<div class="audio-slider">
  <perfect-scrollbar class="audio-slide"
                     [config]="topBarScrollConfig"
                     [scrollIndicators]="false" (psYReachEnd)="onScrollEvent($event)"
                     (psYReachStart)="onScrollEvent($event)">

    <div class="music-blocks" *ngIf="(activeCollectionType$ | async) == 'audio'"
         [dragula]="draggable ?  'dragContainer-audio' : null" id="dragAudioFrom"
         [dragulaModel]="(activeCollection$ | async)">
      <div class="line music-block js-music-block"
           *ngFor="let collection of (activeCollection$ | async); let index=index;let key=key;trackBy: trackByCollection">
        <div class="line--content">
          <div class="bord-wrapper" [class.need_pro]="need_pro(collection, index)">
            <div class="bord bord--hook">
              <img class="bord__icon" *ngIf="collection.icon"
                   src="./assets/images/bord/2x/{{collection.icon}}.png">
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="(activeCollectionType$ | async) == 'percussion'">
      <div class="music-blocks">
        <app-percussion-source [percussion]="percussion" class="line music-block "
                               *ngFor="let percussion of (activeCollection$ | async); trackBy: trackByCollection">
        </app-percussion-source>
      </div>
    </ng-container>
  </perfect-scrollbar>

  <div class="top-bar--scroll-controls">
    <button
      [class]="'top-bar--scroll-controls__up ' + (!topScrollProperties.isEnabled || topScrollProperties.isTop ? '--disabled': '')"
      (pointerup)="scrollTopBarToY(-108)">
      <img src="./assets/images/arrow-up.png" alt="Naar boven scrollen">
    </button>

    <button
      [class]="'top-bar--scroll-controls__down ' + (!topScrollProperties.isEnabled || topScrollProperties.isBottom ? '--disabled': '')"
      (pointerup)="scrollTopBarToY(+108)">
      <img src="./assets/images/arrow-down.png" alt="Naar beneden scrollen">
    </button>
  </div>
</div>
