import { Injectable } from '@angular/core';
import { Media, MEDIA_STATUS, MediaObject } from "@ionic-native/media/ngx";
import { File } from "@ionic-native/file/ngx";
import { cordovaInstance } from "@ionic-native/core";

declare var device;
const REC_OPTIONS = {
  SampleRate: 48000,
  NumberOfChannels: 1
};

MediaObject.prototype["startRecordWithCompression"] = function () {
  return cordovaInstance(this, "startRecordWithCompression", { "sync": true }, arguments);
};

@Injectable({
  providedIn: 'root'
})
export class NativeRecorderService {
  recording: MediaObject;
  mediaTimer: number;
  fileName: any;
  getFile: MediaObject;
  iOS = true;

  constructor(private media: Media,
              private file: File) {
    if (device.platform.toUpperCase() === 'ANDROID') {
      this.iOS = false;
    }
  }

  start(fileName) {
    return new Promise(res => {
      console.log('mobile device detected.. start recording');
      this.fileName = this.iOS ? `documents://${fileName}.m4a` : this.file.externalApplicationStorageDirectory.replace(/^file:\/\//, '') + (fileName + ".mp3");
      this.getFile = this.media.create(this.fileName);


      (this.getFile as any).startRecordWithCompression(REC_OPTIONS);
      console.log(`recording will be stored at ${this.fileName}`);

      this.getFile.onStatusUpdate.subscribe(status => {
        if (status == MEDIA_STATUS.RUNNING) { //if running it means it is recording!
          res();
        }
      });
    });
  }

  stop() {
    this.getFile.stopRecord();
    return this;
  }

  getAudio() {
    return new Promise(res => {
      res([this.fileName]);
    })
  }

  playRecording() {
    this.getFile.play();
  }
}
