<div class="page page-home">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>
      <div class="buttons">
        <app-navigation-button title="{{'Maak je eigen verhaal'|translate}}"
                               (pointerup)="checkVerhaalTimeline()"></app-navigation-button>
        <app-navigation-button title="{{'Maak je eigen muziek'|translate}}"
                               (pointerup)="checkMuziekTimeline()"></app-navigation-button>
        <app-navigation-button title="{{'Handleiding'|translate}}" routerLink="/handleiding"></app-navigation-button>
        <app-navigation-button *ngIf="!!version['pro']" title="{{'Download muziek'|translate}}"
                               routerLink="/download"></app-navigation-button>
        <app-navigation-button *ngIf="!!!version['pro'] && !!networkAvailable"
                               title="{{'Koop de Volledige Versie'|translate}}"
                               (click)="checkParents()"></app-navigation-button>
      </div>
      <div class="actions">
        <div class="bg-mute" [class.unmute]="mutedIntro" (pointerup)="toggleMute()"></div>
        <a class="logout" href="javascript:void(0);" (pointerup)="logout()">{{ 'logout' |translate}}</a>
        <a class="logout" *ngIf="!isProduction" href="javascript:void(0);" (pointerup)="removePro()">relock</a>
        <app-language-switcher></app-language-switcher>
      </div>
    </div>
  </div>
</div>
