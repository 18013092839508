import { NgxLoggerLevel } from "ngx-logger";

export const environment = {
  production: true,
  stats: false,
  api: 'https://account.deoasevandouz.be/api',
  whitelistedDomains: ['account.deoasevandouz.be'],
  blacklistedDomains: [
    'http://account.deoasevandouz.be/auth/login',
    'http://account.deoasevandouz.be/auth/register',
    'https://account.deoasevandouz.be/auth/login',
    'https://account.deoasevandouz.be/auth/register'
  ],
  firebase: {
    apiKey: "AIzaSyCMnLRev8PMLhRDD1vTxlKmLkTrg_HP5CU",
    authDomain: "oase-van-douz.firebaseapp.com",
    databaseURL: "https://oase-van-douz.firebaseio.com",
    projectId: "oase-van-douz",
    storageBucket: "oase-van-douz.appspot.com",
    messagingSenderId: "663466580870"
  },
  logging: {
    rollbarAccessToken : 'dbab1b542ad140928b7394573798448e',
  },
  pro_version: {
    validator: 'https://validator.fovea.cc/v1/validate?appName=be.brassery.deoasevandouz&apiKey=64a2bb02-4c02-4dfb-95d6-af8a60d4fd36',
    appleProductId: 'de_oase_pro_apple',
    googleProductId: 'de_oase_pro_android'
  }
};
