import { Injectable, NgZone } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { dataURLtoBlob, convertToMP3 } from '../lib/utils';
import { Howl } from "howler";

declare var device;
declare var window;
declare var navigator;

// WebAudio API representer
window.AudioContext;

if (typeof (window as any).AudioContext === 'undefined') {
  if (typeof (window as any).webkitAudioContext !== 'undefined') {
    /*global AudioContext:true */
    (window as any).AudioContext = (window as any).webkitAudioContext;
  }

  if (typeof (window as any).mozAudioContext !== 'undefined') {
    /*global AudioContext:true */
    (window as any).AudioContext = (window as any).mozAudioContext;
  }
}

import MicRecorder from 'mic-recorder';
import Mp3Encoder from "mic-recorder/dist/mp3-encoder";
import WavEncoder from "mic-recorder/dist/wav-encoder";
import { NativeRecorderService } from './native-recorder.service';

MicRecorder.prototype.start = function () {
  var _this = this;
  this.context = new AudioContext();
  // this.config.sampleRate = this.context.sampleRate
  if (this.config.encoder === 'mp3') {
    this.__encoder = new Mp3Encoder(this.config);
  } else if (this.config.encoder === 'wav') {
    this.__encoder = new WavEncoder(this.config);
  }
  const audio = { deviceId: { exact: this.config.deviceId } };
  return new Promise((resolve, reject) => {
    navigator.mediaDevices
      .getUserMedia({ audio: true, video: false })
      .then(function (stream) {
        _this.addMicrophoneListener(stream);
        resolve(stream);
      })
      .catch(function (err) {
        reject(err);
      });
  });
};

@Injectable({
  providedIn: 'root'
})
export class AudioRecorderService {
  private microphone: any;
  private recorder: any;
  isNative: boolean;

  constructor(private zone: NgZone, protected localStorage: LocalStorage, private nativeRecorder: NativeRecorderService) {
    if (device.platform.toUpperCase() === 'ANDROID' || device.platform.toUpperCase() === 'IOS') {
      this.isNative = true;
      this.recorder = nativeRecorder;
    } else {
      this.isNative = false;
      this.recorder = new MicRecorder({
        bitRate: 128,
        encoder: 'wav' // default is mp3, can be wav as well
      });
    }
  }

  /**
   * Start recording.
   */
  initiateRecording(filename: string) {
    return new Promise(res => {
      this.zone.runOutsideAngular(() => {
        console.log(`start recording with ${filename}`);
        this.recorder.start(this.isNative ? filename : null).then(res);
      });
    });
  }

  stopWithoutProcessing() {
    return this.recorder.stop();
  }

  /**
   * Stop recording.
   */
  stopRecording() {
    return new Promise(res => {
      this.recorder
        .stop()
        .getAudio().then(([buffer, blob]) => {
        if (this.isNative) {
          // console.log('native audio recorder stopped saving audio')
          res({
            type: 'native',
            fileName: buffer
          });
        } else {
          // console.log('web audio recorder stopped. processing audio')
          this.processRecording(blob).then(res);
        }
      });
    });
  }

  /**
   * processRecording Do what ever you want with blob
   * @param  {any} blob Blog
   */
  processRecording(blob) {
    // TODO: do the mp3 transcoding in realtime https://github.com/muaz-khan/RecordRTC/blob/master/simple-demos/ondataavailable-StereoAudioRecorder.html
    return new Promise(async res => {
      const rawMp3blob = await convertToMP3(blob);
      res({
        type: blob.type,
        blob: rawMp3blob,
      });
    });
  }

  getRecordingsFromStorage(audioSequence) {
    return new Promise((resolve, reject) => {
      this.localStorage.getItem(`voice-${audioSequence.id}`).subscribe(async (result) => {
        if (!this.isNative) {
          if (result && typeof result === 'object' && 'recordedBlobDataUrl' in result) {
            const dataUrlblob = result['recordedBlobDataUrl'];
            const blobFromLocalStorage = await dataURLtoBlob(dataUrlblob);
            const blobUrl = URL.createObjectURL(blobFromLocalStorage);
            // create a howl so it will be cached, we aren't doing anything with it, fuck it
            new Howl({
              volume: 0, // needed for fadein to work
              src: [blobUrl],
              html5: true, // Force to HTML5 which apparently is less buggy in Chrome etc
              format: ['mp3']
            });
            resolve(blobUrl);

          } else {
            // if nothing found in localstorage delete it from the context too.
            reject();
          }
        } else {
          //native device so recording are filenames
          if (result && typeof result === 'object' && 'recordingFileName' in result) {
            const recordingFileName = result['recordingFileName'];
            resolve(recordingFileName);
          } else {
            reject();
          }
        }
      });
    })
  }
}
