import {Action} from '@ngrx/store';

export enum PlayerTimelineActionTypes {
  ImportState = '[PlayerTimeline] ImportState',
  SetBlocks = '[PlayerTimeline] SetBlocks',
  ResetPlayerTimeline = '[ResetPlayerTimeline] Reset',
  SetPlaying = '[PlayerTimeline] SetPlaying',
  SetPlayingBlock = '[PlayerTimeline] SetPlayingBlock',
  GoToFirstBlock = '[PlayerTimeline] goToFirstBlock',
  PlayNextBlock = '[PlayerTimeline] PlayNextBlock',
  PlayPrevBlock = '[PlayerTimeline] PlayPrevBlock',
  IncrementAudioId = '[PlayerTimeline] incrementAudioId',
}

export class IncrementAudioId implements Action {
  readonly type = PlayerTimelineActionTypes.IncrementAudioId;

  constructor() {
  }
}

export class ResetPlayerTimeline implements Action {
  readonly type = PlayerTimelineActionTypes.ResetPlayerTimeline;

  constructor() {
  }
}

export class SetBlocks implements Action {
  readonly type = PlayerTimelineActionTypes.SetBlocks;

  constructor(public payload: any[]) {
  }
}

export class SetPlaying implements Action {
  readonly type = PlayerTimelineActionTypes.SetPlaying;

  constructor(public payload: boolean) {
  }
}

export class SetPlayingBlock implements Action {
  readonly type = PlayerTimelineActionTypes.SetPlayingBlock;

  constructor(public payload: number | null) {
  }
}

export class GoToFirstBlock implements Action {
  readonly type = PlayerTimelineActionTypes.GoToFirstBlock;
}

export class PlayNextBlock implements Action {
  readonly type = PlayerTimelineActionTypes.PlayNextBlock;

  constructor() {
  }
}

export class PlayPrevBlock implements Action {
  readonly type = PlayerTimelineActionTypes.PlayPrevBlock;

  constructor() {
  }
}

export class ImportState implements Action {
  readonly type = PlayerTimelineActionTypes.ImportState;

  constructor(public payload: any) {
  }
}

export type PlayerTimelineActions =
  ImportState |
  SetBlocks |
  PlayNextBlock |
  IncrementAudioId |
  PlayPrevBlock |
  GoToFirstBlock |
  SetPlayingBlock |
  ResetPlayerTimeline |
  SetPlaying;
