import { Media, MediaObject, MEDIA_STATUS } from "@ionic-native/media/ngx";
import {Howl, Howler} from 'howler';
declare var device;
let NativeAudio;

export default class Sound {
  private sound: any;
  private internalState: any = "init";
  isNative: boolean;
  useMedia: boolean;
  media: Media;
  options: { src, format, pool, preload: true };
  fs: any;
  isAndroid: boolean;
  pathPrefix: any = '';
  volume: number;
  stopCalled: boolean;
  muted: boolean;
  soundId: string;

  constructor(media, options) {
    this.isNative = options.useNative || false;
    console.log('usingNative', this.isNative);
    this.options = options;

    this.useMedia = options.useMedia;

    if (this.isNative) {
      NativeAudio = (window as any).plugins.NativeAudio;

      let pathToFile = this.options.src.replace('./', this.pathPrefix);
      if ((window as any).soundIds.indexOf(`${pathToFile}`) > -1 ) {
        console.log(`🔊  audioID already preloaded ${pathToFile}`);
        this.internalState = 'loaded';
        this.soundId = `${pathToFile}`;
      } else {
        console.log(`🔊 creating native audioplayer with audioID ${pathToFile}`);
        this.soundId = `${pathToFile}`;
        (window as any).soundIds.push(`${pathToFile}`);
        this.internalState = 'init';
      }
    } else {
      this.sound = new Howl({
        src: [this.options.src],
        pool: this.options.pool,
        preload: false
      });
    }
  }

  setVolume(value) {
    if (this.isNative) {
      NativeAudio.setVolumeForComplexAsset(this.soundId, value)
    } else {
      (this.sound as Howl).volume(value)
    }
  }

  get state() {
    return this.internalState;
  }


  mute(mute) {
    this.muted = mute;
    if (this.isNative) {
      if (this.muted) {
        this.setVolume(0.0);
      } else {
        this.setVolume(1.0)
      }
    } else {
      (this.sound as Howl).mute(this.muted);
    }
  }

  play(webPreSeek = 0.0) {
    this.stopCalled = false;
    if (this.isNative) {
      NativeAudio.play(this.soundId);
    } else {
      (this.sound as Howl).seek(webPreSeek);
      (this.sound as Howl).play();
    }
  }

  stop() {
    this.stopCalled = true;
    if (this.isNative) {
      NativeAudio.stop(this.soundId);
    } else {
      (this.sound as Howl).stop();
    }
  }

  loop(value) {
    if (this.isNative && value == true) {
      console.log('needs implementation');
    } else if (!this.isNative && value) {
      (this.sound as Howl).loop(value);
    }
  }

  pause() {
    this.sound.pause();
  }

  unload() {
    if (this.isNative) {
      NativeAudio.unload(this.soundId)
    } else {
      (this.sound as Howl).unload()
    }
  }

  load() {
    return new Promise(async (resolve, reject) => {
      console.log(`✋ options source is ${this.options.src}`);
      // @ts-ignore
      if (this.isNative) {
        if (this.internalState != 'loaded') {
          NativeAudio.preloadComplex(this.soundId, this.soundId, //soundId is also the path
            1, // volume
            2, // voices
            0, // delay
            (msg) => {
              this.internalState = "loaded";
            },
            (msg) => {
              alert('Error: ' + msg);
            });
          resolve(this);
        } else {
          resolve(this);
        }
      } else {
        (this.sound as Howl).load();
        (this.sound as Howl).once('load', () => {
          this.internalState = (this.sound as Howl).state();
          resolve(this);
        });
      }
    });
  }
}
