import { Injectable } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { LocalStorage } from '@ngx-pwa/local-storage';

@Injectable({
  providedIn: 'root'
})
export class I18nService {

  private defaultLang = 'nl';
  private availableLanguages = ['nl', 'fr', 'en'];
  public currentLanguage: string;

  constructor(private translate: TranslateService, protected storage: LocalStorage) {

    this.translate.setDefaultLang(this.defaultLang);
    this.getLanguage();
  }

  getLanguage() {
    return new Promise(resolve => {
      this.storage.getItem('i18n').subscribe(language => {
        if (language) {
          this.currentLanguage = <string>language;
        } else {
          let browserLang = this.translate.getBrowserLang();
          this.currentLanguage = browserLang || this.defaultLang;
        }
        this.setLanguage(this.currentLanguage);
        resolve(this.currentLanguage);
      });
    })
  }

  setLanguage(language: string) {
    this.translate.use(language);
    this.currentLanguage = language;
    this.storage.setItem('i18n', language).subscribe();
  }

  getLanguages(): Promise<{ code: string; active: boolean; }[]> {
    return this.getLanguage().then(() => {
      return this.availableLanguages.map(language => {
        let active = language == this.currentLanguage;
        return {
          code: language, active: active
        }
      })
    });
  }
}
