<div class="line">
  <div class="line--content">
    <div class="bord-wrapper drag-handle" [dropTarget]="targetTop">
      <div class="bord bord--hook">
        <img src="./assets/icons/icon-vuilbak.png" class="bord__delete" [hidden]='!deleteMode'
             (pointerup)="remAudioSequence($event)">
        <img class="bord__icon" *ngIf="audioSequence.icon"
             src="./assets/images/bord/2x/{{audioSequence.icon}}.png">
      </div>
    </div>

    <!--MUTE FIRST BLOCK-->
    <div *ngIf="advanced">
      <div class="bord-wrapper bord-wrapper--no-hook mute-block"
           *ngFor="let audioSequencePart of audioSequence.sources[0];trackBy: trackByIndex;let i = index"
           (pointerup)="toggleMute(audioSequence.id, 0, i)">
        <div
          [class]="audioSequencePart?.muted ? 'instrument-channel_mute unmute' : 'instrument-channel_mute mute'">
        </div>
      </div>
    </div>
    <!--END MUTE FIRST BLOCK-->

    <!--ADD PERCUSSION-->
    <div class="bord-wrapper" [dropTarget]="targetBottom">
      <div class="bord bord--hook bord--percussion opacity-2"
           *ngIf="(showOverlayTop$|async) || (showOverlayBottom$|async)">
        <img class="bord__icon" *ngIf="(showOverlayTop$|async)?.item"
             src="./assets/images/bord/2x/{{(showOverlayTop$|async)?.item.icon}}.png">
        <img class="bord__icon" *ngIf="(showOverlayBottom$|async)?.item"
             src="./assets/images/bord/1x/{{(showOverlayBottom$|async)?.item.icon}}.png">
      </div>

      <div class="bord bord--hook bord--percussion"
           *ngIf="audioSequence.percussion && !((showOverlayTop$|async) || (showOverlayBottom$|async))">
        <img src="./assets/icons/icon-vuilbak.png" class="bord__delete" [hidden]='!deleteMode'
             (pointerup)="remPercussionOnly($event)">
        <img class="bord__icon" *ngIf="audioSequence.percussion.icon"
             src="./assets/images/bord/2x/{{audioSequence.percussion.icon}}.png">
      </div>

      <div class="bord bord--hook--only" *ngIf="!audioSequence.percussion"></div>
    </div>
    <!--END ADD PERCUSSION-->

    <!--ADD DRAWING-->
    <div class="bord-wrapper bord-wrapper--no-hook">
      <div class="bord bord--drawing">
        <app-picture-upload (setDrawing)="addDrawing($event)"
                            (upload)="addDrawing($event)"
                            (removeDrawingOnly)="remDrawingOnly()"
                            [deleteMode]="deleteMode"
                            [audioSequence]="audioSequence"></app-picture-upload>
      </div>
    </div>
    <!--END ADD DRAWING-->

    <!--ADD VOICE-->
    <div class="bord-wrapper bord-wrapper--no-hook" [class.active]="localRecordMode" *ngIf="!recordingURLOrFileName">
      <div class="bord record-block" (pointerup)="recordVoice($event)" *ngIf="!convertingToMp3 && !recordCountDown">
        <img class="record-block__icon" src="./assets/icons/record-mic-nodot.png">
        <span class="record-block__timeleft" *ngIf="timeLeft">{{(timeLeft * 60) | date:"mm:ss"}}</span>
      </div>

      <div class="bord record-block record-block__converting" *ngIf="convertingToMp3 && !recordCountDown">
        <span class="record-block__timeleft">converting...</span>
      </div>

      <div class="bord record-block" (pointerup)="recordVoice($event)" *ngIf="recordCountDown">
        <span>{{ recordCountDown }}</span>
      </div>
    </div>

    <div class="bord-wrapper" *ngIf="recordingURLOrFileName">
      <div class="bord bord--hook">
        <img src="./assets/icons/icon-vuilbak.png" class="bord__delete" [hidden]='!deleteMode'
             (pointerup)="remVoiceRecordingOnly($event)">
        <img class="bord__icon" src="./assets/icons/playback-bird.png">
      </div>
    </div>
    <!--END ADD VOICE-->
  </div>

  <!--MUTE EXTRA BLOCKS-->
  <ng-container *ngIf="audioSequence.sources.length > 1 && advanced">
    <ng-container
      *ngFor="let audiopart of audioSequence.sources;trackBy: trackBySrc; let sourceIndex = index;">
      <ng-container *ngIf="sourceIndex > 0">
        <div class="line--content">
          <div class="bord-wrapper bord--sequence opacity-7">
            <div class="bord bord--hook">
              <img class="bord__icon" src="./assets/images/bord/2x/{{audioSequence.icon | lowercase }}.png">
            </div>
          </div>
          <div class="bord-wrapper bord-wrapper--no-hook mute-block"
               *ngFor="let audioSequencePart of audiopart; let instrumentIndex = index"
               (pointerup)="toggleMute(audioSequence.id, sourceIndex, instrumentIndex)">
            <div
              [class]="audioSequencePart?.muted ? 'instrument-channel_mute unmute' : 'instrument-channel_mute mute'">
            </div>
          </div>
          <div class="bord-wrapper bord-wrapper--no-hook opacity-7">
            <div class="bord bord--no-hook ">
            </div>
          </div>
          <div class="bord-wrapper bord-wrapper--no-hook opacity-7">
            <div class="bord bord--no-hook">
            </div>
          </div>
          <div class="bord-wrapper bord-wrapper--no-hook opacity-7">
            <div class="bord bord--no-hook">
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
  <!--END MUTE EXTRA BLOCKS-->
</div>
