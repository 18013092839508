import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {TokenService} from './token.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {tap, map} from 'rxjs/operators';
import {AngularFireAuth} from '@angular/fire/auth';
import * as firebase from 'firebase/app';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedIn = new BehaviorSubject<boolean>(this.tokenService.loggedIn());
  public authStatus = this.loggedIn.asObservable();
  fireBaseUser: firebase.User;

  constructor(
    private af: AngularFireAuth,
    private tokenService: TokenService,
    private userService: UserService,
    private http: HttpClient,
  ) {
    this.af.authState.subscribe((user: firebase.User) => this.fireBaseUser = user);
  }

  firebaseAnonymousLogin() {
    return this.af.auth.signInAnonymously()
      .then(() => {
        return console.log('successful firebase login');
      })
      .catch(error => console.log(error));
  }

  changeAuthStatus(value: boolean) {
    this.loggedIn.next(value);
  }

  register(data) {
    return this.http.post<{ user: any, token: string, version: any }>(`${environment.api}/auth/register`, data)
      .pipe(
        tap(response => this.tokenService.handleResponse(response)),
        tap(response => this.userService.handleResponse(response))
      );
  }

  login(data) {
    return this.http
      .post<{ user: any, token: string, version: any }>(`${environment.api}/auth/login`, {
        'email': data.email,
        'password': data.password,
      })
      .pipe(
        tap(response => this.tokenService.handleResponse(response)),
        tap(response => this.userService.handleResponse(response))
      );
  }

  logout() {
    TokenService.remove();
  }

  getEmailAddress() {
    return this.http
      .get(`${environment.api}/me`)
      .pipe(map(response => (response as any).user.email));
  }


  sendPasswordResetLink(data: { email: string }) {
    return this.http.post(`${environment.api}/auth/reset`, {}, {params: data});
  }
}
