<div class="page page-home">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo" routerLink="/home">
        <img src="./assets/images/logo.png" alt="">
      </div>
      <div class="handleiding">
        <div class="row">
          <div class="col">
            <div class="copy">
              <h4 *ngIf="!!version['pro']">{{ 'U bent reeds in het bezit van de volledige versie' |translate}}</h4>

              <ng-container *ngIf="!version['pro'] && !native">
                <p>{{"Om toegang te krijgen tot de volledige versie geeft u hieronder de code die je achterin het boek terugvindt in en klik je op 'Valideer'"|translate}}</p>
                <form class="form">
                  <input type="text" placeholder="XXXXX-XXXXX-XXXXX-XXXXX" [(ngModel)]="unlockCode" name="unlockCode"
                         tabindex="0">
                  <input type="submit" (pointerup)="unlock()" [disabled]="!unlockCode" value="{{'Valideer'|translate}}"
                         tabindex="1">
                </form>
                <p>{{ 'Of ga je naar de app store of google play' |translate}}</p>
              </ng-container>

              <ng-container *ngIf="!version['pro'] && native">
                <p>{{ "Klik op 'koop nu' om toegang te krijgen tot de volledige versie van de app. " |translate}}</p>
                <input *ngIf="ready && !alreadyOwned" type="submit" (pointerup)="purchase()"
                       value="{{ 'koop nu voor \{\{value\}\}'|translate:price.value }}"
                       tabindex="1">
              </ng-container>
            </div>
          </div>
        </div>
        <div class="buttons">
          <app-navigation-button title="{{'Home'|translate}}" routerLink="/home"></app-navigation-button>
        </div>
      </div>
    </div>
  </div>
</div>
