<div class="page page-home">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo" routerLink="/home">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>
      <div class="simple-player">
        <br> <br>
        <div class="row">
          <div class="copy">
            <table>
              <tr>
                <th >{{ 'Titel' |translate}}</th>
                <th >{{ 'Artiest' |translate}}</th>
                <th >{{ 'Beluister' |translate}}</th>
              </tr>
              <tr>
                <td>{{song}}</td>
                <td>{{artist}}</td>
                <td>
                  <div id="loading" [hidden]="loading == false">
                    <div class="loader loader--style2" title="1">
                      <svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
                           xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                           width="40px" height="40px" viewBox="0 0 50 50" style="enable-background:new 0 0 50 50;"
                           xml:space="preserve">
                        <path fill="#000"
                              d="M25.251,6.461c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615V6.461z">
                          <animateTransform attributeType="xml"
                                            attributeName="transform"
                                            type="rotate"
                                            from="0 25 25"
                                            to="360 25 25"
                                            dur="0.6s"
                                            repeatCount="indefinite"/>
                        </path>
                      </svg>
                    </div>
                  </div>
                  <div class="controls" [hidden]="loading == true">
                    <button (pointerup)="setPlaying(true)" [hidden]="(timelinePlaying$ | async) === true">
                      <img src="./assets/images/btn/play.png">
                    </button>

                    <button (pointerup)="setPlaying(false)" [hidden]="(timelinePlaying$ | async) !== true">
                      <img src="./assets/images/btn/pauze.png">
                    </button>

                    <button (pointerup)="goToFirstBlock($event)">
                      <img src="./assets/images/btn/stop.png">
                    </button>
                  </div>
                </td>
              </tr>
            </table>
            <a href="http://twitter.com/share?url={{shareUrl}}&text={{shareText}}&via={{viaURl}}"
               target="_blank" class="share-btn twitter" style="display: inline-block !important;">
              <i class="fa fa-twitter"></i>
            </a>
            <!-- Facebook -->
            <a
              href="https://www.facebook.com/dialog/feed?app_id=1591298540973401&display=popup&description={{shareText}}&caption={{shareTitle}}&link={{shareUrl}}&redirect_uri={{viaURl}}"
              target="_blank" class="share-btn facebook" style="display: inline-block !important;">
              <i class="fa fa-facebook-f"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
