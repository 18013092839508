import { Component, OnInit } from '@angular/core';
import { I18nService } from "../../services/i18n.service";

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})
export class DownloadComponent implements OnInit {
  public musicTracks: { id: number; name: string; file: string }[];

  constructor(public i18n: I18nService,
  ) {
    this.musicTracks = [
      {
        'id': 1,
        'name': 'De sjeik van Douz',
        'file': '1.De-sjeik-van-Douz.mp3'
      },
      {
        'id': 2,
        'name': 'Le cheik de Douz',
        'file': '2.Le-cheik-de-Douz.mp3'
      },
      {
        'id': 3,
        'name': 'The sheik of Douz',
        'file': '3.-The-sheik-of-Douz.mp3'
      },
      {
        'id': 4,
        'name': 'De oase van Douz,  een koekoeksverhaal',
        'file': '4.De-oase-van-Douz-een-koekoeksverhaal.mp3'
      },
      {
        'id': 5,
        'name': 'De oase van Douz ( instrumentaal )',
        'file': '5.De-oase-van-Douz-(instrumentaal).mp3'
      },
      {
        'id': 6,
        'name': 'L\'oasis de Douz, une histoire de coucou',
        'file': "6.L_oasis-de-Douz-une-histoire-de-coucou.mp3"
      },
      {
        'id': 7,
        'name': 'The oasis of Douz, a cuckoo\'s story',
        'file': '7.The-oasis-of-Douz-a-cuckoos-story.mp3'
      },
      {
        'id': 8,
        'name': 'l\'Inverno e passato',
        'file': '8.lInverno-e-passato.mp3'
      }
    ];
  }

  ngOnInit() {
  }

  download(file) {
    if (typeof document != 'undefined') {
      const link = document.createElement('a');
      link.download = name;
      link.href = 'https://account.deoasevandouz.be/assets/mp3/' + file;
      link.click();
    }
  }
}
