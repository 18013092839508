import { Component, OnInit } from '@angular/core';
import { AuthService } from "../services/auth/auth.service";
import Swal from 'sweetalert2';
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email: string;
  model: any = {};

  constructor(
    public auth: AuthService,
    private router: Router,
    private translate: TranslateService,
  ) {
  }

  ngOnInit() {
  }

  async submit() {
    this.auth.sendPasswordResetLink({ email: this.model.email })
      .subscribe(this.handleResponse.bind(this), this.handleResponse.bind(this));
  }

  private async handleResponse() {
    const [title, text] = await Promise.all([
      this.translate.get('swal-purchase-success-title').toPromise(),
      this.translate.get('reset_link_send').toPromise()]
    );
    await Swal.fire({
      title: title,
      text: text,
      icon: 'success',
      allowOutsideClick: false,
    });

    await this.router.navigate(['login']);
  }
}
