import { NgModule } from '@angular/core';
import { RouterModule, Routes, PreloadAllModules } from '@angular/router';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';


import { HandleidingComponent } from './containers/handleiding/handleiding.component';
import { HomeComponent } from './containers/home/home.component';
import { MuziekComponent } from './containers/muziek/muziek.component';
import { VerhaalComponent } from './containers/verhaal/verhaal.component';

import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { AuthGuard } from './services/auth/auth.guard';
import { DownloadComponent } from './containers/download/download.component';
import { MuziekPlayerComponent } from './containers/player/muziek-player.component';
import { VerhaalPlayerComponent } from './containers/player/verhaal-player.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AppResolver } from './services/resolvers/app.resolver';
import { AudioRecordtestComponent } from './containers/audio-recordtest/audio-recordtest.component';
import { PurchaseComponent } from './containers/purchase/purchase.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'download',
    component: DownloadComponent
  },
  {
    path: 'purchase',
    component: PurchaseComponent,
    canActivate: [AuthGuard],
    resolve: {
      version: AppResolver
    }
  },
  {
    path: 'muziek/:author/:song/:id',
    component: MuziekPlayerComponent
  },
  {
    path: 'verhaal/:author/:song/:id',
    component: VerhaalPlayerComponent
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    resolve: {
      version: AppResolver
    }
  },
  {
    path: 'handleiding',
    component: HandleidingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'record',
    component: AudioRecordtestComponent
  },
  {
    path: 'muziek',
    component: MuziekComponent,
    canActivate: [AuthGuard],
    resolve: {
      version: AppResolver
    }
  },
  {
    path: 'verhaal',
    component: VerhaalComponent,
    canActivate: [AuthGuard],
    resolve: {
      version: AppResolver
    }
  },
  {
    path: '**',
    redirectTo: 'home'
  }
];

@NgModule({
  imports: [
    QuicklinkModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      preloadingStrategy: QuicklinkStrategy
    })],
  exports: [RouterModule],
  providers: [AuthGuard, AppResolver]
})

export class AppRoutingModule {
}
