import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class TimelineService {
  MAX_BLOCK_COUNT = 4;

  constructor(private router: Router, private translate: TranslateService) {
  }

  async checkMaxBlocks(isPro, blockCount, overrideCheck = false): Promise<boolean> {
    if (!isPro && blockCount > this.MAX_BLOCK_COUNT) {
      let results = await this.showNeedProPopup();

      if (results.value) {
        return this.checkParents();
      }

      return false;
    }

    if (overrideCheck) {
      let results = await this.showNeedProPopup();

      if (results.value) {
        return this.checkParents();
      }
    }

    return true;
  }


  async showNeedProPopup() {
    const [title, text, yes, no] = await Promise.all([
      this.translate.get('swal-purchase-fail-title').toPromise(),
      this.translate.get('buy-full-version').toPromise(),
      this.translate.get('yes').toPromise(),
      this.translate.get('no').toPromise()
    ]);

    return Swal.fire({
      title: title,
      text: text,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: yes,
      allowOutsideClick: false,
      cancelButtonText: no
    });
  }

  async checkParents() {
    let title = await this.translate.get('swal-warning-parents').toPromise();
    let riddle2 = await this.translate.get('swal-riddle2').toPromise();
    let cancelButton = await this.translate.get('swal-cancel').toPromise();

    const {value: oplossing} = await Swal.fire({
      title: title,
      text: riddle2,
      input: 'text',
      inputValue: '',
      showCancelButton: true,
      confirmButtonText: 'ok',
      allowOutsideClick: false,
      cancelButtonText: cancelButton
    });

    const solution = oplossing ? (oplossing as String).toLowerCase() : undefined;
    if (oplossing && (oplossing == '7' || solution == 'zeven' || solution == 'seven' || solution == 'sept')) {
      return await this.router.navigate(['/purchase']);
    } else {
      return false;
    }
  }

}
