import {Action} from '@ngrx/store';
import {AudioSequenceCollection} from '../interfaces/AudioSequenceCollection';

export enum CollectionActionTypes {
  SetCollection = '[Collection] Set Collections',
  SetActive = '[Collection] Set Active',
  SetNextActive = '[Collection] Set Next Active',
  SetPrevActive = '[Collection] Set Prev Active',
}

export class SetCollection implements Action {
  readonly type = CollectionActionTypes.SetCollection;

  constructor(public payload: AudioSequenceCollection[]) {
  }
}

export class SetActive implements Action {
  readonly type = CollectionActionTypes.SetActive;

  constructor(public payload: string) {
  }
}

export class SetNextActive implements Action {
  readonly type = CollectionActionTypes.SetNextActive;
}

export class SetPrevActive implements Action {
  readonly type = CollectionActionTypes.SetPrevActive;
}

export type CollectionActions = SetCollection | SetActive | SetNextActive | SetPrevActive;
