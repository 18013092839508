<div class="page page-download">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo" routerLink="/home">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>
      <div class="download">
        <div class="row">
          <div class="copy">
            <h2>{{'Download muziek'|translate}}</h2>
            <p> {{'Hieronder kan je alle muziek beluisteren en downloaden.' | translate}}</p>
            <table>
              <tr>
                <th>#</th>
                <th>{{ 'Naam' |translate}}</th>
                <th>{{ 'Beluister' |translate}}</th>
                <th>{{ 'Download' |translate}}</th>
              </tr>
              <tr *ngFor="let track of musicTracks;">
                <td>{{track.id}}.</td>
                <td>{{track.name}}</td>
                <td>
                  <audio controls>
                    <source src="https://account.deoasevandouz.be/assets/mp3/{{track.file}}"
                            type="audio/mpeg">
                    Your browser does not support the audio element.
                  </audio>
                </td>
                <td class="btn-download">
                  <img src="./assets/icons/save.png" alt="Download track" (pointerup)="download(track.file)">
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="buttons">
          <app-navigation-button title="{{'Home'| translate}}" routerLink="/home"></app-navigation-button>
        </div>
      </div>
    </div>
  </div>
</div>
