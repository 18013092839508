import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  private iss = "oase-jwt";

  constructor() {
  }

  handleResponse(response: { user: any, token: string, version: any }) {
    if (response.token) {
      TokenService.set(response.token);
    }
  }

  static set(token) {
    localStorage.setItem('access_token', token);
  }

  static get() {
    return localStorage.getItem('access_token');
  }

  static remove() {
    localStorage.removeItem('access_token');
  }

  isValid() {
    const token = TokenService.get();
    if (token) {
      const payload = this.payload(token);
      if (payload) {
        return this.iss == payload.iss;
      }
    }
    return false;
  }

  payload(token) {
    const payload = token.split('.')[1];
    return this.decode(payload);
  }

  decode(payload) {
    return JSON.parse(atob(payload));
  }

  loggedIn() {
    return this.isValid();
  }
}
