import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-world-navigation',
  templateUrl: './world-navigation.component.html',
  styleUrls: ['./world-navigation.component.scss']
})
export class WorldNavigationComponent implements OnInit {
  @Input() timelinePlaying;
  @Input() activeCollectionName;
  @Input() activeCollectionIcon;
  @Input() currentDrawing?;

  @Output() prevCollection = new EventEmitter;
  @Output() nextCollection = new EventEmitter;

  constructor() {
  }

  ngOnInit() {
  }
}
