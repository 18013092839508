import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { BootController } from './boot-control';

declare var cordova;
declare var device;

if (environment.production) {
  enableProdMode();
}

const init = () => platformBrowserDynamic().bootstrapModule(AppModule)
  .then(() => {
    console.log('app bootstrapped');
  })
  .catch(console.log);


document.addEventListener('deviceready', () => {
  console.log('deviceready');
  if (navigator && (navigator as any).splashscreen) {
    (navigator as any).splashscreen.hide();
  }
  if (device.platform.toUpperCase() === 'IOS' || device.platform.toUpperCase() === 'ANDROID') {
    if (cordova.plugins.Rollbar) {
      cordova.plugins.Rollbar.init();
    }
    if (cordova.plugins.backgroundMode) {
      cordova.plugins.backgroundMode.enable();
      cordova.plugins.backgroundMode.on('activate', function () {
        cordova.plugins.backgroundMode.disableWebViewOptimizations();
        location.reload();
        console.log('activate');
      });
    }

    document.addEventListener("resume", onResume, false);
  }

  function onResume() {
    setTimeout(function () {
      console.log('🔁 REFRESHHH');
      if (device.platform.toUpperCase() === 'IOS') {
        location.reload();
        init();
      }
    }, 0);
  }

  if (device.platform === 'browser') {
    // @ts-ignore
    window.open = window.backupOpen;
  }

// Init on first load
  init();
  const boot = BootController.getbootControl().watchReboot().subscribe(() => init());
});
