import {AudioSequenceCollection} from '../interfaces/AudioSequenceCollection';
import {CollectionActions, CollectionActionTypes} from '../actions/collection.actions';


export interface State {
  collections: AudioSequenceCollection[];
  activeName: string;
}

export const initialState: State = {
  activeName: 'thuis',
  collections: [],
};

export function reducer(state = initialState, action: CollectionActions): State {
  let index, activeName;


  switch (action.type) {
    case CollectionActionTypes.SetPrevActive:
      index = findActiveCollectionIndex(state.collections, state.activeName);

      if (state.collections[index - 1]) {
        activeName = state.collections[index - 1].name;
      } else {
        activeName = state.collections[state.collections.length - 1].name;
      }

      return {
        ...state,
        activeName,
      };

    case CollectionActionTypes.SetNextActive:
      index = findActiveCollectionIndex(state.collections, state.activeName);

      if (state.collections[index + 1]) {
        activeName = state.collections[index + 1].name;
      } else {
        activeName = state.collections[0].name;
      }

      return {
        ...state,
        activeName,
      };

    case CollectionActionTypes.SetActive:

      return {
        ...state,
        activeName: action.payload,
      };

    case CollectionActionTypes.SetCollection:

      return {
        ...state,
        collections: action.payload,
      };

    default:
      return state;
  }
}

function findActiveCollectionIndex(collections, name) {
  return collections.findIndex((collection) => {
    return collection.name === name;
  });
}

export const getCollections = (state: State) => state.collections;
export const getActiveName = (state: State) => state.activeName;
