import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { PictureUpload } from '../../models/PictureUpload';
import { UploadService } from "../../services/upload.service";
import { AudioSequence } from "../../interfaces/AudioSequence";
import { LocalStorage } from "@ngx-pwa/local-storage";
import { DomSanitizer } from "@angular/platform-browser";
import { blobToDataURL } from '../../lib/utils';
import { ActionSheet, ActionSheetOptions } from '@ionic-native/action-sheet/ngx';
import { TranslateService } from "@ngx-translate/core";
import { combineLatest } from 'rxjs';
import Swal from "sweetalert2";
import { Platform } from "@ionic/angular";

declare const Camera;
declare const navigator;

@Component({
  selector: 'app-picture-upload',
  templateUrl: 'picture-upload.html',
  styleUrls: ['./picture-upload.scss']
})
export class PictureUploadComponent implements OnInit {
  @ViewChild('fileInput', { static: false }) fileInputElement: ElementRef;
  @Input() deleteMode: boolean;
  @Input() audioSequence: AudioSequence;
  @Output() upload = new EventEmitter();
  @Output() setDrawing = new EventEmitter();
  @Output() removeDrawingOnly = new EventEmitter();

  selectedFiles: FileList;
  currentFileUpload: PictureUpload;
  drawingBase64: any;
  buttonLabels: string[];
  takeMessage: string;
  chooseMessage: string;
  addDrawingMessage: string;
  pickMessage: string;
  cancelMessage: string;
  isNative: boolean;

  constructor(private domSanitizer: DomSanitizer,
              private uploadService: UploadService,
              private _ngZone: NgZone,
              private actionSheet: ActionSheet,
              public platform: Platform,
              private translate: TranslateService,
              protected localStorage: LocalStorage) {
    this.isNative = this.platform.is('ios') || this.platform.is('android');
  }

  ngOnInit() {
    this.getDrawingFromStorage();

    combineLatest(
      this.translate.get('Neem een foto'),
      this.translate.get('Kies een foto'),
      this.translate.get('Voeg een tekening toe'),
      this.translate.get('Maak je keuze'),
      this.translate.get('Annuleren'),
    )
      .subscribe(([take, choose, add, pick, cancel]) => {
        this.takeMessage = take;
        this.chooseMessage = choose;
        this.addDrawingMessage = add;
        this.pickMessage = pick;
        this.cancelMessage = cancel;
      });
  }


  async selectFile() {
    if (!this.isNative) {
      this.fileInputElement.nativeElement.click();
    } else {
      const onSuccess = imageData => {
        Swal.close();
        this._ngZone.run(() => { // we need to run it in a zone because it updates the view in an anonymous callback
          let base64 = `data:image/jpeg;base64,${imageData}`;
          this.drawingBase64 = this.domSanitizer.bypassSecurityTrustUrl(base64);
          this.saveDrawing(base64, `drawing-${this.audioSequence.id}.jpg`);
        });
      };

      const onFail = message => {
        Swal.close();
        console.log('Failed because: ' + message);
      };

      this.buttonLabels = [this.takeMessage, this.chooseMessage];

      let buttonIndex = await this.showActionSheet();
      Swal.showLoading();

      if (buttonIndex == 1) {
        navigator.camera.getPicture(onSuccess, onFail, {
          targetWidth: 800,
          targetHeight: 600,
          allowEdit: false,
          quality: 75,
          correctOrientation: true,
          destinationType: Camera.DestinationType.DATA_URL,
          sourceType: Camera.PictureSourceType.CAMERA,
          encodingType: Camera.EncodingType.JPEG,
          saveToPhotoAlbum: true,
          mediaType: Camera.MediaType.PICTURE,
        });
      } else if (buttonIndex == 2) {
        navigator.camera.getPicture(onSuccess, onFail, {
          targetWidth: 800,
          targetHeight: 600,
          allowEdit: false,
          quality: 75,
          correctOrientation: true,
          destinationType: Camera.DestinationType.DATA_URL,
          sourceType: Camera.PictureSourceType.PHOTOLIBRARY,
          encodingType: Camera.EncodingType.JPEG,
          saveToPhotoAlbum: true,
          mediaType: Camera.MediaType.PICTURE,
        });
      }
    }
  }

  showActionSheet() {
    const options: ActionSheetOptions = {
      title: this.addDrawingMessage,
      subtitle: this.pickMessage,
      buttonLabels: this.buttonLabels,
      destructiveButtonLast: false,
      addCancelButtonWithLabel: this.cancelMessage,
    };

    return this.actionSheet.show(options)
  }

  detectFiles(event) {
    this.selectedFiles = event.target.files;
    let file = this.selectedFiles.item(0);
    if (file.type.indexOf('image') > -1) {
      this.selectedFiles = undefined;
      this.uploadSingle(file);
    }
  }

  getDrawingFromStorage() {
    this.localStorage.getItem(`drawing-${this.audioSequence.id}`).subscribe(async (result) => {
      if (result && typeof result === 'object' && 'drawingBase64' in result) {
        const drawingBase64 = result['drawingBase64'];
        // const blobFromLocastorage = await dataURLtoBlob(dataUrlblob);
        this.drawingBase64 = this.domSanitizer.bypassSecurityTrustUrl(drawingBase64);
        this.setDrawing.next(this.drawingBase64);
      } else {
        // if nothing found in localstorage delete it from the context too.
        this.setDrawing.next(null);
        this.drawingBase64 = null;
      }
    });
  }

  saveDrawing(drawingBase64, filename): any {
    const drawing = { drawingBase64: drawingBase64, filename: filename };

    this.localStorage.setItem(`drawing-${this.audioSequence.id}`, drawing).subscribe(() => {
      this.getDrawingFromStorage();
    });
  }

  async uploadSingle(file) {
    if (file) {
      this.currentFileUpload = new PictureUpload(file);
      const resizedFile = await this.uploadService.resizeImage(this.currentFileUpload, { width: 800, height: 600 });

      this.drawingBase64 = await blobToDataURL(resizedFile);
      this.saveDrawing(this.drawingBase64, this.currentFileUpload.name);
    }
  }

  remDrawingOnly(event) {
    event.stopPropagation();
    this.localStorage.removeItem(`drawing-${this.audioSequence.id}`).subscribe(() => {
      this.getDrawingFromStorage();
      this.removeDrawingOnly.emit(this.audioSequence);
    });
  }
}
