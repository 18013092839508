<div class="bord__drawing empty" *ngIf="!drawingBase64">
  <input type="file" #fileInput accept=".jpeg, .jpg, .jpe, .jfif, .jif, .png, .bmp;capture=camera" (change)="detectFiles($event)"
         style="visibility: hidden; position: absolute;left: 1000px;">
  <button class="btn" (pointerup)="selectFile()">
    <img class="bord__icon" src="./assets/icons/foto-plus.png">
  </button>
</div>
<div class="bord__drawing" *ngIf="drawingBase64">
  <button class="btn">
    <img src="./assets/icons/icon-vuilbak.png" class="bord__delete" [hidden]='!deleteMode'
         (pointerup)="remDrawingOnly($event)">
    <img class="preview" [src]="drawingBase64">
  </button>
</div>
