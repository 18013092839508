import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DragulaService } from 'ng2-dragula';
import { Observable, Subject } from 'rxjs';
import {
  GoToFirstBlock,
  IncrementAudioId,
  PlayNextBlock,
  PlayPrevBlock,
  RemovePercussion,
  RemoveAudioSequence,
  SetBlocks,
  SetPercussion,
  SetPlaying,
  SetPlayingBlock,
  ToggleMute,
  SetMuziekVolume,
} from '../../actions/muziekTimeline.actions';
import { SetActive, SetNextActive, SetPrevActive } from '../../actions/collection.actions';
import { AudioSequence } from '../../interfaces/AudioSequence';
import { AudioPlayerService } from '../../services/audio-player.service';
import { AudioSequencesService } from '../../services/audio-sequences.service';
import { takeUntil, take, map } from 'rxjs/operators';
import { SkyhookDndService } from '@angular-skyhook/core';
import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarDirective
} from 'ngx-perfect-scrollbar';
import * as fromStore from '../../reducers';
import { UploadService } from '../../services/upload.service';
import Swal from 'sweetalert2';
import { PageVisibilityService } from 'ngx-page-visibility';
import { ActivatedRoute, Router } from '@angular/router';
import { TimelineService } from '../../services/timeline.service';
import { Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../../services/i18n.service";
import { SwalComponent, SwalPortalTargets } from "@sweetalert2/ngx-sweetalert2";
import { SocialSharing } from "@ionic-native/social-sharing/ngx";

declare var SafariViewController;

@Component({
  selector: 'app-muziek',
  templateUrl: './muziek.component.html',
  viewProviders: [DragulaService],
  styleUrls: ['./muziek.component.scss']
})
export class MuziekComponent implements OnInit, OnDestroy {
  @ViewChild('perfectscroll', { static: true }) timelineScrollDirective?: PerfectScrollbarDirective;
  @ViewChild('shareSwal', { static: true }) shareSwal: SwalComponent;

  public timelineScrollConfig: PerfectScrollbarConfigInterface = {
    handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
    useBothWheelAxes: true,
    suppressScrollY: true
  };

  public loading = true;
  public advanced = false;
  public draggable = true;
  public deleteMode = false;
  public timelineEmpty = true;

  public activeCollectionName$: Observable<string>;
  public activeCollectionIcon$: Observable<string>;
  public timelinePlaying$: Observable<boolean>;
  public timelineBlocks$: Observable<AudioSequence[]>;
  public timelinePlayingIndex$: Observable<number>;

  public currentPlaying: boolean;
  private currentBlockId: number = null;
  private timelinePlayingBlock$: Observable<AudioSequence>;

  private destroyed$ = new Subject();
  private audioId$: Observable<number>;
  private audioId: number;
  version: any;

  volumes: { [p: string]: number } = {};

  isNative: boolean;
  share: { shareText: string, title: string, songName: string, author: string, shareUrl: string, shareHTML: string } = {
    shareText: '',
    title: '',
    songName: '',
    author: '',
    shareUrl: 'url',
    shareHTML: '>>share-html-here<<'
  };

  constructor(private dnd: SkyhookDndService,
              private timelineService: TimelineService,
              private actr: ActivatedRoute,
              private dragulaService: DragulaService,
              private store: Store<fromStore.State>,
              private uploadService: UploadService,
              private router: Router,
              public platform: Platform,
              public i18n: I18nService,
              private socialSharing: SocialSharing,
              public readonly swalTargets: SwalPortalTargets,
              private audioPlayerService: AudioPlayerService,
              private translate: TranslateService,
              private pageVisibilityService: PageVisibilityService,
              private audioSequenceService: AudioSequencesService) {

    this.actr.data
      .pipe(
        map(res => res.version)
      )
      .subscribe(version => {
        this.version = version;
      });
    this.isNative = this.platform.is('ios') && !this.platform.is('android');
    this.initDragula();
  }

  ngOnInit(): void {
    this.loading = true;

    this.setupTimeLineBlocks();
    this.setTrackVolumes();

    this.setupAudioIds();
    this.setupTimeLinePlaying();
    this.setupTimelinePlayingBlocks();
    this.setupTimeLinePlayingIndex();
    this.setupActiveSampleCollections();
  }


  private setupActiveSampleCollections() {
    this.activeCollectionName$ = this.store.pipe(select(fromStore.getCollectionsActiveName));
    this.activeCollectionIcon$ = this.store.pipe(select(fromStore.getCollectionsActiveIcon));
  }

  private setupTimeLinePlayingIndex() {
    this.timelinePlayingIndex$ = this.store.pipe(select(fromStore.getMuziekTimelinePlayingIndex));
    this.timelinePlayingIndex$.pipe(takeUntil(this.destroyed$))
      .subscribe(index => {
        if ((this.currentPlaying || this.advanced)) {
          this.scrollToActiveBlock(index);
        }
      });
  }

  private setupTimeLinePlaying() {
    this.timelinePlaying$ = this.store.pipe(select(fromStore.getMuziekTimeLinePlaying));
    this.timelinePlaying$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((playing) => {
        if (this.currentBlockId === null) {
          this.currentPlaying = playing;

          return;
        }

        if (playing) {
          this.deleteMode = false;
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        } else {
          this.audioPlayerService.stopPlaying();
        }

        this.currentPlaying = playing;
      });
  }

  private setupTimelinePlayingBlocks() {
    this.timelinePlayingBlock$ = this.store.pipe(select(fromStore.getMuziekTimeLinePlayingBlock));
    this.timelinePlayingBlock$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((block) => {
        if (block) {
          block.sources.forEach((sourceList) => {
            sourceList.forEach((source) => {
              this.audioPlayerService.setMuted(source.src, !!source.muted);
            });
          });
        }

        if (this.currentPlaying && (!block || block.id === this.currentBlockId)) {
          // this happens when percussion is dragged onto block while playing
          // stop playback immediately and proceed
          this.currentBlockId = block ? block.id : null;
          this.audioPlayerService.stopPlaying();
        }

        // check if there is a sound currently playing
        if (this.currentPlaying) {
          this.audioPlayerService.stopPlaying();
        }

        this.audioPlayerService.setBlock(block);

        if (this.currentPlaying) {
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        }

        this.currentBlockId = block ? block.id : null;
      });
  }

  private setupAudioIds() {
    this.audioId$ = this.store.pipe(select(fromStore.getMuziekAudioId));
    this.audioId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (audioId) => {
        this.audioId = audioId;
      });
  }

  private setTrackVolumes() {
    this.store.pipe(select(fromStore.getMuziekVolumes))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((volumes) => {
        this.volumes = volumes;
        this.audioPlayerService.setVolumes(volumes);
      });
  }

  private setupTimeLineBlocks() {
    this.timelineBlocks$ = this.store.pipe(select(fromStore.getMuziekTimelineBlocks));
    this.timelineBlocks$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (blocks) => {
        this.timelineEmpty = blocks.length <= 0;
        this.loading = true;
        const sounds = this.audioSequenceService.extractSoundsFromBlocks(blocks);
        this.setPlaying(false);
        await this.audioPlayerService.preload(sounds);
        this.loading = false;
      });
  }

  private scrollToActiveBlock(index?) {
    if (typeof index !== 'undefined') {
      this.timelineScrollDirective.scrollToElement(`.js-music-block:nth-child(${index + 1})`, 0, 500);
    } else {
      this.timelineScrollDirective.scrollToElement(`.js-music-block.selected`, 0, 500);
    }
  }

  ngOnDestroy(): void {
    this.dragulaService.destroy('dragContainer-audio');
    this.destroyed$.next();
    this.audioPlayerService.stopPlaying();
  }

  setVolume(type: string, volume: string) {
    this.store.dispatch(new SetMuziekVolume({ type, volume: parseInt(volume, 10) / 100 }));
  }

  prevCollection() {
    this.store.dispatch(new SetPrevActive());
  }

  nextCollection(key?: string) {
    if (key) {
      this.store.dispatch(new SetActive(key));
    } else {
      this.store.dispatch(new SetNextActive());
    }
  }

  public toggleMute(audioSequence, $event) {
    this.store.dispatch(new ToggleMute({
      id: $event['id'],
      sourceIndex: $event['sourceIndex'],
      instrumentIndex: $event['instrumentIndex']
    }));
  }

  async updateTimelineBlocks(blocks) {
    // only allow more then 5 blocks for pro version
    return await this.timelineService.checkMaxBlocks(this.version['pro'], blocks.length)
      ? this.store.dispatch(new SetBlocks(blocks))
      : false;
  }

  goToFirstBlock($event) {
    $event.preventDefault();
    $event.stopPropagation();

    setTimeout(_ => {
      this.scrollToActiveBlock();
    }, 200);
    this.store.dispatch(new SetPlaying(false));
    this.store.dispatch(new GoToFirstBlock());
  }

  playPrevBlock() {
    this.store.dispatch(new PlayPrevBlock());
  }

  playNextBlock() {
    this.store.dispatch(new PlayNextBlock());
  }

  setPlaying(play: boolean) {
    if (play) {
      this.scrollToActiveBlock();
    }
    this.store.dispatch(new SetPlaying(play));
  }

  setBlockPlaying(id: number) {
    this.store.dispatch(new SetPlayingBlock(id));
  }

  setPercussion(audioSequence, percussion) {
    this.store.dispatch(new SetPercussion({ id: audioSequence.id, percussion }));
  }

  removePercussion(audioSequence) {
    this.store.dispatch(new RemovePercussion({ id: audioSequence.id }));
  }

  removeAudioSequence(audioSequence) {
    this.store.dispatch(new RemoveAudioSequence({ id: audioSequence.id }));
  }

  setAdvancedMode() {
    this.advanced = !this.advanced;
    this.draggable = !this.draggable;
    if (this.advanced) {
      setTimeout(_ => {
        this.scrollToActiveBlock();
      }, 250);
    }
  }

  initDragula() {
    this.dragulaService.createGroup('dragContainer-audio', {
      removeOnSpill: false, // DISABLE DRAG OUT REMOVE
      direction: 'horizontal',
      moves: (el, source, handle, sibling) => { // defines which elements are draggable
        let needPro = handle.parentElement.parentElement.classList.contains('need_pro');
        if (needPro) {
          this.setPlaying(false);
          this.timelineService.checkMaxBlocks(null, null, true);
          return false;
        }
        let moves = !handle.parentElement.classList.contains('donotdrag') && (source.id === 'dragAudioFrom' || handle.parentElement.classList.contains('drag-handle') || handle.parentElement.parentElement.classList.contains('drag-handle'));
        return moves;
      },
      copy: (el, source) => { //copy elements that came from dragAudio From
        return source.id === 'dragAudioFrom';
      },
      copyItem: (audio: AudioSequence) => {
        this.store.dispatch(new IncrementAudioId());
        // deep copy that bitch
        return {
          id: this.audioId,
          ...audio,
          durations: audio.durations || [],
          sources: audio.sources.map(source => source.map((sounds) => ({ ...sounds }))),
        };
      },
      accepts: (el, target, source, sibling) => {//ensure that an element el, that came from container source, can be dropped on container target before a sibling element
        return target.id !== 'dragAudioFrom' && (this.timelineEmpty ? true : sibling !== null);
      }
    });

    this.dragulaService.drag('dragContainer-audio')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ name, el }) => {
        if (el) {
          // disable touch scroll here!
          this.timelineScrollConfig.handlers = ['click-rail', 'drag-thumb', 'keyboard', 'wheel'];
        }
      });

    this.dragulaService.dragend('dragContainer-audio')
      .pipe(takeUntil(this.destroyed$))
      .subscribe(({ name, el }) => {
        if (el) {
          // re-enable touch scroll here!
          this.timelineScrollConfig.handlers = ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'];
        }
      });
  }

  trackById(i, item) {
    return item.id;
  }

  trackByIndex(i, item) {
    return i;
  }

  publishOrPurchase() {
    if (!!this.version['pro']) {
      this.publishOnline();
    } else {
      this.setPlaying(false);
      this.timelineService.checkParents()
    }
  }

  async publishOnline() {
    let title = await this.translate.get('swal-share-song').toPromise();
    let html = await this.translate.get('swal-share-song-form').toPromise();
    let noArtist = await this.translate.get('swal-share-no-artist').toPromise();
    let noTitle = await this.translate.get('swal-share-no-title').toPromise();

    const { value: formValues } = await Swal.fire({
      title: title,
      showCancelButton: true,
      imageUrl: './assets/icons/save.png',
      html: html,
      focusConfirm: false,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      onRender: (dom) => {
        dom.querySelector('#input-author')['value'] = this.share.author;
      },
      preConfirm: () => {
        return new Promise((resolve, reject) => {
          const formValues = {
            author: document.getElementById('input-author')['value'],
            songName: document.getElementById('input-songName')['value'],
            permission: document.getElementById('input-permission')['value']
          };

          if (formValues) {
            const author = formValues['author'];
            const songName = formValues['songName'];
            const permission = formValues['permission'];
            let shareURL;

            if (formValues['author'] == '') {
              Swal.showValidationMessage(
                noArtist
              );
            } else if (formValues['songName'] == '') {
              Swal.showValidationMessage(
                noTitle
              );
            } else {
              this.timelineBlocks$
                .pipe(take(1))
                .subscribe(
                  async timelineBlocks => {
                    if (timelineBlocks) {
                      try {
                        shareURL = await this.uploadService.addMuziekske(timelineBlocks, this.volumes, author, songName, permission);
                        const shareTitle = await this.translate.get('swal-shareTitle').toPromise();
                        const shareText = await this.translate.get('swal-shareText', {
                          songName: songName,
                          author: author
                        }).toPromise();

                        resolve({ songName, shareURL, shareTitle, shareText, author, permission });
                      } catch (err) {
                        reject(err);
                        throw Error(err);
                      }
                    }
                  }
                );
            }
          } else {
            reject('No internet connection');
          }
        });

      }
    });

    if (formValues) {
      const author = formValues['author'];
      const songName = formValues['songName'];
      const shareURL = formValues['shareURL'];
      const shareText = formValues['shareText'];
      const shareTitle = formValues['shareTitle'];
      const permission = formValues['permission'];
      const mailText = `${shareText} ${shareURL}`;

      this.share.shareText = shareText;
      this.share.songName = songName;
      this.share.author = author;
      this.share.title = shareTitle;
      this.share.shareUrl = shareURL;

      const logLiedjeGedeeldEvent = (liedjeLink, auteur, naamVerhaal, permission) => {
        const params = {};
        params['liedjeLink'] = liedjeLink;
        params['auteur'] = auteur;
        params['naamLiedje'] = naamVerhaal;
        params['permission'] = permission;
        window['FB'].AppEvents.logEvent('Liedje Gedeeld', null, params);
      };

      logLiedjeGedeeldEvent(shareURL, author, songName, permission);
      const title = await this.translate.get('swal-share-ready-story').toPromise();
      const confirmButtonText = await this.translate.get('swal-share-ready-listen', {
        songName: songName,
      }).toPromise();

      this.share.shareHTML = await this.translate.get('swal-share-ready-message', {
        songName,
        shareURL,
        shareText,
        shareTitle,
        mailText,
        origin: window.location.origin,
      }).toPromise();

      if (!this.isNative) {
        await Swal.fire({
          titleText: title,
          text: ``,
          confirmButtonText: confirmButtonText,
          html: this.share.shareHTML,
          preConfirm: () => {
            if (!this.isNative) {
              let elementById = document.getElementById('swal-copylink') as HTMLInputElement;
              if (elementById) {
                elementById.select();
                document.execCommand('copy');
              }
              window.open(`${shareURL}`, '_blank', 'location=yes');
            }
          }
        });
      } else {
        this.shareSwal.fire();
      }
    }
  }

  openNativeBrowser() {
    SafariViewController.show({
      url: this.share.shareUrl,
      hidden: false,
      animated: false,
      transition: 'fade',
      enterReaderModeIfAvailable: false,
      tintColor: '#00ffff',
      barColor: '#000000',
      controlTintColor: '#ffffff'
    })
  }

  openNativeShare() {
    this.socialSharing.share(this.share.shareText, this.share.title, null, this.share.shareUrl);
  }
}
