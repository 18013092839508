import {Action} from '@ngrx/store';

export enum MuziekTimelineActionTypes {
  SetBlocks = '[MuziekTimeline] SetBlocks',
  SetMuziekVolume = '[MuziekTimeline] SetVolume',
  ResetMuziekTimeline = '[MuziekTimeline] Reset',
  ToggleMute = '[MuziekTimeline] ToggleMute',
  SetPercussion = '[MuziekTimeline] SetPercussion',
  RemovePercussion = '[MuziekTimeline] RemovePercussion',
  RemoveAudioSequence = '[MuziekTimeline] RemoveAudioSequence',
  SetPlaying = '[MuziekTimeline] SetPlaying',
  SetPlayingBlock = '[MuziekTimeline] SetPlayingBlock',
  GoToFirstBlock = '[MuziekTimeline] goToFirstBlock',
  PlayNextBlock = '[MuziekTimeline] PlayNextBlock',
  PlayPrevBlock = '[MuziekTimeline] PlayPrevBlock',
  IncrementAudioId = '[MuziekTimeline] incrementAudioId',
}

export class IncrementAudioId implements Action {
  readonly type = MuziekTimelineActionTypes.IncrementAudioId;

  constructor() {
  }
}

export class SetMuziekVolume implements Action {
  readonly type = MuziekTimelineActionTypes.SetMuziekVolume;

  constructor(public payload: { type: string, volume: number }) {
  }
}

export class ResetMuziekTimeline implements Action {
  readonly type = MuziekTimelineActionTypes.ResetMuziekTimeline;

  constructor() {
  }
}

export class SetBlocks implements Action {
  readonly type = MuziekTimelineActionTypes.SetBlocks;

  constructor(public payload: any[]) {
  }
}

export class SetPercussion implements Action {
  readonly type = MuziekTimelineActionTypes.SetPercussion;

  constructor(public payload: any) {
  }
}

export class RemovePercussion implements Action {
  readonly type = MuziekTimelineActionTypes.RemovePercussion;

  constructor(public payload: any) {
  }
}

export class RemoveAudioSequence implements Action {
  readonly type = MuziekTimelineActionTypes.RemoveAudioSequence;

  constructor(public payload: any) {
  }
}

export class SetPlaying implements Action {
  readonly type = MuziekTimelineActionTypes.SetPlaying;

  constructor(public payload: boolean) {
  }
}

export class SetPlayingBlock implements Action {
  readonly type = MuziekTimelineActionTypes.SetPlayingBlock;

  constructor(public payload: number | null) {
  }
}

export class GoToFirstBlock implements Action {
  readonly type = MuziekTimelineActionTypes.GoToFirstBlock;
}

export class PlayNextBlock implements Action {
  readonly type = MuziekTimelineActionTypes.PlayNextBlock;

  constructor() {
  }
}

export class PlayPrevBlock implements Action {
  readonly type = MuziekTimelineActionTypes.PlayPrevBlock;

  constructor() {
  }
}

export class ToggleMute implements Action {
  readonly type = MuziekTimelineActionTypes.ToggleMute;

  constructor(public payload: { id: number, instrumentIndex: number, sourceIndex: number }) {
  }
}

export type MuziekTimelineActions =
  SetBlocks |
  ToggleMute |
  PlayNextBlock |
  IncrementAudioId |
  PlayPrevBlock |
  GoToFirstBlock |
  SetPlayingBlock |
  SetPercussion |
  RemovePercussion |
  SetMuziekVolume |
  RemoveAudioSequence |
  ResetMuziekTimeline |
  SetPlaying;
