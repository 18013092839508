<div class="page page-login">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>

      <form class="form" id="login" name="login">
        <h2>{{'Login'|translate}}</h2>
        <input type="text" [(ngModel)]="email" name="email" placeholder="{{'Email'|translate}}" tabindex="1"
               required autocapitalize="off">
        <input type="password" [(ngModel)]="password" name="password" placeholder="{{'Wachtwoord'|translate}}"
               tabindex="2" required autocapitalize="off">

        <input type="submit" (pointerup)="login()" [disabled]="!email || !password" value="Login" tabindex="3">
        <a href="javascript:void(0);" routerLink="/register"
           class="btn submit">{{ 'Maak een account aan' |translate}}</a>
        <br>
        <br>
        <br>
        <p>
          <a href="javascript:void(0);" routerLink="/resetPassword"
             tabindex="-1">{{ 'Wachtwoord vergeten?' |translate}}</a>
        </p>
        <p>
          <a routerLink="/register" tabindex="-1" [innerHTML]="'account-need-one'|translate"></a>
        </p>
        <small [innerHTML]="'accept-privacy-policy-login'|translate">Als je jezelf inlogd ga je akkoord met onze
          <a href="https://deoasevandouz.be/assets/images/privacy.pdf" target="_blank" tabindex="-1">
            Privacy Policy
          </a>
        </small>
      </form>
      <br>
      <app-language-switcher></app-language-switcher>
    </div>

  </div>
</div>
