import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-button',
  templateUrl: './navigation-button.component.html',
  styleUrls: ['./navigation-button.component.scss']
})
export class NavigationButtonComponent implements OnInit {
  @Input() title: string;
  rotate: boolean;
  flip: boolean;

  constructor() {

  }

  ngOnInit() {
    this.flip = Math.random() >= 0.5;
    this.rotate = Math.random() >= 0.5;
  }
}
