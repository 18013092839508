import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from "../services/auth/auth.service";
import { Device } from '@ionic-native/device/ngx';
import { Network } from "@ionic-native/network/ngx";
import { isPlatformBrowser } from "@angular/common";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../services/i18n.service";
import { environment } from "../../environments/environment";
import { Platform } from "@ionic/angular";

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  browser: any = this.device.platform === 'browser';
  model: any = {};

  constructor(
    private device: Device,
    private http: HttpClient,
    public i18n: I18nService,
    private router: Router,
    private network: Network,
    private translate: TranslateService,
    public auth: AuthService,
    public platform: Platform,
  ) {
  }

  async checkInternetConnection() {
    if (this.network.type == 'none') {
      let [title, requirements] = await Promise.all([
        this.translate.get('swal-warning').toPromise(),
        this.translate.get('register-requirements').toPromise()]
      );
      Swal.fire({
        title: title,
        text: requirements,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  submit() {
    this.checkInternetConnection();

    this.auth.register(
      {
        'password': this.model.password,
        'c_password': this.model.checkpass,
        'email': this.model.email,
        'key': this.model.key || '',
      })
      .subscribe(
        async (val: any) => {
          let [title, success] = await Promise.all([
            this.translate.get('swal-purchase-already-title').toPromise(),
            this.translate.get('register-success').toPromise()]
          );

          await Swal.fire({
            title: title,
            text: success,
            icon: 'success',
            allowOutsideClick: false,
          });
          this.router.navigate(['/home']);
        },
        async response => {
          let [title, errorMessage] = await Promise.all([
            this.translate.get('swal-warning').toPromise(),
            this.translate.get(response.error.error || 'unauthorized').toPromise()]
          );
          Swal.fire({
            title: title,
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
  }

  ngOnInit() {
    this.checkInternetConnection();
  }
}
