import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireList } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { select, Store } from '@ngrx/store';

import { LocalStorage } from "@ngx-pwa/local-storage";
import { PictureUpload } from '../models/PictureUpload';
import { AuthService } from "./auth/auth.service";
import ImageTools from '../lib/ImageTools';
import { dataURIToBlob, dataURLtoBlob } from '../lib/utils';
// noinspection TypeScriptCheckImport
import * as EXIF from 'exif-js';
import * as fromStore from "../reducers";
import { File } from '@ionic-native/file/ngx';


declare var device;
declare var window;
import * as slugify from 'slug-generator';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  private basePathUploads = '/uploads';
  private muziekskes$: AngularFireList<{}>;
  private verhalen$: AngularFireList<{}>;
  signedIn: boolean;
  private emailAddress: string = '';
  isNative: boolean;
  iOS: boolean = true;

  constructor(private afd: AngularFireDatabase,
              private store: Store<fromStore.State>,
              public auth: AuthService,
              private file: File,
              protected localStorage: LocalStorage,
              private storage: AngularFireStorage) {
    this.isNative = device.platform.toUpperCase() === 'ANDROID' || device.platform.toUpperCase() === 'IOS';
    if (device.platform.toUpperCase() === 'ANDROID') {
      this.iOS = false;
    }

    this.auth.firebaseAnonymousLogin()
      .then(() => {
        return this.afterSignIn();
      });
  }

  afterSignIn() {
    return new Promise(resolve => {
      this.signedIn = true;
      console.log('setting up lists from firebase to push to')
      this.muziekskes$ = this.afd.list(`muziekskes`, ref =>
        ref.limitToFirst(3)
      );
      this.verhalen$ = this.afd.list(`verhalen`, ref =>
        ref.limitToFirst(3)
      );
      this.auth
        .getEmailAddress()
        .subscribe((email: string) => {
          this.emailAddress = email;
          resolve(this.emailAddress);
        })
    })
  }

  addMuziekske(state: any, volumes: any, artist: string = `artiest zonder naam`, songName: string = `untitled`, permission = false): Promise<string> {
    return new Promise((res, rej) => {
      this.auth
        .firebaseAnonymousLogin()
        .then(async () => {
          let email = await this.afterSignIn();
          this.muziekskes$
            .push({
              blocks: state,
              volumes: volumes,
              songName: songName,
              author: artist,
              email: email ? email : 'koray@weareundefined.be',
              permissionToShare: permission
            })
            .then((item) => {
              let sharingURL = `${window.location.origin}/muziek/${slugify(artist)}/${slugify(songName)}/${item.key}`;
              res(sharingURL);
            })
            .catch(error => {
              rej('something went wrong with adding "muziek" to firebase; \n' + JSON.stringify(error));
            });
        });
    })
  }

  async addVerhaaltje(timelineBlocks: any, volumes: any, artist: string = 'artiest zonder naam', songName: string = 'untitled', permission = false) {

    return new Promise((res, rej) => {
      this.auth
        .firebaseAnonymousLogin()
        .then(async () => {
          const newState = await this.uploadLocalStorageToFirebase(timelineBlocks);
          const email = await this.afterSignIn();
          this.verhalen$
            .push({
              blocks: newState.blocks,
              volumes: volumes,
              preloadFiles: newState.preloadFiles,
              songName: songName,
              author: artist,
              email: email ? email : 'koray@weareundefined.be',
              permissionToShare: permission
            })
            .then((item) => {
              let sharingURL = `https://deoasevandouz.be/verhaal/${slugify(artist)}/${slugify(songName)}/${item.key}`;
              res(sharingURL);
            })
            .catch(error => {
              rej('Something went wrong with adding "verhaal" to firebase; \n\n' + JSON.stringify(error));
            });
        })
        .catch(error => {
          rej('Something went wrong logging in to firebase; \n\n' + JSON.stringify(error));
        });
    });
  }

  uploadLocalStorageToFirebase(timelineBlocks): any {
    return new Promise(resolve => {
      const preloadPictures = [];
      const preloadVoices = [];
      this.localStorage.keys().subscribe((keys) => {
        let promises =
          keys
            .map(key => {
              if (key.startsWith('drawing-')) {
                return new Promise((resolve, reject) => {
                  this.localStorage.getItem(key).subscribe(async (drawing) => {
                    if ((drawing as any)['drawingBase64']) {
                      let drawingBase64 = (drawing as any)['drawingBase64'].replace(/^[^,]+,/, '');

                      //set metadata so we can have thumbnails in firebase
                      const metadata = {
                        contentType: (drawing as any)['drawingBase64'].match(/image\/[^;]+/)[0],
                      };

                      let drawingStorageRef =
                        this.storage
                          .ref(`${this.basePathUploads}/tekeningen/${this.guid()}_drawing`);

                      drawingStorageRef
                        .putString(drawingBase64, 'base64', metadata)
                        .then(resultSnap => {
                          resultSnap.ref.getDownloadURL().then(url => {
                            let currentBlock = timelineBlocks.find(block => block.id == key.replace('drawing-', ''));
                            if (currentBlock && currentBlock.drawing && url) {
                              currentBlock.drawing['url'] = url;
                              preloadPictures.push({ [key]: url });
                              resolve(currentBlock);
                            } else {
                              console.log(`drawing failed`, key, url);
                              resolve(currentBlock)
                            }
                          });
                        });
                    } else {
                      reject('no correct drawing was found')
                    }
                  })
                })
              } else if (key.startsWith('voice-')) {
                return new Promise((resolve, reject) => {
                  this.localStorage.getItem(key).subscribe(async (voice) => {
                    let blobFromLocalstorage;
                    let metadata;
                    if (!this.isNative) {
                      const dataUrlblob = (voice as any)['recordedBlobDataUrl'];
                      blobFromLocalstorage = await dataURLtoBlob(dataUrlblob);

                      metadata = {
                        contentType: 'audio/mp3'
                      };
                    } else { //native recording!
                      const recordingFileName = (voice as any)['recordingFileName'];
                      blobFromLocalstorage = await this.makeVoiceIntoBlob(recordingFileName);
                      metadata = {
                        contentType: 'audio/m4a'
                      };
                    }
                    let recordingStorageRef =
                      this.storage
                        .ref(`${this.basePathUploads}/voice/${this.guid()}_voice`);

                    recordingStorageRef
                      .put(blobFromLocalstorage, metadata)
                      .then(resultSnap => {
                        resultSnap.ref.getDownloadURL().then(url => {
                          let currentBlock = timelineBlocks.find(block => block.id == key.replace('voice-', ''));

                          if (currentBlock) {
                            currentBlock['voiceUrl'] = url;
                          } else {
                            console.error(`❗️ no block found for this voice ${url ? 'url for this voice is ' + url : ''}`);
                          }

                          console.debug(`✅ voice succesfully uplaoded ${this.basePathUploads}/voice/${this.guid()}_voice ${url}`);
                          if (currentBlock && currentBlock['voiceUrl'] && url) {
                            preloadVoices.push({ [key]: url });
                            resolve(currentBlock);
                          } else {
                            resolve(currentBlock);
                            throw Error(`❗voice upload failed ${key}`);
                          }
                        });
                      });
                  });
                });
              }
            });

        Promise.all(promises).then(_ => {
          resolve({
            blocks: timelineBlocks,
            preloadFiles: { pictures: preloadPictures, voices: preloadVoices }
          });
        })
      });
    });
  }

  resizeImage(upload: PictureUpload, maxDimensions: { width: number, height: number }): Promise<File> {
    return new Promise((resolve) => {
      // @ts-ignore
      EXIF.getData(upload.blob, () => {
        const orientation = EXIF.getTag(this, 'Orientation');

        ImageTools.resize(upload.blob, maxDimensions, orientation || 0, (base64File) => {
          resolve(base64File);
        });
      });
    });
  }

  private guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  makeVoiceIntoBlob(_voicePath) {
    // INSTALL PLUGIN - cordova plugin add cordova-plugin-file
    return new Promise((resolve, reject) => {

      if (this.iOS) {
        window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, async fs => {
          window.resolveLocalFileSystemURL(this.file.documentsDirectory, async dirEntry => {
            let justTheNameOfTheFile = _voicePath.split('://')[1];

            dirEntry.getFile(justTheNameOfTheFile, { create: false, exclusive: false }, fileEntry => {
              console.log(`found a file entry for ${justTheNameOfTheFile}`);
              this.readFile(fileEntry, resolve, reject);
            });
          }, (error) => {
            throw Error('something went wrong with resolving the file, trying to resolve without FilesystemURL ;; \n' + JSON.stringify(error))
          });
        });
      } else {
        window.requestFileSystem(window.LocalFileSystem.PERSISTENT, 0, async fs => {

          window.resolveLocalFileSystemURL(this.file.externalApplicationStorageDirectory, async dirEntry => {
            let justTheNameOfTheFile = _voicePath.split('/').pop();
            console.log('justTheNameOfTheFile -> ' + justTheNameOfTheFile);
            dirEntry.getFile(justTheNameOfTheFile, { create: false, exclusive: false }, fileEntry => {
              console.log(`found a file entry for ${justTheNameOfTheFile}`);
              this.readFile(fileEntry, resolve, reject);
            });
          });
        });
      }
    });
  }

  private readFile(fileEntry, resolve, reject) {
    console.log(fileEntry);

    fileEntry.file((file) => {
      const reader = new FileReader();
      reader.onloadend = (evt: any) => {
        var voiceBlob: any = new Blob([evt.target.result], { type: 'audio/m4a' });
        resolve(voiceBlob);
      };
      reader.onerror = (e) => {
        console.log('Failed file read: ' + e.toString());
        reject(e);
      };
      reader.readAsArrayBuffer(file);
    }, error => {
      throw Error('something went wrong with "readFile()" function \n' + JSON.stringify(error))
    });
  }
}
