import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Platform } from '@ionic/angular';
import { IAPProduct, InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {
  product: IAPProduct;

  ready$ = new BehaviorSubject(false);
  price$ = new BehaviorSubject(null);
  productOwned$ = new Subject();
  error$ = new BehaviorSubject(null);

  productId: string;
  alreadyOwned: boolean;

  constructor(
    private store: InAppPurchase2,
    public platform: Platform,
  ) {
    if (this.platform.is('ios')) {
      this.productId = environment.pro_version.appleProductId;
    } else if (this.platform.is('android')) {
      this.productId = environment.pro_version.googleProductId;
    }
  }

  async init() {
    return new Promise(res => {
      try {
        // Register the product with the store
        this.store.register({
          id: this.productId,
          alias: this.productId,
          type: this.store.NON_CONSUMABLE
        });

        this.store.validator = environment.pro_version.validator;

        this.registerHandlers();

        this.store.ready(() => {
          console.log('purchase ready');

          this.product = this.store.products.find(product => product.id === this.productId);

          if (this.product && this.product.id === this.productId && this.product.owned) {
            console.log('product owned in ready call');
            this.alreadyOwned = true;
            this.productOwned$.next(true);
            res();
          }

          this.price$.next(this.product.price);
          setTimeout(() => {
            this.ready$.next(true);
          }, 2000);
          res();
        });
        this.store.refresh();
      } catch (error) {
        throw Error('Error On Store Issues \n' + JSON.stringify(error))
      }
    });
  }

  refresh() {
    console.log('refresh store', this.alreadyOwned);
    this.store.refresh();
    if (this.alreadyOwned) {
      this.productOwned$.next(true);
    }
  }

  async purchase() {
    this.error$.next(null);

    console.log(`@InAppPurchase : <ORDERING>`, this.productId);
    if (this.alreadyOwned) {
      throw Error(`@InAppPurchase : <ORDERING> oordering while already owned on this device`);
      return;
    }
    try {
      return await this.store.order(this.productId);
    } catch (err) {
      this.error$.next(true);
      console.log('purchase error');
      console.log(`@InAppPurchase : <ERROR after ORDER>`, err);

      throw Error(`@InAppPurchase : <ERROR after ORDER>\n ${JSON.stringify(err)}`);
    }
  }

  registerHandlers() {
    // Errors On The Specific Product
    this.store.when(this.productId).error((error) => {
      this.error$.next(true);
      throw Error('something went wrong with "registerHandlers()" inside purchase service \n' + JSON.stringify(error))
    });

    this.store.when(this.productId).approved((product: IAPProduct) => {
      if (product.owned) {
        this.product = product;
        console.log(`@InAppPurchase : <APPROVED> product state is owned `, product);
        this.alreadyOwned = true;
        this.productOwned$.next(true);
      } else {
        // Purchase was approved
        console.log(`@InAppPurchase : <APPROVED> purchase apporved, starting verification via verification server`, product);
        product.verify();
      }
    });

    this.store.when(this.productId).owned((product: IAPProduct) => {
      this.product = product;
      console.log(`@InAppPurchase : <OWNED> Product is owned `, product);
      this.productOwned$.next(true);
    });

    this.store.when(this.productId).verified((product: IAPProduct) => {
      // Purchase was verified
      console.log(`@InAppPurchase : <VERIFIED>`, product);
      this.product = product;
      product.finish();
      console.log('product owned in verified');
      this.productOwned$.next(true);
    });

    this.store.when(this.productId).registered((product: IAPProduct) => {
      console.log(`@InAppPurchase : <REGISTERED>`, product);
      this.product = product;
    });

    this.store.when(this.productId).updated((product: IAPProduct) => {
      console.log(`@InAppPurchase : <UPDATED>:  already bought or not? ` + product.state);
      if (product.owned) {
        this.product = product;
        console.log('product state updated and already  owned ');
        this.productOwned$.next(true);
      }
    });

    this.store.when(this.productId).cancelled((product) => {
      // this.error$.next(true);
      console.log(`@InAppPurchase : <CANCELLED>`, product);
    });

    // Overall Store Error
    this.store.error((err) => {
      this.error$.next(true);
      console.log(`@InAppPurchase : <ERROR>`, err);
      throw Error(`@InAppPurchase : <ERROR> \n ${JSON.stringify(err)}`);
    });
  }
}

