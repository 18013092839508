import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import Swal from "sweetalert2";
import { TranslateService } from '@ngx-translate/core';

declare var require: any;
const { detect } = require('detect-browser');


@Component({
  selector: 'app-browser',
  templateUrl: './browser.component.html',
  styleUrls: ['./browser.component.scss']
})
export class BrowserComponent {
  public open = false;
  public browser;

  constructor(@Inject(PLATFORM_ID) private platformId: Object, private translate: TranslateService) {
    if (isPlatformBrowser(this.platformId)) {
      this.browser = detect();

      console.log(this.browser.name);

      if (this.browser.name === 'firefox' || this.browser.name === 'edge') {
        this.open = true;
        this.showBrowserAlert()
      }
    }
  }

  async showBrowserAlert() {
    let html = await this.translate.get('optimale-ervaring').toPromise();
    let title = await this.translate.get('swal-warning').toPromise();
    await Swal.fire({
      title: title,
      html: html,
      icon: 'warning',
      allowOutsideClick: false,
      showConfirmButton: false,
    });
  }

  public continue() {
    localStorage.setItem('browsercheck', 'false');
    this.open = false;
  }

  private download() {
    console.log('browsercheck');
    let url = 'https://www.google.com/chrome/';

    window.open(url, '_blank', "location=no,clearsessioncache=yes");
    window.focus();

    localStorage.setItem('browsercheck', 'false');
    this.open = false;
  }
}
