import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import Swal from 'sweetalert2';
import { isPlatformBrowser } from "@angular/common";
import { AuthService } from '../services/auth/auth.service';
import { TokenService } from "../services/auth/token.service";
import { Network } from '@ionic-native/network/ngx';
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../services/i18n.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email: string;
  password: string;
  networkAvailable: boolean = false;

  constructor(private http: HttpClient,
              private router: Router,
              public auth: AuthService,
              public tokenService: TokenService,
              public i18n: I18nService,
              private translate: TranslateService,
              private network: Network,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  async checkInternetConnection() {
    if (this.network.type == 'none') {
      let [title, requirements] = await Promise.all([
        this.translate.get('swal-warning').toPromise(),
        this.translate.get('login-requirements').toPromise()]
      );
      Swal.fire({
        title: title,
        text: requirements,
        icon: 'error',
        confirmButtonText: 'Ok'
      });
    }
  }

  login() {
    Swal.showLoading();
    this.checkInternetConnection();

    this.auth
      .login({ email: this.email, password: this.password })
      .toPromise()
      .then(async email => {
        this.auth.changeAuthStatus(true);
        await this.router.navigate(['/home']);
        Swal.close();
      })
      .catch(
        async response => {
          let [title, errorMessage] = await Promise.all([
            this.translate.get('swal-warning').toPromise(),
            this.translate.get(response.error.error || 'unauthorized').toPromise()]
          );
          Swal.fire({
            title: title,
            text: errorMessage,
            icon: 'error',
            confirmButtonText: 'Ok'
          });
        });
  }

  ngOnInit() {
    this.checkInternetConnection();

    if (isPlatformBrowser(this.platformId) && this.tokenService.loggedIn()) {
      this.router.navigate(['/home']);
    }
  }
}
