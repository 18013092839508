import { Component, NgZone, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import {
  GoToFirstBlock, ImportState,
  PlayNextBlock,
  SetPlaying,
} from '../../actions/playerTimeline.actions';
import { AudioSequence } from '../../interfaces/AudioSequence';
import { AudioPlayerService } from '../../services/audio-player.service';
import { AudioSequencesService } from '../../services/audio-sequences.service';
import { takeUntil, first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../reducers';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { Howl } from 'howler';
import Swal from 'sweetalert2';
import { AuthService } from '../../services/auth/auth.service';
import { I18nService } from "../../services/i18n.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'verhaal-player',
  templateUrl: './verhaal-player.component.html',
  styleUrls: ['./player.component.scss']
})
export class VerhaalPlayerComponent implements OnInit, OnDestroy {
  public artist: string;
  public song: string;
  public id: any;
  public timelinePlaying$: Observable<any>;
  public timelineBlocks$: Observable<AudioSequence[]>;
  public timelinePlayingIndex$: Observable<number>;
  public currentDrawing: SafeUrl;
  public loading = true;
  public loadingBlocks = true;

  private destroyed$ = new Subject();
  private routeParamSubscription: Subscription;
  private timelinePlayingBlock$: Observable<AudioSequence>;
  private sharedSong: any;

  volumes: { [p: string]: number } = {};

  audioId$: Observable<number>;
  audioId: number;
  currentBlockId: any;
  currentPlaying: any;
  shareUrl: Location;
  viaURl: string;
  shareTitle: string;
  shareText: string;

  constructor(private domSanitizer: DomSanitizer,
              private zone: NgZone,
              private route: ActivatedRoute,
              private store: Store<fromStore.State>,
              private audioPlayerService: AudioPlayerService,
              @Inject(DOCUMENT) private document,
              @Inject(PLATFORM_ID) private platformId: Object,
              private titleService: Title,
              public auth: AuthService,
              public i18n: I18nService,
              private translate: TranslateService,
              private afd: AngularFireDatabase,
              private metaTagService: Meta,
              private audioSequenceService: AudioSequencesService) {
  }

  ngOnInit() {
    this.routeParamSubscription = this.route.paramMap.subscribe(async params => {
      this.shareUrl = window.location;
      this.viaURl = window.location.origin;

      this.artist = decodeURIComponent(params.get('author'));
      this.song = decodeURIComponent(params.get('song'));
      this.shareTitle = await this.translate.get('swal-shareTitle-story').toPromise();
      this.shareText = await this.translate.get('swal-shareText', {
        songName: this.song,
        author: this.artist
      }).toPromise();

      // this.shareTitle = encodeURIComponent(`Beluister hier ${this.song} gemaakt door ${this.artist}`);
      // this.shareText = encodeURIComponent(`${this.artist} heeft een liedje gemaakt via De Oase Van Douz. Beluister het hier`);

      this.id = params.get('id');
      this.setOgTags();
      this.loading = true;

      // for some reason firebase does not work on the server with angular universal :(
      if (isPlatformBrowser(this.platformId)) {
        this.auth.firebaseAnonymousLogin()
          .then(() => {
            return this.afterSignIn();
          });
      }
    });
  }

  afterSignIn() {
    // get the shared song from firebase
    this.afd
      .object(`verhalen/${this.id}`)
      .valueChanges()
      .pipe(first())
      .subscribe(async value => {
        if (value) {
          this.artist = value['author'] ? decodeURIComponent(value['author']) : this.artist;
          this.song = value['songName'] ? decodeURIComponent(value['songName']) : this.song;
          this.sharedSong = value['blocks'];
          this.setOgTags();

          const preloadFiles = value['preloadFiles'] || {};

          let voicePromises = [];
          if ('voices' in preloadFiles && preloadFiles.voices && preloadFiles.voices.length > 0) {
            voicePromises = preloadFiles.voices.map((voice) => {
              return new Promise(resolve => {
                const voiceKey = Object.keys(voice)[0];
                const voiceUrl = `${Object.values(voice)[0]}`;

                new Howl({
                  volume: 0, // needed for fadein to work
                  src: [voiceUrl],
                  html5: true, // Force to HTML5 which apparently is less buggy in Chrome etc
                  format: ['mp3']
                });

                const currentBlock = this.sharedSong.find(block => block.id == voiceKey.replace('voice-', ''));
                currentBlock.voiceRecording = voiceUrl;
                console.log('voice loaded');
                resolve(currentBlock);
              });
            });
          }

          let picturePromises = [];
          if ('pictures' in preloadFiles && preloadFiles.pictures && preloadFiles.pictures.length > 0) {
            picturePromises =
              preloadFiles.pictures.map(picture => {
                const pictureKey = Object.keys(picture)[0];
                const pictureUrl = `${Object.values(picture)[0]}`;

                return this.getBase64ImageFromUrl(pictureUrl)
                  .then(result => {
                    const currentBlock = this.sharedSong.find(block => block.id == pictureKey.replace('drawing-', ''));
                    currentBlock.drawing = result;
                  })
                  .catch(err => {
                    throw Error(err)
                  });
              });
          }

          Promise.all(voicePromises.concat(picturePromises)).then(result => {
            this.store.dispatch(new ImportState({ blocks: value['blocks'], volumes: value['volumes'] }));
            this.proceed();
          });
        } else {

          const [title, text] = await Promise.all([
            this.translate.get('swal-warning').toPromise(),
            this.translate.get('player-error-story').toPromise()]
          );

          Swal.fire({
            title: title,
            text: text,
            icon: 'error',
            confirmButtonText: 'Ok',
          });
        }
      });
  }

  private async proceed() {
    this.timelineBlocks$ = this.store.pipe(select(fromStore.getPlayerTimelineBlocks));
    this.timelinePlayingIndex$ = this.store.pipe(select(fromStore.getPlayerTimelinePlayingIndex));
    this.timelinePlayingBlock$ = this.store.pipe(select(fromStore.getPlayerTimeLinePlayingBlock));
    this.timelinePlaying$ = this.store.pipe(select(fromStore.getPlayerTimelinePlaying));
    this.audioId$ = this.store.pipe(select(fromStore.getPlayerAudioId));
    this.loading = false;

    this.store.pipe(select(fromStore.getPlayerVolumes))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((volumes) => {
        this.volumes = volumes;
        this.audioPlayerService.setVolumes(volumes);
      });

    this.timelineBlocks$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (blocks) => {
        const sounds = this.audioSequenceService.extractSoundsFromBlocks(blocks);
        this.loadingBlocks = true;
        this.setPlaying(false);
        await this.audioPlayerService.preload(sounds);
        this.loadingBlocks = false;
      });

    this.audioId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (audioId) => {
        this.audioId = audioId;
      });
    this.timelinePlaying$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((playing) => {
        if (this.currentBlockId === null) {
          this.currentPlaying = playing;

          return;
        }

        if (playing) {
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        } else {
          this.audioPlayerService.stopPlaying();
        }

        this.currentPlaying = playing;
      });

    this.timelinePlayingBlock$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((block) => {
        if (block) {
          block.sources.forEach((sourceList) => {
            sourceList.forEach((source) => {
              this.audioPlayerService.setMuted(source.src, !!source.muted);
            });
          });

          if (block.drawing) {
            this.currentDrawing = block.drawing;
          } else {
            this.currentDrawing = null;
          }
        }

        if (this.currentPlaying && (!block || block.id === this.currentBlockId)) {
          // this happens when percussion is dragged onto block while playing
          // stop playback immediately and proceed
          this.currentBlockId = block ? block.id : null;
          this.audioPlayerService.stopPlaying();
        }

        // check if there is a sound currently playing
        if (this.currentPlaying) {
          this.audioPlayerService.stopPlaying();
        }
        this.audioPlayerService.setBlock(block); // VERY IMPORTANT BECAUSE IT DETERMINES THE DURATION OF THE SOUNDS

        if (this.currentPlaying) {
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        }

        this.currentBlockId = block ? block.id : null;
      });
  }

  setOgTags() {
    const newTitle = `${this.song} door ${this.artist} | De Oase Van Douz`;
    const newDescription = `Luister naar het verhaal "${this.song}" gemaakt door ${this.artist} met de Oase Van Douz App.`;

    this.titleService.setTitle(newTitle);
    this.metaTagService.updateTag({ property: 'og:title', content: newTitle });

    this.metaTagService.updateTag({
      name: 'description',
      content: newDescription
    });
    this.metaTagService.updateTag({ property: 'og:description', content: newDescription });
  }

  goToFirstBlock($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.store.dispatch(new SetPlaying(false));
    this.store.dispatch(new GoToFirstBlock());
  }

  setPlaying(play: boolean) {
    this.store.dispatch(new SetPlaying(play));
  }

  ngOnDestroy(): void {
    this.routeParamSubscription.unsubscribe();
    this.destroyed$.next();
  }

  async getBase64ImageFromUrl(imageUrl) {
    const res = await fetch(imageUrl);
    const blob = await res.blob();

    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = (evt: any) => {
        resolve([evt.target.result]);
      };

      reader.onerror = () => {
        return reject(this);
      };
      reader.readAsDataURL(blob);
    });
  }
}
