export default class ScrollBarProperties {
  private _isTop: boolean;
  private _isBottom: boolean;
  private _isEnabled: boolean;

  constructor() {
    this._isTop = true;
    this._isBottom = false;
    this._isEnabled = false;
  }

  get isTop() {
    return this._isTop;
  }

  get isBottom() {
    return this._isBottom;
  }

  get isEnabled() {
    return this._isEnabled;
  }

  set isBottom(bottom: boolean) {
    if (this.isTop && bottom) {
      this._isTop = false;
    }
    this._isBottom = bottom;
  }

  set isTop(top: boolean) {
    if (this.isBottom && top) {
      this._isBottom = false;
    }
    this._isTop = top;
  }

  enable(enable: boolean = true) {
    this._isEnabled = enable;
  }
}
