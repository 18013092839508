import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SkyhookDndService } from '@angular-skyhook/core';

@Component({
  selector: 'app-percussion-source',
  templateUrl: './percussion-source.component.html',
  styleUrls: ['./percussion-source.component.scss']
})
export class PercussionSourceComponent implements OnInit, OnDestroy {
  @Input('percussion') percussion;

  source = this.dnd.dragSource('PERCUSSION', {
    beginDrag: () => (this.percussion),
  });

  isDragging$ = this.source.listen(monitor => monitor.isDragging());

  constructor(private dnd: SkyhookDndService) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.source.unsubscribe();
  }
}
