import { Component, OnInit } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../../services/i18n.service";

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss']
})
export class LanguageSwitcherComponent implements OnInit {
  languages: Promise<{ code: string; active: boolean; }[]>;

  constructor(private translate: TranslateService,
              private i18n: I18nService,) {
  }

  ngOnInit() {
    this.languages = this.i18n.getLanguages();
  }

  changeLanguage(lang) {
    this.i18n.setLanguage(lang);
    this.languages = this.i18n.getLanguages()
  }
}
