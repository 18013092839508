export const data = {
  'audio': {
    'percussie': {
      'icon': 'percussie',
      'type': 'percussion',
      'samples': [
        {
          'name': 'Castagnette',
          'icon': 'castagnettes',
          'src': 'perc/castagnette',
        },
        {
          'name': 'Drums traag',
          'icon': 'drums1',
          'src': 'perc/drums_1',
        },
        {
          'name': 'Drums middel',
          'icon': 'drums2',
          'src': 'perc/drums_2',
        },
        {
          'name': 'Drums snel',
          'icon': 'drums3',
          'src': 'perc/drums_3',
        },
        {
          'name': 'Drums wals',
          'icon': 'drums4',
          'src': 'perc/drums_4',
        },
        {
          'name': 'Darbouka',
          'icon': 'darbouka1',
          'src': 'perc/darbouka_1',
        },
        {
          'name': 'Darbouka alternatief',
          'icon': 'darbouka2',
          'src': 'perc/darbouka_2',
        },
        {
          'name': 'Bongo',
          'icon': 'darbouka3',
          'src': 'perc/bongo_2',
        },
        {
          'name': 'Bongo alternatief',
          'icon': 'darbouka4',
          'src': 'perc/bongo_3',
        },
      ]
    },
    'De oase van Douz': {
      'icon': 'douz',
      'type': 'audio',
      'files': [{
        'name': 'loop intro douz',
        'icon': 'palmboom',
        'durations': [6.00097],
        'sources': [
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_14',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_14',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_14',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_14',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_14',
              'icon': 'tuba'
            }
          ]
        ]
      },

        {
          'name': 'thema douz',
          'icon': 'tajines',
          'durations': [15.00396, 21.00501],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_15',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_15',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_15',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_15',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_15',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_16',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_16',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_16',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_16',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_16',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'tempo des chameaux, loop 1',
          'icon': 'buikdansen',
          'durations': [8],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_17',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_17',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_17',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_17',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_17',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'prachtige dingen... loop 1',
          'icon': 'minaret',
          'durations': [8],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_18',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_18',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_18',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_18',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_18',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'actie loop',
          'icon': 'fakir',
          'durations': [3],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_19',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_19',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_19',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_19',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_19',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'actie vlucht...',
          'icon': 'puntschoenen',
          'durations': [6, 17.97897],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_20',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_20',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_20',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_20',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_20',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_21',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_21',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_21',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_21',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_21',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'gewond',
          'icon': 'aap',
          'durations': [27.00700],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_22',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_22',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_22',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_22',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_22',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'heimwee',
          'icon': 'raam',
          'durations': [12.00299],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_23',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_23',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_23',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_23',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_23',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'tempo des chameaux, loop 2',
          'icon': 'muntthee',
          'durations': [8],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_24',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_24',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_24',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_24',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_24',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'prachtige dingen... loop 2',
          'icon': 'waterpijp',
          'durations': [8],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_25',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_25',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_25',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_25',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_25',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'vertrek karavaan',
          'icon': 'camel',
          'durations': [4, 10, 12, 8],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_26',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_26',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_26',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_26',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_26',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_27',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_27',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_27',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_27',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_27',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_28',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_28',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_28',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_28',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_28',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_29',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_29',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_29',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_29',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_29',
                'icon': 'tuba'
              }
            ]
          ]
        }
      ]
    },
    'thuis': {
      'icon': 'thuis',
      'type': 'audio',
      'files': [{
        'name': 'intro',
        'icon': 'boom',
        'durations': [6, 12, 12, 6, 6, 6],
        'sources': [
          [
            {
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_1',
              'icon': 'trumpet1'
            },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_1',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_1',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_1',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_1',
              'icon': 'tuba'
            }
          ],
          [
            {
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_2',
              'icon': 'trumpet1'
            },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_2',
              'icon': 'trumpet1'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_2',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_2',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_2',
              'icon': 'tuba'
            }
          ],
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_3',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_3',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_3',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_3',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_3',
              'icon': 'tuba'
            }
          ],
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_4',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_4',
              'icon': 'trumpet1'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_4',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_4',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_4',
              'icon': 'tuba'
            }
          ],
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_5',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_5',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_5',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_5',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_5',
              'icon': 'tuba'
            }
          ],
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_6',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_6',
              'icon': 'trumpet1'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_6',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_6',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_6',
              'icon': 'tuba'
            }
          ]
        ]
      },

        {
          'name': 'thuis loop',
          'icon': 'tak',
          'durations': [12],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_7',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_7',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_7',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_7',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_7',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema, soft + discussie',
          'icon': 'huis',
          'durations': [12.00299, 12.00299, 15.00297, 9.00299, 6.00097],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_8',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_8',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_8',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_8',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_8',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_9',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_9',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_9',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_9',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_9',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_10',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_10',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_10',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_10',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_10',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_11',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_11',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_11',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_11',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_11',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_12',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_12',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_12',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_12',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_12',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'vertrek',
          'icon': 'reiskoffer',
          'durations': [18.00399],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_13',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_13',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_13',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_13',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_13',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'intro l\'inverno...',
          'icon': 'blad',
          'durations': [1.5, 1.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_82',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_82',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_82',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_82',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_82',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_83',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_83',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_83',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_83',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_83',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema trompet',
          'icon': 'koekoek1',
          'durations': [6.00097, 5.25099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_84',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_84',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_84',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_84',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_84',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_85',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_85',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_85',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_85',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_85',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'loop snel, ...',
          'icon': 'nest',
          'durations': [3.00099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_86',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_86',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_86',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_86',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_86',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema l\'inverno trombone',
          'icon': 'koekoek2',
          'durations': [6.00097],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_87',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_87',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_87',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_87',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_87',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema deel twee, trombone',
          'icon': 'klok',
          'durations': [6.00097],
          'sources': [
            [{
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_88',
              'icon': 'trombone'
            },
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_88',
                'icon': 'trumpet1'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_88',
                'icon': 'horn'
              },

              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_88',
                'icon': 'trumpet2'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_88',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema bastuba',
          'icon': 'boekentas',
          'durations': [5.25099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_89',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_89',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_89',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_89',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_89',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'klokken einde',
          'icon': 'bomen',
          'durations': [6.00097],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_90',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_90',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_90',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_90',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_90',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'coda',
          'icon': 'ei',
          'durations': [3.00099, 3.00099, 0.74997, 1.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_91',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_91',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_91',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_91',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_91',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_92',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_92',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_92',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_92',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_92',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_93',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_93',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_93',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_93',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_93',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_94',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_94',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_94',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_94',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_94',
                'icon': 'tuba'
              }
            ]
          ]
        }
      ]
    },
    'De zee': {
      'icon': 'zee',
      'type': 'audio',
      'files': [{
        'name': 'dezee (wind)',
        'icon': 'zee',
        'durations': [6],
        'sources': [
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_30',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_30',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_30',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_30',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_30',
              'icon': 'tuba'
            }
          ]
        ]
      },
        {
          'name': 'deZee!',
          'icon': 'vis',
          'durations': [10.49997, 6],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_31',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_31',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_31',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_31',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_31',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_32',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_32',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_32',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_32',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_32',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'Epic Horn thema 1',
          'icon': 'anker',
          'durations': [3, 3, 12, 4.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_33',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_33',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_33',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_33',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_33',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_34',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_34',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_34',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_34',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_34',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_35',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_35',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_35',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_35',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_35',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_36',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_36',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_36',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_36',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_36',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'zee loop gevaar....',
          'icon': 'kraken',
          'durations': [4.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_37',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_37',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_37',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_37',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_37',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'Epic Horn thema 2e x',
          'icon': 'piraten',
          'durations': [6, 12, 4.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_38',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_38',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_38',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_38',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_38',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_39',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_39',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_39',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_39',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_39',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_40',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_40',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_40',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_40',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_40',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'de zee afsluiter',
          'icon': 'drietand',
          'durations': [6.75],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_41',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_41',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_41',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_41',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_41',
                'icon': 'tuba'
              }
            ]
          ]
        }
      ]
    },
    'Spanje': {
      'icon': 'spanje',
      'type': 'audio',
      'files': [{
        'name': 'intro Spanje ?',
        'icon': 'don_quichot',
        'durations': [18.00399],
        'sources': [
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_42',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_42',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_42',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_42',
              'icon': 'trombone'
            },

            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_42',
              'icon': 'tuba'
            }
          ]
        ]
      },

        {
          'name': 'Spanje !!!',
          'icon': 'stier',
          'durations': [13.50299, 4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_43',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_43',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_43',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_43',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_43',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_44',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_44',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_44',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_44',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_44',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'Spanje malaguena loop 1',
          'icon': 'paella',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_45',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_45',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_45',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_45',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_45',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema malaguena 1',
          'icon': 'waaier',
          'durations': [9.00199],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_46',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_46',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_46',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_46',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_46',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'Spanje malaguena loop 2',
          'icon': 'spaanse_peper',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_47',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_47',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_47',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_47',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_47',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'handclaps...',
          'icon': 'flamenco',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_48',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_48',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_48',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_48',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_48',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema spanje 2',
          'icon': 'sangria_drank',
          'durations': [4.50099, 4.50099, 5.62600, 3.37600, 2.25099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_49',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_49',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_49',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_49',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_49',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_50',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_50',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_50',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_50',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_50',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_51',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_51',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_51',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_51',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_51',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_52',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_52',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_52',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_52',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_52',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_53',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_53',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_53',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_53',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_53',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'background spanje',
          'icon': 'gitaar',
          'durations': [4.50099, 4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_54',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_54',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_54',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_54',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_54',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_55',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_55',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_55',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_55',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_55',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'thema spanje 3 ( olé )',
          'icon': 'stierenvechter',
          'durations': [4.50099, 5.62600, 4.12600, 1.5],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_56',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_56',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_56',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_56',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_56',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_57',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_57',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_57',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_57',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_57',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_58',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_58',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_58',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_58',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_58',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_59',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_59',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_59',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_59',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_59',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'Spanje malaguena loop 3',
          'icon': 'paard',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_60',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_60',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_60',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_60',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_60',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'malaguena uitsterven...',
          'icon': 'windmolen',
          'durations': [14.62900],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_61',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_61',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_61',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_61',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_61',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'hoorn, tuba',
          'icon': 'jambon',
          'durations': [10.50299],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_62',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_62',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_62',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_62',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_62',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'trombone solo,... droom',
          'icon': 'sagrada_familia',
          'durations': [24.75598],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_63',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_63',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_63',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_63',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_63',
                'icon': 'tuba'
              }
            ]
          ]
        }
      ]
    },
    'Frankrijk': {
      'icon': 'parijs',
      'type': 'audio',
      'files': [{
        'name': 'loop wals',
        'icon': 'kaas',
        'durations': [4.50099],
        'sources': [
          [{
            'name': 'Trumpet1',
            'src': 'instr/Trumpet_-_1_-_64',
            'icon': 'trumpet1'
          },
            {
              'name': 'Trumpet2',
              'src': 'instr/Trompet2_-_1_-_64',
              'icon': 'trumpet2'
            },
            {
              'name': 'Horn',
              'src': 'instr/Horn_-_1_-_64',
              'icon': 'horn'
            },
            {
              'name': 'Trombone',
              'src': 'instr/trombone_-_1_-_64',
              'icon': 'trombone'
            },
            {
              'name': 'Tuba',
              'src': 'instr/tuba_-_1_-_64',
              'icon': 'tuba'
            }
          ]
        ]
      },

        {
          'name': 'thema bugel',
          'icon': 'eiffeltoren',
          'durations': [9.00199, 4.50099, 4.50099, 4.50099, 4.50099, 6.75199],
          'sources': [
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_65',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_65',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_65',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_65',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_65',
                'icon': 'tuba'
              }
            ],
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_66',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_66',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_66',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_66',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_66',
                'icon': 'tuba'
              }
            ],
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_67',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_67',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_67',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_67',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_67',
                'icon': 'tuba'
              }
            ],
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_68',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_68',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_68',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_68',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_68',
                'icon': 'tuba'
              }
            ],
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_69',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_69',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_69',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_69',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_69',
                'icon': 'tuba'
              }
            ],
            [
              {
                'name': 'Trumpet1',
                'src': 'instr/Trumpet_-_1_-_70',
                'icon': 'trumpet1'
              },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_70',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_70',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_70',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_70',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'franse loop 2',
          'icon': 'stokbrood',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_71',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_71',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_71',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_71',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_71',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'thema trombone',
          'icon': 'keuken',
          'durations': [4.50099, 4.50099, 4.50099, 4.50099, 4.50099, 4.50099, 6.75199],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_72',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_72',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_72',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_72',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_72',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_73',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_73',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_73',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_73',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_73',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_74',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_74',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_74',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_74',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_74',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_75',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_75',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_75',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_75',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_75',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_76',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_76',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_76',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_76',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_76',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_77',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_77',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_77',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_77',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_77',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_78',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_78',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_78',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_78',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_78',
                'icon': 'tuba'
              }
            ]
          ]
        },
        {
          'name': 'franse loop 3',
          'icon': 'wijn',
          'durations': [4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_79',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_79',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_79',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_79',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_79',
                'icon': 'tuba'
              }
            ]
          ]
        },

        {
          'name': 'einde FR',
          'icon': 'mont_saint_michel',
          'durations': [2.25099, 4.50099],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_80',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_80',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_80',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_80',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_80',
                'icon': 'tuba'
              }
            ],
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_81',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_81',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_81',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_81',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_81',
                'icon': 'tuba'
              }
            ]
          ]
        }
      ]
    },
    'pauze': {
      'icon': 'rust',
      'type': 'audio',
      'files': [
        {
          'name': 'pauze',
          'icon': 'slaap1',
          'durations': [3],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },

              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }
            ]]
        },
        {
          'name': 'pauze',
          'icon': 'slaap2',
          'durations': [3, 3],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }]
          ]
        }, {
          'name': 'pauze',
          'icon': 'slaap3',
          'durations': [3, 3, 3, 3],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }]
          ]
        },
        {
          'name': 'pauze',
          'icon': 'slaap4',
          'durations': [23.96],
          'sources': [
            [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }], [{
              'name': 'Trumpet1',
              'src': 'instr/Trumpet_-_1_-_95',
              'icon': 'trumpet1'
            },
              {
                'name': 'Trumpet2',
                'src': 'instr/Trompet2_-_1_-_95',
                'icon': 'trumpet2'
              },
              {
                'name': 'Horn',
                'src': 'instr/Horn_-_1_-_95',
                'icon': 'horn'
              },
              {
                'name': 'Trombone',
                'src': 'instr/trombone_-_1_-_95',
                'icon': 'trombone'
              },
              {
                'name': 'Tuba',
                'src': 'instr/tuba_-_1_-_95',
                'icon': 'tuba'
              }]
          ]
        }
      ]
    }
  },
};
