import { Injectable } from '@angular/core';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { environment } from '../../../environments/environment';
import { map, tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Network } from '@ionic-native/network/ngx';
import { TokenService } from "./token.service";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  networkAvailable = true;

  constructor(
    private nativeStorage: NativeStorage,
    private http: HttpClient,
    private network: Network,
    private tokenService: TokenService,
  ) {
    this.networkAvailable = !(this.network.type == 'none');
    let connectSubscription = this.network.onDisconnect().subscribe(() => {
      console.log('network was disconnected :-(');
      this.networkAvailable = false;
    });
  }

  handleResponse(response: { user: any, token: string, version: any }): void {
    if (response.user) {
      this.nativeStorage.setItem('user', response.user)
        .then(
          () => {
          },
          error => {
            throw Error('Storing item went wrong \n' + JSON.stringify(error))
          }
        );
    }
  }

  relock(): any {
    return this.http
      .post(`${environment.api}/version/remove`, {})
      .pipe(map(response => (response as any).version));
  }

  unlock(params: { key: string; }): any {
    return this.http
      .post(`${environment.api}/version/unlock/key`, {}, { params: params })
      .pipe(map(response => (response as any).version));
  }

  unlockIAP(): any {
    return this.http
      .post(`${environment.api}/version/unlock`, {})
      .pipe(map(response => (response as any).user.version));
  }

  getVersion() {
    if (this.networkAvailable) {
      this.http
        .get<{ user: any, token: string, version: any }>(`${environment.api}/me`)
        .pipe(
          tap(response => this.tokenService.handleResponse(response)),
          tap(response => this.handleResponse(response))
        )
        .toPromise();
    }
    return this.nativeStorage
      .getItem('user')
      .then(
        data => data.version
      );
  }
}
