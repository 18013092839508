import { Component, NgZone, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2/ngx';
import Swal from 'sweetalert2';
import { UserService } from '../../services/auth/user.service';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { Subject } from 'rxjs';
import { RouteInterceptorService } from '../../services/route-interceptor.service';
import { environment } from '../../../environments/environment';
import { PurchaseService } from '../../services/purchase.service';
import { Network } from "@ionic-native/network/ngx";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../../services/i18n.service";


@Component({
  selector: 'app-purchase',
  templateUrl: './purchase.component.html',
  styleUrls: ['./purchase.component.scss']
})
export class PurchaseComponent implements OnInit, OnDestroy {
    alreadyOwned: boolean;
  [x: string]: any;

  version: any;
  unlockCode: string;
  native: boolean;
  product: IAPProduct;
  handlers: any[] = [];
  environment = environment;

  destroyed$ = new Subject();
  price;
  ready = false;

  constructor(private location: Location,
              private purchaseService: PurchaseService,
              private actr: ActivatedRoute,
              private _ngZone: NgZone,
              private store: InAppPurchase2,
              private userService: UserService,
              private spinnerDialog: SpinnerDialog,
              private translate: TranslateService,
              private router: Router,
              public i18n: I18nService,
              private network: Network,
              private routeInterceptorService: RouteInterceptorService,
              public platform: Platform
  ) {
    this.actr.data
      .pipe(
        map(res => res.version)
      )
      .subscribe((version) => {
        this.version = version;
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next(true);
  }

  async ngOnInit() {
    // check to see is already purchased on page init
    this.purchaseService.productOwned$
      .pipe(take(1))
      .pipe(filter((owned) => !!owned))
      .subscribe(async () => {
        this.ready = false;
        this.alreadyOwned = true;
        console.log('already owned in component ');
        await Swal.fire({
          title: title,
          text: text,
          icon: 'success',
          confirmButtonText: 'Ok',
        });
        this.router.navigate([this.routeInterceptorService.previousUrl])
          .catch(err => {
            throw Error(err);
          });
      });

    this.native = this.platform.is('ios') || this.platform.is('android');

    if (!this.native) {
      return;
    }
    this.spinnerDialog.show();
    this.purchaseService.init();

    this.price = { value: await this.purchaseService.price$ };

    // wait for purchase service to be ready
    await this.purchaseService.ready$
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((ready) => !!ready))
      .pipe((take(1)))
      .toPromise();

    this.ready = true;

    this.spinnerDialog.hide();

    const [title, text] = await Promise.all([
      this.translate.get('swal-purchase-already-title').toPromise(),
      this.translate.get('swal-purchase-already-text').toPromise()]
    );


    const [failTitle, failText] = await Promise.all([
      this.translate.get('swal-purchase-fail-title').toPromise(),
      this.translate.get('swal-purchase-fail-text').toPromise()]
    );

    // watch for errors from store
    this.purchaseService
      .error$
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((error) => !!error))
      .subscribe((error) => {
        this._ngZone.run(() => { // we need to run it in a zone because it updates the view in an anonymous callback
          Swal.fire({
            title: failTitle,
            text: failText,
            icon: 'error',
            confirmButtonText: 'Ok',
          });
          this.spinnerDialog.hide();
          throw Error(`STORE ERROR : something went wrong with 'purchaseService' \n` + JSON.stringify(error));
        });
      });
  }

  async purchase() {
    this.spinnerDialog.show();

    await this.purchaseService.purchase();

    // wait until product is owned
    this.purchaseService.productOwned$
      .pipe(takeUntil(this.destroyed$))
      .pipe(filter((owned) => !!owned))
      .pipe((take(1)))
      .subscribe(() => this.unlockIAP());
  }

  unlock() {
    if (this.version && !!!this.version.pro) {
      this.userService
        .unlock({ key: this.unlockCode })
        .toPromise()
        .then(version => {
          this._ngZone.run(async () => { // we need to run it in a zone because it updates the view in an anonymous callback
            this.version = version;
            const [title, text] = await Promise.all([
              this.translate.get('swal-purchase-succeed-title').toPromise(),
              this.translate.get('swal-purchase-succeed-text').toPromise()]
            );

            await Swal.fire({
              title: title,
              text: text,
              icon: 'success',
              confirmButtonText: 'Ok',
            });
          });
        })
        .catch(
          error => {
            throw Error(`something went wrong with 'Unlock' \n` + JSON.stringify(error));
          });
    }
  }

  unlockIAP() {
    this.userService
      .unlockIAP()
      .toPromise()
      .then(version => {
        console.log(version);
        this._ngZone.run(async () => { // we need to run it in a zone because it updates the view in an anonymous callback
          this.version = version;
          this.spinnerDialog.hide();

          const [title, text] = await Promise.all([
            this.translate.get('swal-purchase-succeed-title').toPromise(),
            this.translate.get('swal-purchase-succeed-text').toPromise()]
          );

          await Swal.fire({
            title: title,
            text: text,
            icon: 'success',
            confirmButtonText: 'Ok'
          });
          location.reload();
        })
      })
      .catch(
        response => {
          this._ngZone.run(async () => { // we need to run it in a zone because it updates the view in an anonymous callback
            this.spinnerDialog.hide();
            let title = await this.translate.get('swal-warning').toPromise();
            await Swal.fire({
              title: title,
              text: response.error ? JSON.stringify(response.error.error) : response,
              icon: 'error',
              confirmButtonText: 'Ok'
            });
            throw Error(`something went wrong with 'unlockIAP' \n` + JSON.stringify(response));
          });
        });
  }
}
