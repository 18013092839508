<div class="page">
  <div class="wrapper" id="wrapper">
    <!-- begin topbar -->
    <div class="top-bar">
      <app-world-navigation [timelinePlaying]="(timelinePlaying$ | async) === true"
                            [activeCollectionName]="activeCollectionName$|async"
                            [activeCollectionIcon]="activeCollectionIcon$|async"
                            (prevCollection)="prevCollection()"
                            (nextCollection)="nextCollection()">
      </app-world-navigation>

      <app-audio-navigation [draggable]="draggable" [version]="version" >
      </app-audio-navigation>
    </div>
    <!-- end topbar -->
    <div [class]="'sequencer ' + ((timelinePlaying$ | async) === true ? 'playing' : '')">
      <div class="hr-vertical">
        <img src="./assets/images/hr-vertical.png">
      </div>
      <div class="rows-wrapper">
        <div class="row sidebar">
          <div class="bord-wrapper bord-wrapper--no-hook">
            <div class="bord bord--bar">
              <div class="volume-control">
                <input class="volume" type="range" (change)="setVolume('sources', $event.target.value)"
                       [value]="volumes.sources * 100" max="100">
              </div>

              <img class="bord__icon" src="./assets/images/bord/1x/trompet.png" (pointerup)="nextCollection('thuis')">
            </div>
          </div>

          <ng-container *ngIf="(timelineBlocks$ | async)[0] && advanced">
            <!--begin instrument channels-->
            <div *ngFor="let instrument of (timelineBlocks$ | async)[0].sources[0];trackBy: trackByIndex"
                 class="bord-wrapper bord-wrapper--no-hook">
              <div class="bord bord--bar__light ">
                <img class="bord__icon" [src]="'assets/images/instrumenten/' + instrument.icon+ '.png'"
                     alt="{{instrument.name}}">
              </div>
            </div>
            <!--end instrument channels-->
          </ng-container>
          <div class="bord-wrapper bord-wrapper--no-hook">
            <div class="bord bord--bar">
              <div class="volume-control">
                <input class="volume" type="range" (change)="setVolume('percussion', $event.target.value)"
                       [value]="volumes.percussion * 100" max="100">
              </div>

              <img class="bord__icon" src="./assets/images/instrumenten/percussie.png"
                   (pointerup)="nextCollection('percussie')">
            </div>
          </div>
        </div>
        <div class="row main" [perfectScrollbar]="timelineScrollConfig" #perfectscroll="ngxPerfectScrollbar">
          <div class="scroller"
               [dragula]="draggable ? 'dragContainer-audio': null"
               id="dragAudioTo"
               [dragulaModel]="(timelineBlocks$ | async)"
               (dragulaModelChange)="this.updateTimelineBlocks($event)">
            <app-muziek-timeline-drop
              *ngFor="let audioSequence of (timelineBlocks$ | async);let i = index;trackBy: trackById;"
              [class]="((timelinePlayingIndex$ | async) == i ? 'selected': '' ) + ' advanced-'+advanced +' line music-block js-music-block'"
              (pointerup)="setBlockPlaying(audioSequence.id)"
              [audioSequence]="audioSequence"
              [deleteMode]="deleteMode"
              [advanced]="advanced"
              [selected]="(timelinePlayingIndex$ | async) == i"
              (removeAudioSequence)="removeAudioSequence(audioSequence)"
              (setPercussion)="setPercussion(audioSequence, $event)"
              (removePercussionOnly)="removePercussion(audioSequence)"
              (toggleMute)="toggleMute(audioSequence, $event)"
            >
            </app-muziek-timeline-drop>

            <div class="line music-block dropzone donotdrag" id="dropzone">
              <div class="line">
                <div class="line--content">
                  <div class="bord-wrapper">
                    <div class="bord bord--hook--only"></div>
                  </div>
                </div>
              </div>
            </div> <!-- end drop zone -->
          </div> <!-- end scroller -->
        </div> <!-- end  main -->
      </div>
    </div>

    <div class="bottom-bar">
      <div class="bg-img">
        <img src="./assets/images/balk-bottom.png">
      </div>
      <div class="controls">
        <button class="deleteMode" [class.forbidden]="currentPlaying" (pointerup)="deleteMode = currentPlaying ? false : !deleteMode">
          <img [src]="'./assets/icons/' + (deleteMode ? 'vuilbak-open' : 'vuilbak-closed') + '.png'">
        </button>
        <button class="advanced" [class.active]="advanced" (pointerup)="setAdvancedMode()">
          <img src="./assets/images/btn/advanced-{{i18n.currentLanguage}}.png">
        </button>
        <div class="music">
          <button class="back" (pointerup)="playPrevBlock()">
            <img src="./assets/images/btn/terug.png">
          </button>

          <button (pointerup)="setPlaying(true)" [hidden]="(timelinePlaying$ | async) === true">
            <img src="./assets/images/btn/play.png">
          </button>

          <button (pointerup)="setPlaying(false)" [hidden]="(timelinePlaying$ | async) !== true">
            <img src="./assets/images/btn/pauze.png">
          </button>

          <button (pointerup)="goToFirstBlock($event)">
            <img src="./assets/images/btn/stop.png">
          </button>
          <button class="advanced" (pointerup)="playNextBlock()">
            <img src="./assets/images/btn/verder.png">
          </button>
        </div>
        <button class="advanced" (click)="publishOrPurchase()">
          <img src="./assets/images/btn/save-{{i18n.currentLanguage}}.png">
        </button>
        <button routerLink="/home" class="home">
          <img src="./assets/images/btn/home-{{i18n.currentLanguage}}.png">
        </button>
      </div>
    </div>
  </div>
</div>

<div class="loading" [class.show]="loading">
  <div class="content">
    <h3>{{ 'Even geduld, alles wordt ingeladen' |translate}}</h3>
  </div>
</div>

<swal title="{{'swal-share-ready-story'|translate}}" #shareSwal>
  <!-- This form will be displayed as the alert main content
       Targets the alert's main content zone by default -->
  <div *swalPortal>
    <br>
    <br>
    <a class="share-btn email" style="width: auto !important"
       (click)="openNativeShare()">
      <i class="fa fa-share-alt"></i> &nbsp;{{'swal-share-ready-inapp-button' | translate}}</a>

    <br>
    <br>

    <a class="share-btn facebook" style="width: auto !important"
       (click)="openNativeBrowser()">
      <i class="fa fa-share-square"></i> &nbsp;<span [innerHTML]="'swal-share-ready-listen' | translate:share"></span>
    </a>
  </div>

  <!-- This targets the confirm button's inner content
       Notice the usage of ng-container to avoid creating an useless DOM element inside the button -->
  <ng-container *swalPortal="swalTargets.confirmButton">{{"alert-close" | translate}}</ng-container>
</swal>
