import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { Media } from '@ionic-native/media/ngx';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { File } from '@ionic-native/file/ngx';
import { InAppPurchase2 } from '@ionic-native/in-app-purchase-2/ngx';
import { SpinnerDialog } from '@ionic-native/spinner-dialog/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SocialSharing } from "@ionic-native/social-sharing/ngx";


import { TransferHttpModule, TransferHttpService } from '@gorniv/ngx-transfer-http';
import { DragulaModule } from 'ng2-dragula';
import { NgxPageVisibilityModule } from 'ngx-page-visibility';
import { WindowRefService } from './services/window-ref.service';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

import { HandleidingComponent } from './containers/handleiding/handleiding.component';
import { HomeComponent } from './containers/home/home.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { VerhaalComponent } from './containers/verhaal/verhaal.component';
import { MuziekComponent } from './containers/muziek/muziek.component';
import { NavigationButtonComponent } from './components/navigation-button/navigation-button.component';
import { WorldNavigationComponent } from './components/world-navigation/world-navigation.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';


import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { RotateComponent } from './rotate/rotate.component';
import { SkyhookDndModule } from '@angular-skyhook/core';
import { createDefaultMultiBackend, SkyhookMultiBackendModule } from '@angular-skyhook/multi-backend';
import { PercussionSourceComponent } from './components/percussion-source/percussion-source.component';
import { TimelineDropComponent } from './components/timeline-drop/timeline-drop.component';
import { MuziekTimelineDropComponent } from './components/timeline-drop/muziek-timeline-drop.component';
import {
  PerfectScrollbarModule,
  PERFECT_SCROLLBAR_CONFIG,
  PerfectScrollbarConfigInterface
} from 'ngx-perfect-scrollbar';
import { BrowserComponent } from './browser/browser.component';
import { DownloadComponent } from './containers/download/download.component';
import { PictureUploadComponent } from './components/picture-upload/picture-upload';
import { AudioNavigationComponent } from './components/audio-navigation/audio-navigation.component';
import { MuziekPlayerComponent } from './containers/player/muziek-player.component';
import { VerhaalPlayerComponent } from './containers/player/verhaal-player.component';
import { JwtModule } from '@auth0/angular-jwt';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AudioRecordtestComponent } from './containers/audio-recordtest/audio-recordtest.component';
import { PurchaseComponent } from './containers/purchase/purchase.component';
import { Device } from '@ionic-native/device/ngx';
import { HttpDeviceInterceptor } from './services/auth/device.interceptor';
import { ActionSheet } from '@ionic-native/action-sheet/ngx';
import { RollbarErrorHandler, RollbarService, rollbarFactory } from './services/error.service';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  handlers: ['click-rail', 'drag-thumb', 'keyboard', 'wheel', 'touch'],
  useBothWheelAxes: true,
  suppressScrollY: true
};

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export class DynamicLocaleId extends String {
  locale: string;

  toString() {
    return this.locale;
  }
}

export function tokenGetter() {
  return localStorage.getItem('access_token');
}


// import production only provider modules
let prodProviders = [
  {
    provide: ErrorHandler,
    useClass: RollbarErrorHandler,
  },
  {
    provide: RollbarService,
    useFactory: rollbarFactory,
  },
];

// if production clear dev imports and set to prod mode
if (!environment.production) {
  prodProviders = [];
}


@NgModule({
  declarations: [
    AppComponent,
    HandleidingComponent,
    HomeComponent,
    NavbarComponent,
    PictureUploadComponent,
    VerhaalComponent,
    MuziekComponent,
    NavigationButtonComponent,
    WorldNavigationComponent,
    AudioNavigationComponent,
    LoginComponent,
    RegisterComponent,
    RotateComponent,
    PercussionSourceComponent,
    TimelineDropComponent,
    MuziekTimelineDropComponent,
    BrowserComponent,
    DownloadComponent,
    MuziekPlayerComponent,
    VerhaalPlayerComponent,
    ResetPasswordComponent,
    AudioRecordtestComponent,
    PurchaseComponent,
    LanguageSwitcherComponent,
  ],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      defaultLanguage: 'nl',
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    SweetAlert2Module.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: environment.whitelistedDomains,
        blacklistedRoutes: environment.blacklistedDomains
      }
    }),
    TransferHttpModule,
    DragulaModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }), // disable serviceworker on iOS
    AppRoutingModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    FormsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireDatabaseModule, // for database
    AngularFireStorageModule,
    PerfectScrollbarModule,
    SkyhookDndModule.forRoot({ backendFactory: createDefaultMultiBackend }),
    SkyhookMultiBackendModule,
    TransferHttpCacheModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxPageVisibilityModule,
  ],
  providers: [
    WindowRefService,
    TransferHttpService,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpDeviceInterceptor,
      multi: true
    },
    ...prodProviders,
    Media,
    File,
    Network,
    NativeStorage,
    InAppPurchase2,
    ActionSheet,
    SocialSharing,
    Device,
    SpinnerDialog
  ],
  bootstrap:
    [AppComponent]
})
export class AppModule {
}
