import { Component, OnInit } from '@angular/core';
import { I18nService } from "../../services/i18n.service";

@Component({
  selector: 'app-handleiding',
  templateUrl: './handleiding.component.html',
  styleUrls: ['./handleiding.component.scss']
})
export class HandleidingComponent implements OnInit {

  constructor(              public i18n: I18nService,
  ) {
  }

  ngOnInit() {
  }

}
