<div class="page page-manual">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo" routerLink="/home">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>
      <div class="handleiding">
        <div class="row">
          <div class="col image">
            <img src="./assets/images/handleiding/add.gif" alt="">
          </div>
          <div class="col copy">
            <h2>{{ 'howto-musical-unit-title' |translate}}</h2>
            <p>{{ 'howto-musical-unit-text' |translate}}</p>
          </div>

          <div class="row">
            <div class="col copy">
              <h2>{{ 'howto-percussion-title' |translate}}</h2>
              <p>{{ 'howto-percussion-text' |translate}}</p>
            </div>
            <div class="col image">
              <img src="./assets/images/handleiding/percussie.gif" alt="">
            </div>
          </div>

          <div class="row">
            <div class="col image">
              <img src="./assets/images/handleiding/change.gif" alt="">
            </div>
            <div class="col copy">
              <h2>{{ 'howto-navigate-title' |translate}}</h2>
              <p>{{ 'howto-navigate-text' |translate}}</p>
            </div>
          </div>

          <div class="row">
            <div class="col copy">
              <h2>{{ 'howto-play-title' |translate}}</h2>
              <p>{{ 'howto-play-text' |translate}}</p>
            </div>
            <div class="col image">
              <img src="./assets/images/handleiding/play.gif" alt="">
            </div>
          </div>

          <div class="row">
            <div class="col image">
              <img src="./assets/images/handleiding/delete.gif" alt="">
            </div>
            <div class="col copy">
              <h2>{{ 'howto-delete-title' |translate}}</h2>
              <p>{{ 'howto-delete-text' |translate}}</p>
            </div>
          </div>

          <div class="row">
            <div class="col copy">
              <h2>{{ 'howto-record-title' |translate}}</h2>
              <p>{{ 'howto-record-text' |translate}}</p>
            </div>
            <div class="col image">
              <img src="./assets/images/handleiding/record.gif" alt="">
            </div>
          </div>

          <div class="row">
            <div class="col image">
              <img src="./assets/images/handleiding/advanced.gif" alt="">
            </div>
            <div class="col copy">
              <h2>{{ 'howto-advanced-title' |translate}}</h2>
              <p>{{ 'howto-advanced-text' |translate}}</p>
            </div>
          </div>

        </div>
      </div>
      <div class="buttons">
        <app-navigation-button title="{{'Home'|translate}}" routerLink="/home"></app-navigation-button>
      </div>
    </div>
  </div>
</div>
