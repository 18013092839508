<div class="world">
  <div class="world_drawing" [class.show]="timelinePlaying && currentDrawing">
    <img [src]="currentDrawing || ''" alt="">
  </div>
  <div class="world_image" [class.hide]="timelinePlaying && currentDrawing">
    <img class="world_image--overlay" alt="{{ activeCollectionName }}"
         src="./assets/images/landen/{{ activeCollectionIcon }}.png">
    <img class="world_image--bg" src="./assets/images/landen/globe.png">
  </div>

  <div class="world_image--nav-controls" [class.hide]="timelinePlaying && currentDrawing">
    <img class="world_image--nav-controls__left" src="./assets/images/arrow-left.png"
         (pointerup)="prevCollection.emit()">
    <img class="world_image--nav-controls__right" src="./assets/images/arrow-right.png"
         (pointerup)="nextCollection.emit()">
  </div>
</div>
