import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer
} from '@ngrx/store';

import * as fromTimeline from './timeline.reducer';
import * as fromMuziekTimeline from './muziekTimeline.reducer';
import * as fromPlayerTimeline from './playerTimeline.reducer';
import * as fromCollections from './collections.reducer';

import {localStorageSync} from 'ngrx-store-localstorage';

export interface State {
  timeline: fromTimeline.State;
  muziekTimeline: fromMuziekTimeline.State;
  playerTimeline: fromPlayerTimeline.State,
  collections: fromCollections.State;
}

export const reducers: ActionReducerMap<State> = {
  timeline: fromTimeline.reducer,
  muziekTimeline: fromMuziekTimeline.reducer,
  playerTimeline: fromPlayerTimeline.reducer,
  collections: fromCollections.reducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [{'timeline': ['blocks', 'volumes', 'audioId']}, {'collections': ['activeName']}, {'muziekTimeline': ['blocks', 'volumes', 'audioId']}],
    rehydrate: true
  })(reducer);
}

export const metaReducers: MetaReducer<State>[] = [localStorageSyncReducer];

export const getTimelineState = createFeatureSelector<fromTimeline.State>('timeline');
export const getMuziekTimelineState = createFeatureSelector<fromMuziekTimeline.State>('muziekTimeline');
export const getPlayerTimelineState = createFeatureSelector<fromPlayerTimeline.State>('playerTimeline');

export const getTimelineBlocks = createSelector(getTimelineState, fromTimeline.getblocks);
export const getMuziekTimelineBlocks = createSelector(getMuziekTimelineState, fromMuziekTimeline.getblocks);
export const getPlayerTimelineBlocks = createSelector(getPlayerTimelineState, fromPlayerTimeline.getblocks);

export const getTimelinePlayingBlockId = createSelector(getTimelineState, fromTimeline.getBlockPlayingId);
export const getTotalRecordingDuration = createSelector(getTimelineState, fromTimeline.getTotalRecordingDuration);
export const getMuziekTimelinePlayingBlockId = createSelector(getMuziekTimelineState, fromMuziekTimeline.getBlockPlayingId);
export const getPlayerTimelinePlayingBlockId = createSelector(getPlayerTimelineState, fromPlayerTimeline.getBlockPlayingId);

export const getTimeLinePlaying = createSelector(getTimelineState, fromTimeline.getPlaying);
export const getMuziekTimeLinePlaying = createSelector(getMuziekTimelineState, fromMuziekTimeline.getPlaying);
export const getPlayerTimelinePlaying = createSelector(getPlayerTimelineState, fromPlayerTimeline.getPlaying);

export const getAudioId = createSelector(getTimelineState, fromTimeline.getAudioId);
export const getMuziekAudioId = createSelector(getMuziekTimelineState, fromMuziekTimeline.getAudioId);
export const getPlayerAudioId = createSelector(getPlayerTimelineState, fromPlayerTimeline.getAudioId);

export const getVolumes = createSelector(getTimelineState, fromTimeline.getVolumes);
export const getMuziekVolumes = createSelector(getMuziekTimelineState, fromMuziekTimeline.getVolumes);
export const getPlayerVolumes = createSelector(getPlayerTimelineState, fromPlayerTimeline.getVolumes);

export const getTimeLinePlayingBlock = createSelector(getTimelineBlocks, getTimelinePlayingBlockId, (blocks, id) => {
  return blocks.find(block => block.id === id);
});
export const getMuziekTimeLinePlayingBlock = createSelector(getMuziekTimelineBlocks, getMuziekTimelinePlayingBlockId, (blocks, id) => {
  return blocks.find(block => block.id === id);
});
export const getPlayerTimeLinePlayingBlock = createSelector(getPlayerTimelineBlocks, getPlayerTimelinePlayingBlockId, (blocks, id) => {
  return blocks.find(block => block.id === id);
});

export const getTimelineBlockWithPlaying = createSelector(getTimelineBlocks, getTimelinePlayingBlockId, (blocks, id) => {
  return blocks.map((block) => {
    if (block.id === id) {
      return {
        ...block,
        playing: true,
      };
    }

    return block;
  });
});
export const getMuziekTimelineBlockWithPlaying = createSelector(getMuziekTimelineBlocks, getMuziekTimelinePlayingBlockId, (blocks, id) => {
  return blocks.map((block) => {
    if (block.id === id) {
      return {
        ...block,
        playing: true,
      };
    }

    return block;
  });
});

export const getPlayerTimelineBlockWithPlaying = createSelector(getPlayerTimelineBlocks, getPlayerTimelinePlayingBlockId, (blocks, id) => {
  return blocks.map((block) => {
    if (block.id === id) {
      return {
        ...block,
        playing: true,
      };
    }

    return block;
  });
});


export const getTimelinePlayingIndex = createSelector(getTimelineBlocks, getTimelinePlayingBlockId, (blocks, id) => {
  const result = blocks.findIndex((block) => block.id === id);
  if (result < 0) {
    return 0;
  }
  return result;
});
export const getMuziekTimelinePlayingIndex = createSelector(getMuziekTimelineBlocks, getMuziekTimelinePlayingBlockId, (blocks, id) => {
  const result = blocks.findIndex((block) => block.id === id);
  if (result < 0) {
    return 0;
  }
  return result;
});
export const getPlayerTimelinePlayingIndex = createSelector(getPlayerTimelineBlocks, getPlayerTimelinePlayingBlockId, (blocks, id) => {
  const result = blocks.findIndex((block) => block.id === id);
  if (result < 0) {
    return 0;
  }
  return result;
});

export const getCollectionsState = createFeatureSelector<fromCollections.State>('collections');
export const getCollections = createSelector(getCollectionsState, fromCollections.getCollections);
export const getCollectionsActiveName = createSelector(getCollectionsState, fromCollections.getActiveName);

export const getActiveCollection = createSelector(getCollections, getCollectionsActiveName, (collections, name) => {
  return collections.find((collection) => {
    return collection.name === name;
  }).samples;
});

export const getCollectionsActiveIcon = createSelector(getCollections, getCollectionsActiveName, (collections, name) => {
  return collections.find((collection) => {
    return collection.name === name;
  }).icon;
});

export const getCollectionsActiveType = createSelector(getCollections, getCollectionsActiveName, (collections, name) => {
  return collections.find((collection) => {
    return collection.name === name;
  }).type;
});

export const getTimeLineDrawingBlock = createSelector(getTimelineBlocks, getTimelinePlayingBlockId, (blocks, id) => {
  return blocks.find(block => block.id === id);
});


export const getTimeLineBlock = createSelector(
  getTimelineBlocks,
  blocks => (id: number) => blocks[id]
);
