import { Action } from '@ngrx/store';

export enum TimelineActionTypes {
  SetBlocks = '[Timeline] SetBlocks',
  IncrementTotalRecordingDuration = '[Timeline] IncrementTotalRecordingDuration',
  ResetTotalRecordingDuration = '[Timeline] ResetTotalRecordingDuration',
  SetVolume = '[Timeline] SetVolume',
  ResetTimeline = '[Timeline] Reset',
  ToggleMute = '[Timeline] ToggleMute',
  SetPercussion = '[Timeline] SetPercussion',
  SetVoiceRecording = '[Timeline] SetVoiceRecording',
  SetDrawing = '[Timeline] SetDrawing',
  RemovePercussion = '[Timeline] RemovePercussion',
  RemoveAudioSequence = '[Timeline] RemoveAudioSequence',
  RemoveVoiceRecording = '[Timeline] RemoveVoiceRecording',
  RemoveDrawing = '[Timeline] RemoveDrawing',
  SetPlaying = '[Timeline] SetPlaying',
  SetPlayingBlock = '[Timeline] SetPlayingBlock',
  GoToFirstBlock = '[Timeline] goToFirstBlock',
  PlayNextBlock = '[Timeline] PlayNextBlock',
  PlayPrevBlock = '[Timeline] PlayPrevBlock',
  IncrementAudioId = '[Timeline] incrementAudioId',
}

export class IncrementAudioId implements Action {
  readonly type = TimelineActionTypes.IncrementAudioId;

  constructor() {
  }
}

export class SetVolume implements Action {
  readonly type = TimelineActionTypes.SetVolume;

  constructor(public payload: { type: string, volume: number }) {
  }
}

export class ResetTimeline implements Action {
  readonly type = TimelineActionTypes.ResetTimeline;

  constructor() {
  }
}

export class SetBlocks implements Action {
  readonly type = TimelineActionTypes.SetBlocks;

  constructor(public payload: any[]) {
  }
}

export class SetPercussion implements Action {
  readonly type = TimelineActionTypes.SetPercussion;

  constructor(public payload: any) {
  }
}

export class SetVoiceRecording implements Action {
  readonly type = TimelineActionTypes.SetVoiceRecording;

  constructor(public payload: any) {
  }
}

export class SetDrawing implements Action {
  readonly type = TimelineActionTypes.SetDrawing;

  constructor(public payload: any) {
  }
}

export class RemovePercussion implements Action {
  readonly type = TimelineActionTypes.RemovePercussion;

  constructor(public payload: any) {
  }
}

export class RemoveAudioSequence implements Action {
  readonly type = TimelineActionTypes.RemoveAudioSequence;

  constructor(public payload: any) {
  }
}

export class RemoveVoiceRecording implements Action {
  readonly type = TimelineActionTypes.RemoveVoiceRecording;

  constructor(public payload: any) {
  }
}

export class RemoveDrawing implements Action {
  readonly type = TimelineActionTypes.RemoveDrawing;

  constructor(public payload: any) {
  }
}

export class SetPlaying implements Action {
  readonly type = TimelineActionTypes.SetPlaying;

  constructor(public payload: boolean) {
  }
}

export class SetPlayingBlock implements Action {
  readonly type = TimelineActionTypes.SetPlayingBlock;

  constructor(public payload: number | null) {
  }
}

export class GoToFirstBlock implements Action {
  readonly type = TimelineActionTypes.GoToFirstBlock;
}

export class PlayNextBlock implements Action {
  readonly type = TimelineActionTypes.PlayNextBlock;

  constructor() {
  }
}

export class PlayPrevBlock implements Action {
  readonly type = TimelineActionTypes.PlayPrevBlock;

  constructor() {
  }
}

export class ToggleMute implements Action {
  readonly type = TimelineActionTypes.ToggleMute;

  constructor(public payload: { id: number, instrumentIndex: number, sourceIndex: number }) {
  }
}

export class IncrementTotalRecordingDuration implements Action {
  readonly type = TimelineActionTypes.IncrementTotalRecordingDuration;

  constructor(public payload: number | null) {
  }
}

export class ResetTotalRecordingDuration implements Action {
  readonly type = TimelineActionTypes.ResetTotalRecordingDuration;

  constructor() {
  }
}

export type TimelineActions =
  SetBlocks |
  ResetTotalRecordingDuration |
  IncrementTotalRecordingDuration |
  ToggleMute |
  PlayNextBlock |
  IncrementAudioId |
  PlayPrevBlock |
  GoToFirstBlock |
  SetPlayingBlock |
  SetPercussion |
  SetVoiceRecording |
  SetDrawing |
  RemovePercussion |
  RemoveAudioSequence |
  RemoveVoiceRecording |
  RemoveDrawing |
  ResetTimeline |
  SetVolume |
  SetPlaying;
