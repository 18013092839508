<div [@fadeAnimation]="getRouterOutletState(o)">
  <router-outlet #o="outlet"></router-outlet>
</div>
<app-rotate></app-rotate>
<app-browser></app-browser>

<skyhook-preview [allBackends]="true">
  <ng-template let-type let-item="item">
    <li class="music-block gu-mirror">
      <div class="bord-wrapper">
        <div class="bord bord--hook">
          <img class="bord__icon" src="./assets/images/bord/2x/{{item.icon}}.png">
        </div>
      </div>
    </li>
  </ng-template>
</skyhook-preview>
