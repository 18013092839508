import { Injectable } from '@angular/core';

import { data } from '../audio/audio';
import { AudioSequenceCollection } from '../interfaces/AudioSequenceCollection';

@Injectable({
  providedIn: 'root'
})
export class AudioSequencesService {
  constructor() {
  }

  extractSounds(name): string[] {
    const sounds = [];
    const collection = data.audio[name];

    if (collection.type === 'audio') {
      collection.files.forEach((file) => {
        file.sources.forEach((sources) => {
          sources.forEach((sound) => {
            sounds.push(sound.src);
          });
        });
      });
    } else if (collection.type === 'percussion') {
      collection.samples.forEach((file) => {
        sounds.push(file.src);
      });
    }

    return sounds;
  }

  extractPercussion(name): string[] {
    const sounds = [];
    const collection = data.audio[name];
    if (collection.type === 'percussion') {
      console.log('extractpercussion!!!!');
      collection.samples.forEach((file) => {
        sounds.push(file.src);
      });
    }
    return sounds;
  }

  extractSoundsFromBlocks(blocks): string[] {
    const sounds = [];

    if (blocks) {
      blocks.forEach((block) => {
        block.sources.forEach((samples) => {
          samples.forEach((sample) => {
            sounds.push(sample.src);
          });
        });
        if (block.percussion) {
          console.log('extractpercussion!!!!');
          console.log(block.percussion.src);
          sounds.push(block.percussion.src);
        }
      });
    }
    return sounds;
  }

  getSequenceCollections(): AudioSequenceCollection[] {
    return Object.keys(data.audio).map((key) => {
      const collection = data.audio[key];

      if (collection.type === 'audio') {
        return {
          name: key,
          icon: collection.icon,
          type: collection.type,
          durations: collection.durations || ['no duration on collection'],
          samples: collection.files.map((file) => {
            return {
              durations: file.durations || ['no duration on file'],
              name: file.name,
              icon: file.icon,
              sources: file.sources,
            };
          })
        };
      } else if (collection.type === 'percussion') {
        return {
          name: key,
          ...collection,
          samples: [
            ...collection.samples
          ]
        };
      }
    });
  }
}
