import lamejs from 'lamejs';

const CHANNELS = 1; //1 for mono or 2 for stereo
const SAMPLERATE = 44100; //44.1khz (normal mp3 samplerate)
const KBPS = 128; //encode 128kbps mp3
const MP3_ENCODER = lamejs ? new lamejs.Mp3Encoder(CHANNELS, SAMPLERATE, KBPS) : {
  encodeBuffer: function () {
    return true
  },
  flush: function () {
    return true
  }
};

const bufferLoaded = (event) => {
  return new Promise(res => {
    const blobbuffer = event.target['result'];
    const int16Array = new Int16Array(blobbuffer, 0, Math.floor(blobbuffer.byteLength / 2));

    const mp3Data = [];
    const sampleBlockSize = 576 * 4; //can be anything but make it a multiple of 576 to make encoders life easier

    for (let i = 1; i < int16Array.length; i += sampleBlockSize) {
      const sampleChunk = int16Array.subarray(i, i + sampleBlockSize);
      const mp3buf = MP3_ENCODER.encodeBuffer(sampleChunk);
      if (mp3buf.length > 0) {
        mp3Data.push(mp3buf);
      }
    }
    const mp3buf = MP3_ENCODER.flush();   //finish writing mp3

    if (mp3buf.length > 0) {
      mp3Data.push(new Int8Array(mp3buf));
    }

    const rawMp3blob = new Blob(mp3Data, { type: 'audio/mp3' });
    res(rawMp3blob);
  });
};

const convertToMP3 = (blob) => {
  return new Promise(res => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      bufferLoaded(event)
        .then((convertedBlob) => {
          res(convertedBlob);
        });
    };

    fileReader.readAsArrayBuffer(blob);
  });
};

const blobToDataURL = (blob): any => {
  return new Promise(res => {
    const f = new FileReader();
    f.onload = function (e) {
      res(e.target['result']);
    };
    f.readAsDataURL(blob);
  });
};
const dataURLtoBlob = (dataurl) => {
  let arr = dataurl.split(',');
  let mime = arr[0].match(/:(.*?);/)[1];
  let bstr = atob(arr[1]), n = bstr.length;
  let u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export { convertToMP3, blobToDataURL, dataURLtoBlob, dataURIToBlob };

function base64ArrayBuffer(arrayBuffer) {
  var base64 = "",
    encodings = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/",
    bytes = new Uint8Array(arrayBuffer),
    byteLength = bytes.byteLength,
    byteRemainder = byteLength % 3,
    mainLength = byteLength - byteRemainder,
    a,
    b,
    c,
    d,
    chunk;
  // Main loop deals with bytes in chunks of 3
  for (var i = 0; i < mainLength; i = i + 3) {
    // Combine the three bytes into a single integer
    chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2];
    // Use bitmasks to extract 6-bit segments from the triplet
    a = (chunk & 16515072) >> 18; // 16515072 = (2^6 - 1) << 18
    b = (chunk & 258048) >> 12; // 258048   = (2^6 - 1) << 12
    c = (chunk & 4032) >> 6; // 4032     = (2^6 - 1) << 6
    d = chunk & 63; // 63       = 2^6 - 1
    // Convert the raw binary segments to the appropriate ASCII encoding
    base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d];
  }
  // Deal with the remaining bytes and padding
  if (byteRemainder == 1) {
    chunk = bytes[mainLength];
    a = (chunk & 252) >> 2; // 252 = (2^6 - 1) << 2
    // Set the 4 least significant bits to zero
    b = (chunk & 3) << 4; // 3   = 2^2 - 1
    base64 += encodings[a] + encodings[b] + "==";
  } else if (byteRemainder == 2) {
    chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1];
    a = (chunk & 64512) >> 10; // 64512 = (2^6 - 1) << 10
    b = (chunk & 1008) >> 4; // 1008  = (2^6 - 1) << 4
    // Set the 2 least significant bits to zero
    c = (chunk & 15) << 2; // 15    = 2^4 - 1
    base64 += encodings[a] + encodings[b] + encodings[c] + "=";
  }
  return base64;
}

function base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function dataURIToBlob(dataURI) {
  dataURI = dataURI.replace(/^data:/, '');

  const type = dataURI.match(/image\/[^;]+/);
  const base64 = dataURI.replace(/^[^,]+,/, '');
  const arrayBuffer = new ArrayBuffer(base64.length);
  const typedArray = new Uint8Array(arrayBuffer);

  for (let i = 0; i < base64.length; i++) {
    typedArray[i] = base64.charCodeAt(i);
  }

  return new Blob([arrayBuffer], { type });
}
