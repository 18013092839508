import { Component, OnInit, OnDestroy, ViewEncapsulation, Input, ViewChild } from '@angular/core';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../reducers';
import { SkyhookDndService } from '@angular-skyhook/core';

import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';

import {
  PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent
} from 'ngx-perfect-scrollbar';
import ScrollBarProperties from '../../models/ScrollBarProperties';
import { AudioSequence } from '../../interfaces/AudioSequence';
import { AudioSequencesService } from '../../services/audio-sequences.service';
import { AudioPlayerService } from '../../services/audio-player.service';

@Component({
  selector: 'app-audio-navigation',
  templateUrl: './audio-navigation.component.html',
  styleUrls: ['./audio-navigation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AudioNavigationComponent implements OnInit, OnDestroy {
  @ViewChild(PerfectScrollbarComponent, { static: true }) topBarScrollComponentRef?: PerfectScrollbarComponent;

  @Input() draggable: boolean;
  @Input() version: any;

  private destroyed$ = new Subject();
  public activeCollection$: Observable<AudioSequence[]>;
  public activeCollectionName$: Observable<string>;
  public activeCollectionIcon$: Observable<string>;
  public activeCollectionType$: Observable<string>;
  public activeName: string;
  public topScrollProperties: ScrollBarProperties;
  public loading = true;
  public numberOfFreeSounds = 4;
  public topBarScrollConfig: PerfectScrollbarConfigInterface = {
    handlers: [],
    useBothWheelAxes: true,
    suppressScrollX: false,
    suppressScrollY: false,
  };

  constructor(private dnd: SkyhookDndService,
              private audioSequenceService: AudioSequencesService,
              private audioPlayerService: AudioPlayerService,
              private store: Store<fromStore.State>) {
  }

  ngOnInit() {
    this.activeCollection$ = this.store.pipe(select(fromStore.getActiveCollection));
    this.activeCollectionName$ = this.store.pipe(select(fromStore.getCollectionsActiveName));
    this.activeCollectionIcon$ = this.store.pipe(select(fromStore.getCollectionsActiveIcon));
    this.activeCollectionType$ = this.store.pipe(select(fromStore.getCollectionsActiveType));

    this.activeCollectionName$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (name) => {
        this.loading = true;
        this.activeName = name;
        const sounds = this.audioSequenceService.extractPercussion(name);
        await this.audioPlayerService.preload(sounds); // only preload percussion because it is important!
        this.loading = false;
      });

    this.activeCollection$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (blocks) => {
        this.topScrollProperties = new ScrollBarProperties();
        if (blocks.length > 8) {
          this.topScrollProperties.enable();
        }
      });
  }

  public scrollTopBarToY(y: number): void {
    const newY = (this.topBarScrollComponentRef.directiveRef as any).ps().lastScrollTop + y;
    const animationTime = 500;
    if (this.topBarScrollComponentRef && this.topBarScrollComponentRef.directiveRef) {
      this.topBarScrollComponentRef.directiveRef.scrollToY(newY, animationTime);
    }
    // check if end or start is reached after scroll animation completes
    setTimeout(_ => {
      if (!(this.topBarScrollComponentRef.directiveRef as any).ps().reach.y) {
        this.topScrollProperties.isBottom = false;
        this.topScrollProperties.isTop = false;
      }
    }, animationTime);
  }

  public onScrollEvent(event: any): void {
    switch (event.type) {
      case 'ps-y-reach-start':
        this.topScrollProperties.isTop = true;
        break;
      case 'ps-y-reach-end':
        this.topScrollProperties.isBottom = true;
        break;
    }
  }

  need_pro(collection, index) {
    index = index + 1;
    if (this.version.pro) {
      return false
    } else {
      if (this.activeName == "Spanje" || this.activeName == "thuis") {
        return (index) > this.numberOfFreeSounds * 2 || index <= this.numberOfFreeSounds
      } else {
        return index > this.numberOfFreeSounds
      }
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  trackByCollection(i, item) {
    return item.name + item.icon;
  }
}
