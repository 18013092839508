<div class="page page-register">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo">
        <img src="./assets/images/logo-{{i18n.currentLanguage}}.png" alt="">
      </div>
      <form class="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
        <h2>{{ 'Maak een account aan' |translate}}</h2>
        <div class="form-group">
          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
            <div *ngIf="email.errors.required">{{ 'Email is verplicht' |translate}}</div>
            <div *ngIf="email.errors.email">{{ 'Geen geldig Email address' |translate}}</div>
          </div>
          <input type="text" placeholder="Email" name="email" [(ngModel)]="model.email" #email="ngModel"
                 [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email autocapitalize="off"/>
        </div>
        <div class="form-group">
          <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
            <div *ngIf="password.errors.required">{{ 'Wachtwoord is verplicht' |translate}}</div>
            <div *ngIf="password.errors.minlength">{{ 'Wachtwoord moet minstens 6 tekens bevatten' |translate}}</div>
          </div>
          <input type="password" class="form-control" placeholder="{{'Wachtwoord'|translate}}" name="password"
                 [(ngModel)]="model.password" #password="ngModel"
                 [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required minlength="6"
                 autocapitalize="off"/>
        </div>

        <div class="form-group">
          <div *ngIf="f.submitted && checkpass.invalid" class="invalid-feedback">
            <div *ngIf="checkpass.errors.required">{{ 'Wachtwoord komt niet overeen' |translate}}</div>
          </div>
          <input type="password" class="form-control" placeholder="{{'Bevestig wachtwoord'|translate}}" name="checkpass"
                 [(ngModel)]="model.checkpass" #checkpass="ngModel"
                 [ngClass]="{ 'is-invalid': (checkpass.value != password.value)||(f.submitted && checkpass.invalid)}"
                 required minlength="6" autocapitalize="off"/>
        </div>
        <label for="key"></label>
        <input *ngIf="browser" id="key" type="key" [(ngModel)]="key" name="key" placeholder="XXXXX-XXXXX-XXXXX-XXXXX">

        <input type="submit"
               value="{{'Registreer'|translate}}">

        <!--        todo-->
        <p>
          <a href="#" routerLink="/login" [innerHTML]="'account-already-have-one' | translate"></a>
        </p>
        <small [innerHTML]="'accept-privacy-policy'|translate">Door te registreren ga je akkoord met onze
          <a href="https://deoasevandouz.be/assets/images/privacy.pdf" target="_blank" tabindex="-1">
            Privacy Policy
          </a>
        </small>
      </form>
      <br>
      <app-language-switcher></app-language-switcher>
    </div>
  </div>
</div>
