import { Component, NgZone, OnDestroy, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Subscription, Observable, Subject } from 'rxjs';
import {
  GoToFirstBlock, ImportState,
  PlayNextBlock,
  PlayPrevBlock,
  SetPlaying,
} from '../../actions/playerTimeline.actions';
import { AudioSequence } from '../../interfaces/AudioSequence';
import { AudioPlayerService } from '../../services/audio-player.service';
import { AudioSequencesService } from '../../services/audio-sequences.service';
import { takeUntil, first } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../reducers';
import { Title, Meta } from '@angular/platform-browser';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from "../../services/auth/auth.service";
import { I18nService } from "../../services/i18n.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'muziek-player',
  templateUrl: './muziek-player.component.html',
  styleUrls: ['./player.component.scss']
})
export class MuziekPlayerComponent implements OnInit, OnDestroy {
  [x: string]: any;

  public artist: string;
  public song: string;
  public id: any;
  public timelinePlaying$: Observable<any>;
  public timelineBlocks$: Observable<AudioSequence[]>;
  public timelinePlayingIndex$: Observable<number>;

  private routeParamSubscription: Subscription;
  private timelinePlayingBlock$: Observable<AudioSequence>;

  volumes: { [p: string]: number } = {};

  private destroyed$ = new Subject();
  public loading: boolean = true;
  audioId$: Observable<number>;
  audioId: number;
  currentBlockId: any;
  currentPlaying: any;
  private sharedSong: any;
  shareTitle: string;
  shareText: string;
  shareUrl: Location;
  viaURl: string;

  constructor(private domSanitizer: DomSanitizer,
              private zone: NgZone,
              private route: ActivatedRoute,
              private store: Store<fromStore.State>,
              private audioPlayerService: AudioPlayerService,
              @Inject(DOCUMENT) private document,
              @Inject(PLATFORM_ID) private platformId: Object,
              private titleService: Title,
              private afd: AngularFireDatabase,
              public auth: AuthService,
              public i18n: I18nService,
              private translate: TranslateService,
              private metaTagService: Meta,
              private audioSequenceService: AudioSequencesService) {
  }

  ngOnInit() {
    this.routeParamSubscription = this.route.paramMap.subscribe(async params => {
      this.shareUrl = window.location;
      this.viaURl = window.location.origin;

      this.artist = decodeURIComponent(params.get('author'));
      this.song = decodeURIComponent(params.get('song'));

      this.shareTitle = await this.translate.get('swal-shareTitle').toPromise();
      this.shareText = await this.translate.get('swal-shareText', {
        songName: this.song,
        author: this.artist
      }).toPromise();

      // this.shareTitle = encodeURIComponent(`Beluister hier ${this.song} gemaakt door ${this.artist}`);
      // this.shareText = encodeURIComponent(`${this.artist} heeft een liedje gemaakt via De Oase Van Douz. Beluister het hier`);
      this.id = params.get('id');
      this.setOgTags();
      this.loading = true;
      // for some reason firebase does not work on the server with angular universal :(
      if (isPlatformBrowser(this.platformId)) {
        this.auth.firebaseAnonymousLogin()
          .then(() => {
            return this.afterSignIn();
          });
      }
    });
  }

  afterSignIn() {
    // get the shared song from firebase
    this.afd
      .object(`muziekskes/${this.id}`)
      .valueChanges()
      .pipe(first())
      .subscribe(value => {
        if (value) {
          this.artist = value['author'] ? decodeURIComponent(value['author']) : this.artist;
          this.song = value['songName'] ? decodeURIComponent(value['songName']) : this.song;
          this.setOgTags();

          this.sharedSong = value['blocks'];
          this.store.dispatch(new ImportState({ blocks: value['blocks'], volumes: value['volumes'] }));
          this.loading = false;
        }
      });
    this.timelineBlocks$ = this.store.pipe(select(fromStore.getPlayerTimelineBlocks));
    this.timelinePlayingIndex$ = this.store.pipe(select(fromStore.getPlayerTimelinePlayingIndex));
    this.timelinePlayingBlock$ = this.store.pipe(select(fromStore.getPlayerTimeLinePlayingBlock));
    this.timelinePlaying$ = this.store.pipe(select(fromStore.getPlayerTimelinePlaying));
    this.audioId$ = this.store.pipe(select(fromStore.getPlayerAudioId));

    this.store.pipe(select(fromStore.getPlayerVolumes))
      .pipe(takeUntil(this.destroyed$))
      .subscribe((volumes) => {
        this.volumes = volumes;
        this.audioPlayerService.setVolumes(volumes);
      });

    this.timelineBlocks$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (blocks) => {
        const sounds = this.audioSequenceService.extractSoundsFromBlocks(blocks);
        this.loading = true;
        this.setPlaying(false);
        await this.audioPlayerService.preload(sounds);
        this.loading = false;
      });

    this.audioId$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(async (audioId) => {
        this.audioId = audioId;
      });
    this.timelinePlaying$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((playing) => {
        if (this.currentBlockId === null) {
          this.currentPlaying = playing;

          return;
        }

        if (playing) {
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        } else {
          this.audioPlayerService.stopPlaying();
        }

        this.currentPlaying = playing;
      });

    this.timelinePlayingBlock$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((block) => {
        if (block) {
          block.sources.forEach((sourceList) => {
            sourceList.forEach((source) => {
              this.audioPlayerService.setMuted(source.src, !!source.muted);
            });
          });
        }

        if (this.currentPlaying && (!block || block.id === this.currentBlockId)) {
          // this happens when percussion is dragged onto block while playing
          // stop playback immediately and proceed
          this.currentBlockId = block ? block.id : null;
          this.audioPlayerService.stopPlaying();
        }

        // check if there is a sound currently playing
        if (this.currentPlaying) {
          this.audioPlayerService.stopPlaying();
        }
        this.audioPlayerService.setBlock(block); //VERY IMPORTANT BECAUSE IT DETERMINES THE DURATION OF THE SOUNDS

        if (this.currentPlaying) {
          this.audioPlayerService.startPlaying()
            .then(() => {
              this.store.dispatch(new PlayNextBlock());
            });
        }

        this.currentBlockId = block ? block.id : null;
      });
  }

  setOgTags() {
    let newTitle = `${this.song} door ${this.artist} | De Oase Van Douz`;
    let newDescription = `Luister naar het liedje "${this.song}" gemaakt door ${this.artist} met de Oase Van Douz App.`;

    this.titleService.setTitle(newTitle);
    this.metaTagService.updateTag({ property: 'og:title', content: newTitle });

    this.metaTagService.updateTag({
      name: 'description',
      content: newDescription
    });
    this.metaTagService.updateTag({ property: 'og:description', content: newDescription });
  }

  goToFirstBlock($event) {
    $event.preventDefault();
    $event.stopPropagation();

    this.store.dispatch(new SetPlaying(false));
    this.store.dispatch(new GoToFirstBlock());
  }

  setPlaying(play: boolean) {
    this.store.dispatch(new SetPlaying(play));
  }

  ngOnDestroy(): void {
    this.routeParamSubscription.unsubscribe();
    this.destroyed$.next();
  }
}
