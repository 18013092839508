import { Component, Inject, AfterViewInit, OnInit, OnDestroy, NgZone } from '@angular/core';
import { WindowRefService } from './services/window-ref.service';
import { SetCollection } from './actions/collection.actions';
import { Store } from '@ngrx/store';
import * as fromStore from './reducers';
import { AudioSequencesService } from './services/audio-sequences.service';

import { Router } from '@angular/router';
import { SwUpdate } from '@angular/service-worker';

import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID } from '@angular/core';
import { PageVisibilityService } from 'ngx-page-visibility';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { fadeAnimation } from './route-animation';
import { RouteInterceptorService } from './services/route-interceptor.service';
import { Platform } from '@ionic/angular';
import { environment } from '../environments/environment';
import { I18nService } from './services/i18n.service';
import { Howl, Howler } from 'howler';

declare var cordova, chrome;
const isEmptyObject = object => Object.keys(object).length === 0;
const isCordovaApp = document.URL.indexOf('http://') === -1
  && document.URL.indexOf('https://') === -1;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeAnimation]
})
export class AppComponent implements AfterViewInit, OnInit, OnDestroy {

  private width: number;
  private height: number;
  private relationship = 1.350923483;  // ratio
  private destroyed$ = new Subject();


  environment = environment;
  previousCpuInfo: any;
  cpuInfo: any = {};

  constructor(private winRef: WindowRefService,
              public platform: Platform,
              private swUpdate: SwUpdate,
              private store: Store<fromStore.State>,
              private pageVisibilityService: PageVisibilityService,
              private audioSequenceService: AudioSequencesService,
              private _ngZone: NgZone,
              private i18n: I18nService,
              @Inject(PLATFORM_ID) private platformId: Object,
              private routeInterceptorService: RouteInterceptorService,
              private router: Router) {

    const sequenceCollections = this.audioSequenceService.getSequenceCollections();
    // set default Country
    this.store.dispatch(new SetCollection(sequenceCollections));
    document.addEventListener('backbutton', this.handleBackbutton.bind(this), false);
  }

  handleBackbutton() {
    this._ngZone.run(() => {
      if (this.routeInterceptorService.currentUrl === '/home') {
        navigator['app'].exitApp();
      } else {
        this.router.navigate([this.routeInterceptorService.previousUrl]).catch(error => {
          throw Error('something went wrong with "handleBackbutton()" function \n' + JSON.stringify(error));
        });
      }
    });
  }

  ngOnInit() {
    Howler.unload();
    this.i18n.getLanguages().then(r => {
    });
    if (!isCordovaApp && this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe(evt => {
        window.location.reload();
      });

      if (isPlatformBrowser(this.platformId)) {
        // this should fix some visibility fixes after e.g. opening a new tab on ipad.
        this.pageVisibilityService.$onPageVisible.pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.resize();
          });
      };
    }
  }


  ngAfterViewInit() {
    setTimeout(() => {
      this.resize();
    }, 50);

    if (!isCordovaApp) {
      (window as any).onresize = () => {
        this.resize();
      };
    }
  }

  resize() {
    if (isPlatformBrowser(this.platformId)) {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      let resize = 1;

      if (this.width / this.height > this.relationship) {
        resize = this.height / 768;
      } else {
        resize = this.width / 1024;
      }

      if (resize > 1.1) {
        resize = 1.1;
      }

      if (typeof document !== 'undefined') {
        const styleNode = document.getElementById('scaleWindowStyle');

        if (styleNode) {
          styleNode.parentNode.removeChild(styleNode);
        }

        const style = document.createElement('style');

        style.id = 'scaleWindowStyle';
        style.type = 'text/css';
        style.innerHTML = `
          .gu-mirror{
              transform:scale(${resize}) translateX(${resize}%)
           }
           .gu-mirror.line{
             transform:scale(1)
           }
           .gu-mirror .line--content{
             transform:scale(${resize})
           }
          .wrapper{
              transform:scale(${resize})
           }
        `;
        document.getElementsByTagName('head')[0].appendChild(style);
      }
    }
  }

  public getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
