export class PictureUpload {
  $key: string;
  blob: Blob;
  name: string;
  url: string;
  uid?: string;
  progress: number;

  constructor(blob: Blob) {
    this.blob = blob;
  }
}
