<br>
<br>
<button (pointerup)="record()">record</button>
<br>
<button (pointerup)="stopRecording()">stop</button>
<br>
<br>
<button (pointerup)="playRecording()">play</button>
<br>
<br>
<button routerLink="/home">Go To Home</button>
