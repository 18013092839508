import { TimelineActions, TimelineActionTypes } from '../actions/timeline.actions';
import { AudioSequence } from '../interfaces/AudioSequence';


export interface State {
  blocks: AudioSequence[];
  volumes: { [s: string]: number; };
  blockPlayingId: number | null;
  playing: boolean;
  audioId: number;
  totalRecordingDuration: number;
}

export const initialState: State = {
  volumes: {
    sources: 0.7,
    percussion: 0.7,
    voiceRecording: 0.7,
  },
  blocks: [],
  audioId: 0,
  blockPlayingId: null,
  playing: false,
  totalRecordingDuration: 0
};

export function reducer(state = initialState, action: TimelineActions): State {
  let index;

  switch (action.type) {
    case TimelineActionTypes.IncrementAudioId:
      return {
        ...state,
        audioId: state.audioId + 1,
      };

    case TimelineActionTypes.ResetTimeline:
      return initialState;

    case TimelineActionTypes.SetVolume:
      const volumes = {
        ...state.volumes,
      };

      volumes[action.payload.type] = action.payload.volume;

      return {
        ...state,
        volumes,
      };

    case TimelineActionTypes.SetPercussion:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            percussion: action.payload.percussion,
          };
        }),
      };

    case TimelineActionTypes.SetVoiceRecording:
      let totalRecordingDuration = state.totalRecordingDuration + action.payload.duration;

      if (totalRecordingDuration < 0) {
        totalRecordingDuration = 0;
      }

      return {
        ...state,
        totalRecordingDuration: totalRecordingDuration,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            voiceRecording: action.payload.voiceRecording
          };
        }),
      };

    case TimelineActionTypes.IncrementTotalRecordingDuration:

      let newTotalRecordingDuration = state.totalRecordingDuration + action.payload;

      if (newTotalRecordingDuration < 0) {
        newTotalRecordingDuration = 0;
      }

      return {
        ...state,
        totalRecordingDuration: newTotalRecordingDuration,
      };

    case TimelineActionTypes.SetDrawing:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            drawing: action.payload.drawing,
          };
        }),
      };

    case TimelineActionTypes.RemovePercussion:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            percussion: null,
          };
        }),
      };

    case TimelineActionTypes.RemoveVoiceRecording:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            voiceRecording: null,
          };
        }),
      };

    case TimelineActionTypes.RemoveDrawing:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id !== action.payload.id) {
            return block;
          }

          return {
            ...block,
            drawing: null,
          };
        }),
      };

    case TimelineActionTypes.RemoveAudioSequence:
      return {
        ...state,
        blocks: state.blocks.filter((block) => block.id !== action.payload.id),
      };

    case TimelineActionTypes.GoToFirstBlock:
      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        blockPlayingId: state.blocks[0].id,
      };

    case TimelineActionTypes.PlayPrevBlock:

      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      index = findPlayingBlockIndex(state.blocks, state.blockPlayingId);

      index -= 1;

      if (!state.blocks[index]) {
        index = state.blocks.length - 1;
      }

      return {
        ...state,
        blockPlayingId: state.blocks[index].id,
      };

    case TimelineActionTypes.PlayNextBlock:

      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      index = findPlayingBlockIndex(state.blocks, state.blockPlayingId);

      index += 1;

      if (!state.blocks[index]) {
        index = 0;
      }

      return {
        ...state,
        playing: index === 0 && state.blocks.length === 1 ? false : state.playing,
        blockPlayingId: state.blocks[index].id,
      };

    case TimelineActionTypes.SetPlayingBlock:
      return {
        ...state,
        blockPlayingId: action.payload,
      };

    case TimelineActionTypes.ResetTotalRecordingDuration:

      return {
        ...state,
        totalRecordingDuration: 0,
      };

    case TimelineActionTypes.SetPlaying:
      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        blockPlayingId: findPlayingBlockIndex(state.blocks, state.blockPlayingId) > -1 ? state.blockPlayingId : state.blocks[0].id,
        playing: action.payload,
      };

    case TimelineActionTypes.SetBlocks:
      return {
        ...state,
        // cleanse out blocks because dragula sucks
        blocks: action.payload.map((block) => {
          delete block.playing;

          return block;
        }),
      };

    case TimelineActionTypes.ToggleMute:
      return {
        ...state,
        blocks: state.blocks.map((block) => {
          if (block.id === action.payload.id) {
            return {
              ...block,
              sources: block.sources.map((source, sourceIndex) => {
                if (sourceIndex === action.payload.sourceIndex) {
                  return source.map((instrument, instrumentIndex) => {
                    if (instrumentIndex === action.payload.instrumentIndex) {
                      return {
                        ...instrument,
                        muted: !instrument.muted,
                      };
                    }

                    return instrument;
                  });
                }

                return source;
              })
            };
          }

          return block;
        }),
      };
    default:
      return state;
  }
}

function findPlayingBlockIndex(blocks, id) {
  return blocks.findIndex((block) => {
    return block.id === id;
  });
}

export const getblocks = (state: State) => state.blocks;
export const getBlockPlayingId = (state: State) => state.blockPlayingId;
export const getPlaying = (state: State) => state.playing;
export const getAudioId = (state: State) => state.audioId;
export const getVolumes = (state: State) => state.volumes;
export const getTotalRecordingDuration = (state: State) => state.totalRecordingDuration;
