import {PlayerTimelineActions, PlayerTimelineActionTypes} from '../actions/playerTimeline.actions';
import {AudioSequence} from '../interfaces/AudioSequence';

export interface State {
  blocks: AudioSequence[];
  volumes: { [s: string]: number; };
  blockPlayingId: number | null;
  playing: boolean;
  audioId: number;
}

export const initialState: State = {
  volumes: {
    sources: 0.7,
    percussion: 0.7,
    voiceRecording: 0.7,
  },
  blocks: [],
  audioId: 0,
  blockPlayingId: null,
  playing: false,
};

export function reducer(state = initialState, action: PlayerTimelineActions): State {
  let index;

  switch (action.type) {
    case PlayerTimelineActionTypes.ImportState:
      return {
        ...state,
        blocks: action.payload.blocks,
        audioId: action.payload.blocks.length,
        volumes: action.payload.volumes ? action.payload.volumes : this.state.volumes,
      };

    case PlayerTimelineActionTypes.IncrementAudioId:
      return {
        ...state,
        audioId: state.audioId + 1,
      };

    case PlayerTimelineActionTypes.ResetPlayerTimeline:
      return initialState;


    case PlayerTimelineActionTypes.GoToFirstBlock:
      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        blockPlayingId: state.blocks[0].id,
      };

    case PlayerTimelineActionTypes.PlayPrevBlock:

      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      index = findPlayingBlockIndex(state.blocks, state.blockPlayingId);

      index -= 1;

      if (!state.blocks[index]) {
        index = state.blocks.length - 1;
      }

      return {
        ...state,
        blockPlayingId: state.blocks[index].id,
      };

    case PlayerTimelineActionTypes.PlayNextBlock:

      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      index = findPlayingBlockIndex(state.blocks, state.blockPlayingId);

      index += 1;

      if (!state.blocks[index]) {
        // Or the block index does not exist and something is seriously wrong
        // or we hit the end of the song in which case we want to stop playback
        return {
          ...state,
          blockPlayingId: 0,
          playing: false,
        };
      }

      return {
        ...state,
        playing: index === 0 && state.blocks.length === 1 ? false : state.playing,
        blockPlayingId: state.blocks[index].id,
      };

    case PlayerTimelineActionTypes.SetPlayingBlock:
      return {
        ...state,
        blockPlayingId: action.payload,
      };

    case PlayerTimelineActionTypes.SetPlaying:
      if (!state.blocks.length) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        blockPlayingId: findPlayingBlockIndex(state.blocks, state.blockPlayingId) > -1 ? state.blockPlayingId : state.blocks[0].id,
        playing: action.payload,
      };

    case PlayerTimelineActionTypes.SetBlocks:
      return {
        ...state,
        // cleanse out blocks because dragula sucks
        blocks: action.payload.map((block) => {
          delete block.playing;

          return block;
        }),
      };

    default:
      return state;
  }
}

function findPlayingBlockIndex(blocks, id) {
  return blocks.findIndex((block) => {
    return block.id === id;
  });
}

export const getblocks = (state: State) => state.blocks;
export const getBlockPlayingId = (state: State) => state.blockPlayingId;
export const getPlaying = (state: State) => state.playing;
export const getAudioId = (state: State) => state.audioId;
export const getVolumes = (state: State) => state.volumes;
