<div class="page page-home">
  <div class="wrapper" id="wrapper">
    <div class="content">
      <div class="logo">
        <img src="./assets/images/logo.png" alt="">
      </div>
      <form class="form" (ngSubmit)="f.form.valid && submit()" #f="ngForm" novalidate>
        <h2>{{ 'Nieuw wachtwoord aanvragen' |translate}}</h2>
        <div class="form-group">
          <input type="text" placeholder="Email" name="email" [(ngModel)]="model.email" #email="ngModel"
                 [ngClass]="{ 'is-invalid': f.submitted && email.invalid }" required email/>
          <div *ngIf="f.submitted && email.invalid" class="invalid-feedback">
            <div *ngIf="email.errors.required">{{ 'Email is verplicht' |translate}}</div>
            <div *ngIf="email.errors.email">{{ 'Geen geldig Email address' |translate}}</div>
          </div>
        </div>
        <input type="submit" [disabled]="!f.form.valid" value="{{'Verstuur'|translate}}">

        <p [innerHTML]="'account-already-have-one' | translate">
          Heb je al een account? Log dan
          <a href="#" routerLink="/login">hier</a>
          in!
        </p>
      </form>
    </div>
  </div>
</div>
