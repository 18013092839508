import { Component } from '@angular/core';

@Component({
  selector: 'app-rotate',
  templateUrl: './rotate.component.html',
  styleUrls: ['./rotate.component.scss']
})

export class RotateComponent {
}
