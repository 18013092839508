import {Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output} from '@angular/core';
import {SkyhookDndService} from '@angular-skyhook/core';
import {TimerObservable} from 'rxjs-compat/observable/TimerObservable';
import {DomSanitizer} from '@angular/platform-browser';
import {ViewEncapsulation} from '@angular/core';

import {
  setDriftlessTimeout,
  setDriftlessInterval,
  clearDriftless,
} from 'driftless';
import {map} from "rxjs/operators";

const TIMER = TimerObservable.create(0, 100);

@Component({
  selector: 'app-muziek-timeline-drop',
  templateUrl: './muziek-timeline-drop.component.html',
  styleUrls: ['./timeline-drop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MuziekTimelineDropComponent implements OnInit, OnDestroy {
  @Input('audioSequence') audioSequence;
  @Input('deleteMode') deleteMode;
  @Input('advanced') advanced;
  @Input('selected') selected;

  @Output('removeAudioSequence') removeAudioSequence = new EventEmitter();
  @Output('setPercussion') setPercussion = new EventEmitter();
  @Output('removePercussionOnly')  removePercussionOnly = new EventEmitter();
  @Output('toggleMute') toggleMuteEmitter = new EventEmitter();

  targetTop = this.dnd.dropTarget('PERCUSSION', {
    drop: (monitor) => {
      this.setPercussion.emit(monitor.getItem());
    }
  });

  targetBottom = this.dnd.dropTarget('PERCUSSION', {
    drop: (monitor) => {
      this.setPercussion.emit(monitor.getItem());
    }
  });

  topCollected$ = this.targetTop.listen(m =>
    ({
      canDrop: m.canDrop(),
      isOver: m.isOver(),
      item: m.getItem()
    }));

  bottomCollected$ = this.targetBottom.listen(m => ({
    canDrop: m.canDrop(),
    isOver: m.isOver(),
    item: m.getItem()
  }));

  totalDurationMs: number;

  showOverlayTop$ = this.topCollected$.pipe(map(c => {
    if (c.isOver) return c
  }));

  showOverlayBottom$ = this.bottomCollected$.pipe(map(c => {
    if (c.isOver) return c
  }));

  constructor(private domSanitizer: DomSanitizer,
              private dnd: SkyhookDndService) {
  }

  ngOnInit() {
    this.totalDurationMs = this.audioSequence.durations.reduce((a, b) => a + b, 0) * 1000;
  }

  public toggleMute(id, sourceIndex, instrumentIndex) {
    this.toggleMuteEmitter.next({id, sourceIndex, instrumentIndex});
  }

  remPercussionOnly(event): void {
    event.stopPropagation();
    this.removePercussionOnly.emit(this.audioSequence);
  }

  remAudioSequence(event): void {
    event.stopPropagation();
    this.removeAudioSequence.emit(this.audioSequence);
  }

  ngOnDestroy(): void {
    this.targetTop.unsubscribe();
    this.targetBottom.unsubscribe();
  }

  trackBySrc(i, item) {
    return i + '_' + item.src;
  }

  trackByIndex(index, item) {
    return item['icon'] + '_' + index;
  }
}
