import { Component, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output } from '@angular/core';
import { SkyhookDndService } from '@angular-skyhook/core';
import { AudioRecorderService } from '../../services/audio-recorder.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { blobToDataURL } from '../../lib/utils';
import { ViewEncapsulation } from '@angular/core';
import { map } from 'rxjs/operators';

import {
  setDriftlessTimeout,
  setDriftlessInterval,
  clearDriftless,
} from 'driftless';
import { PictureUpload } from "../../models/PictureUpload";

@Component({
  selector: 'app-timeline-drop',
  templateUrl: './timeline-drop.component.html',
  styleUrls: ['./timeline-drop.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TimelineDropComponent implements OnInit, OnDestroy {
  @Input('audioSequence') audioSequence;
  @Input('deleteMode') deleteMode;
  @Input('globalRecordmode') globalRecordmode?;
  @Input('stopRecordingEvent') stopRecordingEvent?;
  @Input('advanced') advanced;
  @Input('selected') selected;

  @Output('setPercussion') setPercussion = new EventEmitter();
  @Output('setBusy') setBusy = new EventEmitter();
  @Output('setVoiceRecording') setVoiceRecording = new EventEmitter();
  @Output('setDrawing') setDrawing = new EventEmitter();
  @Output('removePercussionOnly') removePercussionOnly = new EventEmitter();
  @Output('removeVoiceRecordingOnly') removeVoiceRecordingOnly? = new EventEmitter();
  @Output('removeDrawingOnly') removeDrawingOnly = new EventEmitter();
  @Output('removeAudioSequence') removeAudioSequence = new EventEmitter();
  @Output('updateRecordingMode') updateRecordingMode? = new EventEmitter();
  @Output('toggleMute') toggleMuteEmitter = new EventEmitter();

  private timerSubscription: Subscription;

  targetTop = this.dnd.dropTarget('PERCUSSION', {
    drop: (monitor) => {
      this.setPercussion.emit(monitor.getItem());
    }
  });

  targetBottom = this.dnd.dropTarget('PERCUSSION', {
    drop: (monitor) => {
      this.setPercussion.emit(monitor.getItem());
    }
  });

  topCollected$ = this.targetTop.listen(m =>
    ({
      canDrop: m.canDrop(),
      isOver: m.isOver(),
      item: m.getItem()
    }));

  bottomCollected$ = this.targetBottom.listen(m => ({
    canDrop: m.canDrop(),
    isOver: m.isOver(),
    item: m.getItem()
  }));

  showOverlayTop$ = this.topCollected$.pipe(map(c => {
    if (c.isOver) return c
  }));
  showOverlayBottom$ = this.bottomCollected$.pipe(map(c => {
    if (c.isOver) return c
  }));

  private recorderTimeout: any;
  private recordCountDown: number;
  public milliseconds: number;
  public totalDurationMs: number;
  public timeLeft: number;
  public recordingURLOrFileName: any;
  public localRecordMode: boolean;
  convertingToMp3: boolean = false;
  timerInterval: any;
  countdownInterval: any;

  constructor(private domSanitizer: DomSanitizer,
              private dnd: SkyhookDndService,
              private audioRecorderService: AudioRecorderService,
              protected localStorage: LocalStorage,
              private _ngZone: NgZone) {
  }

  ngOnInit() {
    this.totalDurationMs = this.audioSequence.durations.reduce((a, b) => a + b, 0) * 1000;

    if (this.stopRecordingEvent) {
      this.stopRecordingEvent.subscribe(event => {
          if (event.type === 'action') {
            switch (event.data) {
              case 'stopRecording':
                if (this.globalRecordmode) {
                  clearInterval(this.countdownInterval);
                  this.audioRecorderService.stopWithoutProcessing();
                  this.reset();
                }
                break;
            }
          }
        }
      );
      this.setBusy.emit(true);
      this.audioRecorderService
        .getRecordingsFromStorage(this.audioSequence)
        .then(voice => {
          console.log(`voice from block is ${voice} \n duration ${this.totalDurationMs}`);
          this.recordingURLOrFileName = voice;
          this.setVoiceRecording.emit({
            recordingURLOrFileName: this.recordingURLOrFileName,
            duration: this.totalDurationMs
          });
          this.setBusy.emit(false)
        })
        .catch(_ => {
          this.recordingURLOrFileName = null;
          this.setBusy.emit(false)
        });
    }
  }

  public toggleMute(id, sourceIndex, instrumentIndex) {
    this.toggleMuteEmitter.next({ id, sourceIndex, instrumentIndex });
  }

  addDrawing(drawing: PictureUpload): void {
    if (drawing) {
      this.setDrawing.next(drawing);
    } else {
      this.removeDrawingOnly.next();
    }
  }

  async recordVoice(event) {
    if (!this.globalRecordmode) {
      event.stopPropagation();
      event.preventDefault();
      this.globalRecordmode = true;
      this.localRecordMode = true;
      this.updateRecordingMode.emit(this.globalRecordmode);

      await this.audioRecorderService.initiateRecording(`voice-${this.audioSequence.id}`);
      this.audioRecorderService.stopWithoutProcessing();

      this.recordCountDown = 3;

      this.countdownInterval = setInterval(async () => {
        this.recordCountDown -= 1;
        if (this.recordCountDown <= 0) {
          clearInterval(this.countdownInterval);

          this.recordCountDown = null;

          await this.audioRecorderService.initiateRecording(`voice-${this.audioSequence.id}`);
          console.log(`recording initialed and started! ${this.totalDurationMs}milliseconds`);

          this.timeLeft = this.totalDurationMs + 900;

          this.timerInterval = setDriftlessInterval(async () => {
            this.timeLeft = this.timeLeft - 50;

            if (this.timeLeft <= 0) {
              clearDriftless(this.timerInterval);

              this.timeLeft = 0;
              this.convertingToMp3 = true;

              let result = await this.audioRecorderService.stopRecording();

              if (result['type'] == 'native') {
                const recordingFileName = result['fileName'];
                this.saveVoiceNative(recordingFileName);
              } else {
                const recordedBlobDataUrl = await blobToDataURL(result['blob']);
                this.saveVoice(recordedBlobDataUrl);
              }

              this.reset();
            }
          }, 50);
        }
      }, 435); // 435 is 140 bpm
    } else {
      console.log('another block is already recording');
    }
  }

  saveVoice(recordedBlobDataUrl): any {
    const recording = { recordedBlobDataUrl: recordedBlobDataUrl };

    this.localStorage.setItem(`voice-${this.audioSequence.id}`, recording).subscribe(() => {
      this.audioRecorderService
        .getRecordingsFromStorage(this.audioSequence)
        .then(voice => {
          this.recordingURLOrFileName = voice;
          this.setVoiceRecording.emit({
            recordingURLOrFileName: this.recordingURLOrFileName,
            duration: this.totalDurationMs
          });
        })
        .catch(_ => {
          this.recordingURLOrFileName = null;
        });
    });
  }

  saveVoiceNative(recordingFileName: string): any {
    const recording = { recordingFileName: recordingFileName };

    this.localStorage.setItem(`voice-${this.audioSequence.id}`, recording).subscribe(() => {
      this.audioRecorderService
        .getRecordingsFromStorage(this.audioSequence)
        .then(voice => {
          this.recordingURLOrFileName = voice;
          this.setVoiceRecording.emit({
            recordingURLOrFileName: this.recordingURLOrFileName,
            duration: this.totalDurationMs
          });
        })
        .catch(_ => {
          this.recordingURLOrFileName = null;
        });
    });
  }

  remVoiceRecordingOnly(event): void {
    event.stopPropagation();
    this.localStorage.removeItem(`voice-${this.audioSequence.id}`).subscribe(() => {
      this.audioRecorderService
        .getRecordingsFromStorage(this.audioSequence)
        .then(voice => {
          this.recordingURLOrFileName = voice;
          this.setVoiceRecording.emit({
            recordingURLOrFileName: this.recordingURLOrFileName,
            duration: this.totalDurationMs
          });
        })
        .catch(_ => {
          this.recordingURLOrFileName = null;
          this.removeVoiceRecordingOnly.emit(this.audioSequence);
        });
    });
  }

  remDrawingOnly(): void {
    this.removeDrawingOnly.emit(this.audioSequence);
  }

  remPercussionOnly(event): void {
    event.stopPropagation();
    this.removePercussionOnly.emit(this.audioSequence);
  }

  remAudioSequence(event): void {
    event.stopPropagation();
    this.removeAudioSequence.emit(this.audioSequence);
  }

  private reset() {
    this.convertingToMp3 = false;
    this.globalRecordmode = false;
    this.localRecordMode = false;
    this.updateRecordingMode.emit(false);
    clearDriftless(this.recorderTimeout);
    clearDriftless(this.timerInterval);
    clearInterval(this.countdownInterval);
    this.timeLeft = null;
    this.recordCountDown = null;
  }

  ngOnDestroy(): void {
    this.targetTop.unsubscribe();
    this.targetBottom.unsubscribe();
  }

  trackBySrc(i, item) {
    return i + '_' + item.src;
  }

  trackByIndex(index, item) {
    return item['icon'] + '_' + index;
  }
}
