import { Injectable, NgZone } from '@angular/core';
import {
  setDriftlessTimeout,
  setDriftlessInterval,
  clearDriftless,
} from 'driftless';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import Sound from '../lib/Sound';
import { Howl, Howler } from 'howler';

const format = 'm4a';
declare var device;
(window as any).soundIds = [];

@Injectable({
  providedIn: 'root'
})
export class AudioPlayerService {
  private sounds = {};

  private soundsPlaying: any[] = [];
  private percussionPlaying: any;
  private playingIndex = 0;
  private nextTimeout;
  private playingDurations: any[];
  private voiceRecording: Howl | MediaObject;
  isNative: boolean;
  private volumes: any;
  isMediastopped: boolean;

  constructor(private _ngZone: NgZone, private media: Media) {
    Howler.autoSuspend = false;
    Howler.volume(1);
    this.isNative = device.platform.toUpperCase() === 'ANDROID' || device.platform.toUpperCase() === 'IOS';
  }

  setVolumes(volumes) {
    this.volumes = volumes;

    if (this.soundsPlaying) {
      this.soundsPlaying.forEach((sources) => {
        sources.forEach((sound) => sound.setVolume(this.volumes['sources']));
      });
    }

    if (this.percussionPlaying) {
      this.percussionPlaying.setVolume(this.volumes['percussion']);
    }

    if (this.voiceRecording) {
      if (this.isNative) {
        (this.voiceRecording as MediaObject).setVolume(this.volumes['voiceRecording']);
      } else {
        (this.voiceRecording as Howl).volume(this.volumes['voiceRecording']);
      }
    }
  }

  setBlock(block) {
    // only do this if there is an actual block object
    if (block) {
      this.soundsPlaying = [];
      this.playingIndex = 0;
      this.playingDurations = block.durations;

      block.sources.forEach((source) => {
        this.soundsPlaying.push(source.map(sound => {
          return this.sounds[sound.src];
        }));
      });

      this.percussionPlaying = block.percussion ? this.sounds[block.percussion.src] : null;

      // TODO: I think we have a possible memoryleak right here.. leave it for now unloading etc...
      if (block.voiceRecording) {
        if (this.isNative) {
          this.voiceRecording = this.media.create(`${block.voiceRecording}`);
        } else {
          this.voiceRecording = new Howl({
            volume: 0, // needed for fadein to work
            src: [block.voiceRecording],
            format: ['mp3'],
            pool: 30,
          });
        }
      } else {
        // important
        this.voiceRecording = null;
      }
    }
  }

  setMuted(src, muted) {
    this.sounds[src].mute(muted);
  }

  startPlaying(index = 0) {
    console.log("😡 start called isMediastopped " + this.isMediastopped)
    this.isMediastopped = false;

    if (index === 0 && this.voiceRecording) {
      if (this.isNative) {
        (this.voiceRecording as MediaObject).setVolume(this.volumes['voiceRecording']);
        (this.voiceRecording as MediaObject).play({ playAudioWhenScreenIsLocked: false });
      } else {
        (this.voiceRecording as Howl).seek(0.05);
        (this.voiceRecording as Howl).volume(this.volumes['voiceRecording']);
        (this.voiceRecording as Howl).play();
      }
    }

    return new Promise((res, rej) => {
      this.playingIndex = index;
      const duration = this.playingDurations[index];

      this._ngZone.runOutsideAngular(() => {
        // set timeout when to trigger the next sample (duration is the length of the sound)
        this.nextTimeout = setDriftlessTimeout(() => {
          if (this.soundsPlaying[this.playingIndex + 1]) {
            if (this.percussionPlaying) {
              this.percussionPlaying.stop();
            }
            return this.startPlaying(this.playingIndex + 1).then(res);
          } else {
            res();
          }
        }, duration * 1000);

        if (this.percussionPlaying) {
          // start percussion loop everytime a new sequence is triggered.
          this.percussionPlaying.loop(true);
          this.percussionPlaying.setVolume(this.volumes['percussion']);
          this.percussionPlaying.play();
        }

        this.soundsPlaying[this.playingIndex].forEach((sound) => {
          sound.setVolume(this.volumes['sources']);
          sound.play();
        });
      });
    });
  }

  stopPlaying() {

    if (this.percussionPlaying) {
      this.percussionPlaying.stop();
    }

    if (typeof this.voiceRecording !== 'undefined' && this.voiceRecording) {
      console.log("😡 voicercording need sto stop! native? = " + this.isNative)
      if (!this.isNative) {
        let fadeTime = 200;
        (this.voiceRecording as Howl).fade(1, 0, fadeTime);
        (this.voiceRecording as Howl).stop();
      } else {
        if (!this.isMediastopped) (this.voiceRecording as MediaObject).stop();
        this.isMediastopped = true;
      }
    }

    if (!this.soundsPlaying[this.playingIndex]) {
      console.log('😓 no sound playing returning')
      return;
    }

    clearDriftless(this.nextTimeout);

    this.soundsPlaying[this.playingIndex].forEach((sound) => {
      sound.stop();
    });
  }

  async preload(sounds): Promise<boolean> {
    const promises = [];

    sounds.forEach((sound, index) => {
      if (sound) {
        promises.push(new Promise((res, rej) => {
          if (this.sounds[sound]) {
            res();
            return;
          }
          this._ngZone.runOutsideAngular(() => {
            this.sounds[sound] = new Sound(this.media, {
              src: `./assets/audio/${sound}.${format}`,
              format: format,
              pool: 30,
              preload: false,
              useNative: false
            });

            this.sounds[sound]
              .load()
              .then(loaded => {
                res();
              })
              .catch(err => {
                throw Error(`Failed to load sound file  ${err}`)
              });
          });
        }));
      }
    });

    this.loadBatch(0, sounds);

    return Promise.all(promises)
      .then(() => {
        return true;
      });
  }

  private loadBatch(index, sounds) {
    const items_per_load = 2;
    const batch = sounds.slice(index * items_per_load, (index + 1) * items_per_load);

    if (!batch.length) {
      return;
    }

    const promises = [];

    batch.forEach((sound) => {
      if (sound) { // check if the sound is not undefined
        promises.push(new Promise((res, rej) => {
          if (this.sounds[sound].state === 'loaded') {
            res();
          } else {
            this.sounds[sound].load();
            res();
          }
        }));
      }
    });

    Promise.all(promises)
      .then(() => this.loadBatch(index + 1, sounds));
  }
}
