import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import * as fromStore from '../../reducers';
import { map, take, takeUntil } from 'rxjs/operators';
import { ResetTimeline } from '../../actions/timeline.actions';
import { ResetMuziekTimeline } from '../../actions/muziekTimeline.actions';
import Swal from 'sweetalert2';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { AuthService } from '../../services/auth/auth.service';
import { PageVisibilityService } from 'ngx-page-visibility';
import { Subject } from 'rxjs';
import { UserService } from '../../services/auth/user.service';
import { environment } from '../../../environments/environment';
import { Network } from "@ionic-native/network/ngx";
import { TranslateService } from "@ngx-translate/core";
import { I18nService } from "../../services/i18n.service";
import { Howl, Howler } from 'howler';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})

export class HomeComponent implements OnInit, OnDestroy {
  private intro: Howl;
  private introId: number;
  private destroyed$ = new Subject();

  isProduction = !!environment.production;
  version: any;
  mutedIntro: boolean;
  networkAvailable: boolean = true;

  constructor(private router: Router,
              private store: Store<fromStore.State>,
              private actr: ActivatedRoute,
              protected localStorage: LocalStorage,
              public auth: AuthService,
              public i18n: I18nService,
              private network: Network,
              public userService: UserService,
              private translate: TranslateService,
              private pageVisibilityService: PageVisibilityService,
  ) {
    this.actr.data
      .pipe(
        map(res => res.version)
      )
      .subscribe((version) => {
        this.version = version;
      });
  }

  ngOnInit() {
    // Howler.autoSuspend = false;
    Howler.html5PoolSize = 100;

    this.intro = new Howl({
      src: [`./assets/audio/other/intro.m4a`],
      format: ['m4a'],
      pool: 10,
    });
    this.intro.loop(true);

    this.localStorage.getItem<boolean>('introMuted').subscribe(muted => {
      this.mutedIntro = (muted as boolean) || false;
      this.setMute(this.mutedIntro);
      this.introId = this.intro.play();
    });

    this.pageVisibilityService.$onPageHidden.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.intro.pause();
      });

    this.pageVisibilityService.$onPageVisible.pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        setTimeout(_ => {
          this.intro.play();
        }, 300);
      });

    if (this.network.type == 'none') {
      this.networkAvailable = false;
    }
  }

  ngOnDestroy() {
    this.destroyed$.next();
    this.intro.fade(1, 0, 1000);
    this.intro.stop();
    this.intro.unload();
  }

  async checkParents() {
    let title = await this.translate.get('swal-warning-parents').toPromise();
    let riddle1 = await this.translate.get('swal-riddle1').toPromise();
    let cancelButton = await this.translate.get('swal-cancel').toPromise();

    const { value: oplossing } = await Swal.fire({
      title: title,
      text: riddle1.replace(/(<([^>]+)>)/ig, ""), //strip html tags
      input: 'text',
      inputValue: '',
      showCancelButton: true,
      confirmButtonText: 'ok',
      allowOutsideClick: false,
      cancelButtonText: cancelButton
    });

    const solution = oplossing ? (oplossing as string).toLowerCase() : undefined;

    if (oplossing && (oplossing == '9' || solution == 'negen' || solution == 'nine' || solution == 'neuf')) {
      await this.router.navigate(['/purchase']);
    } else {
      //do nothing
    }
  }

  async checkVerhaalTimeline() {
    let title = await this.translate.get('swal-warning2').toPromise();
    let text = await this.translate.get('swal-continue').toPromise();
    let confirm = await this.translate.get('swal-continue-confirm').toPromise();
    let newSession = await this.translate.get('swal-continue-cancel').toPromise();

    const blockCount = await this.store.pipe(select(fromStore.getTimelineBlocks))
      .pipe(map((blocks) => blocks.length))
      .pipe(take(1))
      .toPromise();

    if (blockCount > 0) {
      const results = await Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirm,
        allowOutsideClick: false,
        cancelButtonText: newSession
      });

      if (!results.value) {
        this.localStorage.clear().subscribe(() => {
        });
        this.store.dispatch(new ResetTimeline());
      }
    }

    await this.router.navigate(['verhaal']);
  }

  async checkMuziekTimeline() {
    const blockCount = await this.store.pipe(select(fromStore.getMuziekTimelineBlocks))
      .pipe(map((blocks) => blocks.length))
      .pipe(take(1))
      .toPromise();

    if (blockCount > 0) {
      let title = await this.translate.get('swal-warning2').toPromise();
      let text = await this.translate.get('swal-continue').toPromise();
      let confirm = await this.translate.get('swal-continue-confirm').toPromise();
      let newSession = await this.translate.get('swal-continue-cancel').toPromise();

      const results = await Swal.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirm,
        allowOutsideClick: false,
        cancelButtonText: newSession
      });

      if (!results.value) {
        this.localStorage.clear().subscribe(() => {
        });
        this.store.dispatch(new ResetMuziekTimeline());
      }
    }

    await this.router.navigate(['muziek']);
  }


  setMute(muted = false) {
    this.localStorage.setItemSubscribe('introMuted', muted);
    this.intro.mute(muted);
    return muted;
  }

  toggleMute() {
    this.mutedIntro = this.setMute(!this.mutedIntro);
  }

  logout() {
    this.auth.changeAuthStatus(false);
    this.auth.logout();
    this.router.navigate(['login']);
  }

  removePro() {
    this.userService.relock().subscribe(res => {
        this.version = this.userService.getVersion();
      },
      err => {
        throw Error(JSON.stringify(err));
      });
  }
}
