import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Device } from '@ionic-native/device/ngx';

import { Observable } from 'rxjs';
import { DeviceUUID } from 'device-uuid';
import { isPlatformBrowser } from '@angular/common';

@Injectable()
export class HttpDeviceInterceptor implements HttpInterceptor {
  uid = 'unset'

  constructor(
    private device: Device,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.uid = new DeviceUUID().get();
    }
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    //ONLY intercept call which come from clientside and not from server
    let authReq = req.clone({
      headers: req.headers.set('device-uuid', this.device.uuid || this.uid)
    });

    authReq = authReq.clone({
      headers: authReq.headers.set('device-platform', this.device.platform)
    });
    return next.handle(authReq);
  }
}
