import { Component, OnInit } from '@angular/core';
import { Media, MediaObject } from '@ionic-native/media/ngx';
import { File } from '@ionic-native/file/ngx';

declare var device;

@Component({
  selector: 'app-audio-recordtest',
  templateUrl: './audio-recordtest.component.html',
  styleUrls: ['./audio-recordtest.component.css']
})
export class AudioRecordtestComponent implements OnInit {
  recording: MediaObject;
  mediaTimer: number;
  fileName: any;
  getFile: MediaObject;

  constructor(private media: Media, private file: File) {
  }

  ngOnInit() {
    this.fileName = 'sample';
  }

  record() {
    let src = `${this.fileName}.mp3`;
    if (device.platform.toUpperCase() === 'IOS') {
      console.log('mobile device detected.. start recording');
      src = `documents://${this.fileName}.m4a`;
      console.log(`${src}`);
      alert(`${src}`);
    } else if (device.platform.toUpperCase() === 'ANDROID') {
      src = `${this.file.dataDirectory}${this.fileName}.mp3`;
      console.log(`${src}`);
      alert(`${src}`);
    } else {
      this.getFile = this.media.create(src);
    }
    this.getFile.startRecord();
  }

  stopRecording() {
    console.log('stop recording');
    alert('stop recording');
    this.getFile.stopRecord();
  }

  playRecording() {
    this.getFile.play();
  }
}
